import React, { Suspense, lazy, useEffect } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { gEvent } from '../atoms'
import ListEvent from '../components/list/list_event'
import SAlmRecommendation from '../components/shimmer/salm_recommendation'
import { getEventController } from '../controllers/global_controllers'
import { Column, HorizontalDivider, ShareFBButton, ShareTelegramButton, ShareWAButton, ShareXButton } from '../shared/ui'

const ListRecognitionRecommendation = lazy(() => import('../components/list/list_recognition_recommendation'))

function EventPage() {

    const result = useLoaderData()

    const [listEvent, setListEvent] = useRecoilState(gEvent)

    const getEventFromLoader = () => {
        if (result.status) {
            setListEvent(result.data)
            console.log(listEvent)
        }
    }

    useEffect(() => {
        getEventFromLoader()
    }, [])

    return (
        <Column className={'w-full max-w-8xl mx-auto gap-x-5 gap-y-8 pt-20 pb-16 sm:pt-24 lg:pt-32 xl:flex-row'}>
            <Column className={'w-fit hidden fixed gap-x-3 gap-y-3 lg:flex lg:left-10 lg:top-32 xl:sticky'}>
                <ShareFBButton location={window.location.href} />
                <ShareXButton location={window.location.href} />
                <ShareWAButton location={window.location.href} />
                <ShareTelegramButton location={window.location.href} />
            </Column>
            <Column className={'w-full gap-y-5 max-w-4xl mx-auto sm:px-14 sm:gap-y-8'}>
                <ListEvent events={listEvent} />
            </Column>
            <HorizontalDivider className={'my-4 xl:hidden'} />
            <Column className={'w-full max-w-4xl mx-auto sm:px-14 xl:px-0 xl:max-w-sm xl:sticky xl:top-32'}>
                <Suspense fallback={<SAlmRecommendation />}>
                    <ListRecognitionRecommendation />
                </Suspense>
            </Column>
        </Column>
    )
}

export default EventPage

export const getEvent = async () => {
    const props = {
        page: 1,
        limit: 10,
    }
    const result = await getEventController(props)
    return result
}