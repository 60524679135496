import React, { useState } from 'react'
import { TbSearch } from 'react-icons/tb'
import bgCareer1 from '../../images/bg-career1.jpg'
import { Column, Form, Image, LargeHeadline, OutlineInput, PrimaryButton, Row, Title } from '../../shared/ui'

function WHeaderCareer({ onSearch }) {

  const [searchQuery, setSearchQuery] = useState(null)

  const onSubmitSearch = async (event) => {
    event.preventDefault()
    const query = event.target.query.value
    setSearchQuery(query)
    onSearch(50, query)
  }

  return (
    <Column className={'w-full relative pt-56 pb-28 gap-y-20 items-center'}>
      <Image
        className={'w-full absolute inset-0'}
        imgClasName={'w-full h-full object-top'}
        src={bgCareer1}
        hoverScale={false} />
      <div className={'w-full absolute inset-0 top-0 bg-gradient-to-b from-black/70 to-black/70'} />
      <Column className={'max-w-6xl gap-y-8 items-center z-[5]'}>
        <LargeHeadline className={'font-semibold text-white'}>
          Lowongan Kerja
        </LargeHeadline>
        <Title className={'font-normal font-inter text-center text-white/70'}>
          Berbagai informasi lowongan terpilih kami bagikan kepada alumni Universitas Teknologi Yogyakarta. Semua alumni Universitas Teknologi Yogyakarta akan mendapatkan fasilitas melamar secara langsung keperusahaan mitra UTY. Jadilah yang pertama untuk mendapatkan kandidat terbaik untuk perusahaan Anda dengan bergabung menjadi mitra UTY.
        </Title>
      </Column>
      <Form
        className={'w-full z-[5]'}
        onSubmit={onSubmitSearch}>
        <Row className={'w-full max-w-2xl mx-auto'}>
          <OutlineInput
            id='query'
            className={'px-8 py-3.5 text-xl rounded-l-full rounded-r-none bg-white'}
            bgColor={'bg-slate-50'}
            inputWidth={'w-full'}
            defaultValue={searchQuery}
            placeholder='Posisi, perusahaan atau kata kunci lainnya' />
          <PrimaryButton
            type='submit'
            className={'pl-7 pr-8 rounded-l-none rounded-r-full'}
            color='indigo'
            leadingIcon={
              <TbSearch className={'h-6 w-6'} />
            } />
        </Row>
      </Form>
    </Column>
  )
}

export default WHeaderCareer