import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { Column, Row } from '../../../../components/interfaces/block';
import { Card } from '../../../../components/interfaces/card';
import { CaptionText, OverlineText, TitleText } from '../../../../components/interfaces/text';
import { getOvPosisiWiraTracerController } from '../../../../controllers/admin_controllers';
import { DotSeparated } from '../../../../shared/ui';

function ChartPosisiWirausaha({ prodi, year, series, labels }) {

  const colors = [
    '#4CAF50',
    '#2196F3',
    '#3F51B5',
    '#FF9800',
  ]

  const legendColors = [
    'bg-[#4CAF50]',
    'bg-[#2196F3]',
    'bg-[#3F51B5]',
    'bg-[#FF9800]',
  ]

  const [locSeries, setLocSeries] = useState([])
  const [locLabels, setLocLabels] = useState([])

  const getOvPosisiWiraTracer = async () => {
    const props = {
      prodi: prodi.value,
      tahun: year.value,
    }
    const result = await getOvPosisiWiraTracerController(props)
    if (result.status) {
      setLocSeries(result.data)
      setLocLabels(result.labels)
    }
  }

  useEffect(() => {
    if (prodi.value != null || year.value != null) {
      getOvPosisiWiraTracer()
    }
  }, [prodi, year])

  return (
    <Card className={'w-full h-full p-0'}>
      <Column className={'h-full'}>
        <Column className={'p-6 gap-y-1'}>
          <TitleText>
            Posisi/Jabatan Jika Berwiraswasta
          </TitleText>
          <Row>
            <CaptionText>
              {prodi.label}
            </CaptionText>
            <DotSeparated />
            <CaptionText>
              {year.value ? `Tahun ${year.label}` : year.label}
            </CaptionText>
          </Row>
        </Column>
        <div className='my-auto pb-6'>
          <Chart
            height={250}
            type="donut"
            series={(prodi.value != null || year.value != null) ? locSeries : series}
            options={{
              chart: {
                width: 720,
                height: 720,
                type: 'donut',
                fontFamily: 'archivo',
              },
              labels: (prodi.value != null || year.value != null) ? locLabels : labels,
              dataLabels: {
                enabled: true,
                fontSize: '12px',
              },
              colors: colors,
              legend: {
                show: false,
                position: 'bottom',
                offsetX: 0,
              }
            }} />
        </div>
        <ChartLegend
          labels={(prodi.value != null || year.value != null) ? locLabels : labels}
          colors={legendColors} />
      </Column>
    </Card>
  )
}

export default ChartPosisiWirausaha

export const ChartLegend = ({ labels, colors }) => {
  return (
    <Row className={'px-6 pb-6 gap-x-3 gap-y-2 flex-wrap justify-center'}>
      {labels.map((label, idx) => {
        return (
          <Row key={idx} className={'w-fit gap-1.5'}>
            <Card className={`${colors[idx]} border-none p-1.5 rounded-full my-auto`} />
            <OverlineText className={'my-auto text-gray-800 font-normal'}>
              {label}
            </OverlineText>
          </Row>
        )
      })}
    </Row>
  )
}