import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowSaranTracer from './rows/row_saran_tracer'

function TablesaranTracer({ listSaran, page, limit, onShort }) {

  const [currentSort, setCurrentSort] = useState(null)

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='nimhsmsmh'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            NPM
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='nmmhsmsmh'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Nama Lengkap
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='jenjang'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Jenjang
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='prodi'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Program Studi
          </TableHeadCell>
          <TableHeadCell>
            Saran
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listSaran.length > 0 ? listSaran.map((saran, idx) => {
          return (
            <RowSaranTracer
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              saran={saran} />
          )
        }) : <TableRow>
          <TableCell colSpan={6} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TablesaranTracer