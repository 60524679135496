import { axios_delete, axios_get, axios_post } from "../services/apiservice"
import { eventApiUrl, gChatConsultationApiUrl, gConsultationCategoryApiUrl, gLowonganApiUrl, gRecognitionApiUrl, logoutApiUrl, masterFilterOvTracerApiUrl, notificationsApiUrl } from "../shared/variable"

//? GET -- FILTER TRACER STUDI
export const getFilterTracerController = async () => {
    const result = await axios_get(masterFilterOvTracerApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- NOTIFICATIONS
export const getNotificationsController = async () => {
    const result = await axios_get(notificationsApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- READ NOTIFICATIONS
export const readNotificationsControllers = async (id) => {
    const body = {
        id_notifikasi: id,
        is_read: true
    }
    const result = await axios_post(notificationsApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- REKOGNISI (BERITA ALUMNI)
export const getRecognitionController = async ({ page = 1, limit = 10 }) => {
    const header = {}
    const params = {
        page,
        limit,
    }
    const result = await axios_get(gRecognitionApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DETAIL REKOGNISI (BERITA ALUMNI)
export const getDetailRecognitionController = async (idrec) => {
    const result = await axios_get(`${gRecognitionApiUrl}/${idrec}`)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- EVENT ALUMNI
export const getEventController = async ({ page = 1, limit = 10 }) => {
    const header = {}
    const params = {
        page,
        limit
    }
    const result = await axios_get(eventApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DETAIL EVENT ALUMNI
export const getDetailEventController = async (slug) => {
    const result = await axios_get(`${eventApiUrl}/${slug}`)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- KATEGORI KONSULTASI ALUMNI
export const getConsultationCategoryController = async () => {
    const result = await axios_get(gConsultationCategoryApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- CHAT KONSULTASI ALUMNI/KONSELOR
export const getChatConsultationController = async (slug) => {
    const result = await axios_get(`${gChatConsultationApiUrl}/${slug}`)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- SAVE CHAT KONSULTASI ALUMNI/KONSELOR
export const saveChatConsultationController = async (id, reply, idreply) => {
    const body = idreply !== null ? {
        id_konsultasi: id,
        chat: reply,
        replay_to: idreply,
    } : {
        id_konsultasi: id,
        chat: reply
    }
    const result = await axios_post(`${gChatConsultationApiUrl}/${id}`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? DELETE -- HAPUS CHAT KONSULTASI ALUMNI/KONSELOR
export const deleteChatConsultationController = async (id) => {
    const result = await axios_delete({
        url: `${gChatConsultationApiUrl}/${id}`
    })
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- MENGAKHIRI SESI KONSULTASI ALUMNI/KONSELOR
export const finishConsultationController = async (id) => {
    const body = {}
    const result = await axios_post(`${gChatConsultationApiUrl}/${id}/finish`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- LOWONGAN LANDING PAGE
export const getLowonganController = async ({ jenispekerjaan = null, waktu = null, prov = 1, kota = 10, posisi = null }) => {
    const header = {}
    const params = {
        jenispekerjaan,
        waktu,
        prov,
        kota,
        posisi,
    }
    const result = await axios_get(gLowonganApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- KELUAR AKUN ADMIN/KONSELOR/ALUMNI/ADMIN PRODI/MITRA
export const logoutAccountController = async () => {
    const result = await axios_post(logoutApiUrl)
    if (result.status) {
        return true;
    }
    return false;
}