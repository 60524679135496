import React, { useEffect, useState } from 'react'
import { TbArrowRight } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { Column, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { OutlineInput, SearchInput, SelectInput } from '../../../components/interfaces/input'
import { Headline2Text } from '../../../components/interfaces/text'
import { Pagination, PaginationOption, useDebounce } from '../../../components/interfaces/utility'
import { getSurveiPenggunaController } from '../../../controllers/admin_controllers'
import { getFilterTracerController } from '../../../controllers/global_controllers'
import TableSurveiPengguna from './tables/table_survei_pengguna'

function AdmSurveiPenggunaPage() {

  const result = useLoaderData()

  const [listSurvei, setListSurvei] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [stsGetLoader, setStsGetLoader] = useState(false)
  const [filterProdi, setFilterProdi] = useState([])

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 })

  const [currentProdi, setCurrentProdi] = useState({ label: 'Semua Program Studi', value: null })
  const [filterStart, setFilterStart] = useState(new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().slice(0, 10))
  const [filterEnd, setFilterEnd] = useState(new Date().toISOString().slice(0, 10))

  const getSurveiTracerFromLoader = () => {
    if (result.filter.status === 'fulfilled') {
      const res = result.filter.value
      if (res.status) {
        const prodi = res.data.prodi
        const listProdi = []

        prodi.map((prodi) => {
          const p = {
            label: prodi.label,
            value: prodi.kode_prodi
          }
          listProdi.unshift(p)
        })
        listProdi.unshift({ label: 'Semua Program Studi', value: null })

        setFilterProdi(listProdi)
      }
    }
    if (result.survei.status === 'fulfilled') {
      const res = result.survei.value
      if (res.status) {
        setListSurvei(res.data)
        setTotalRow(res.totalRows)
        setTotalPage(res.totalPage)
      }
    }
    setStsGetLoader(true)
  }

  const regetSurveiTracer = async () => {
    const result = await getSurveiPenggunaController({
      search: searchQuery,
      page: currentPage,
      limit: limitRow,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null,
      date_start: filterStart,
      date_end: filterEnd,
      mhs_prodi: currentProdi.value,
    })
    if (result.status) {
      setListSurvei(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
    if (currentSort !== null) {
      setCurrentSort(null)
    }
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
  }

  const onHandleFilterStart = (event) => {
    event.preventDefault()
    setFilterStart(event.target.value)
  }

  const onHandleFilterEnd = (event) => {
    event.preventDefault()
    setFilterEnd(event.target.value)
  }

  const onChangeProdi = (value) => {
    setCurrentProdi(value)
  }

  useEffect(() => {
    getSurveiTracerFromLoader()
  }, [])

  useEffect(() => {
    if (stsGetLoader) {
      regetSurveiTracer()
    }
  }, [debounceSearch, limitRow, currentPage, currentSort, currentProdi, filterStart, filterEnd])

  return (
    <Column className={'p-4 gap-y-5 sm:p-8'}>
      <Headline2Text className={'font-semibold'}>
        Survei Pengguna Lulusan
      </Headline2Text>
      <Card className={'w-full'}>
        <Column className={'gap-y-5'}>
          <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
            <div>
              <SelectInput
                width='w-full sm:w-80'
                initial={currentProdi}
                options={filterProdi}
                onChange={onChangeProdi}
                zindex='z-20' />
            </div>
            <Row className='w-full h-[2.64rem] gap-3 sm:w-fit'>
              <div className={'w-full sm:w-fit'}>
                <OutlineInput
                  id='start'
                  type='date'
                  label='Tanggal mulai'
                  maxDate={filterEnd}
                  className='appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50'
                  placeholder='dd/mm/yyyy'
                  onChange={onHandleFilterStart}
                  defaultValue={filterStart}
                  hideLabel
                  required />
              </div>
              <TbArrowRight className='hidden h-5 w-5 my-auto text-gray-500 sm:flex' />
              <div className={'w-full sm:w-fit'}>
                <OutlineInput
                  id='end'
                  type='date'
                  label='Tanggal selesai'
                  minDate={filterStart}
                  maxDate={new Date().toISOString().slice(0, 10)}
                  className='appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50'
                  placeholder='dd/mm/yyyy'
                  onChange={onHandleFilterEnd}
                  defaultValue={filterEnd}
                  hideLabel
                  required />
              </div>
            </Row>
          </Column>
          <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
            <PaginationOption
              initial={limitRow}
              onChange={onHandlePaginationOption} />
            <SearchInput
              defaultValue={searchQuery}
              placeholder='Cari berita'
              onChange={onHandleSearch} />
          </Column>
          <TableSurveiPengguna
            limit={limitRow}
            page={currentPage}
            listSurvei={listSurvei}
            onShort={onHandleSort} />
          {listSurvei.length > 0 && <Pagination
            limitRow={limitRow}
            totalRow={totalRow}
            totalPage={totalPage}
            triger={currentSort !== null && currentSort.key}
            onChange={onHandlePagination} />}
        </Column>
      </Card>
    </Column>
  )
}

export default AdmSurveiPenggunaPage

export const getAdmSurveiPengguna = async ({ search = null, page = 1, limit = 10, order = null, asc = null }) => {

  const start = new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().slice(0, 10)
  const end = new Date().toISOString().slice(0, 10)

  const props = {
    search: search,
    page: page,
    limit: limit,
    order: order,
    asc: asc,
    date_start: start,
    date_end: end,
    mhs_prodi: null,
  }
  const [filter, survei] = await Promise.allSettled([
    getFilterTracerController(),
    getSurveiPenggunaController(props)
  ])
  return {
    'filter': filter,
    'survei': survei,
  }
}