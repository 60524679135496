import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getFullNameUser } from '../../../controllers/apicontrollers'
import { Avatar, Caption, Column, DotSeparated, Row, ShimmerAnimation, ShimmerItem, Text, TextButton } from '../../../shared/ui'

const ItemCommentRecognition = ({ comment, action = 0, onApprove, onReject, onUndo }) => {

    const [fullName, setFullName] = useState(null)

    const getFullNameOfUser = async () => {
        const result = await getFullNameUser(comment.id_identify, comment.id_user)
        if (result.status) {
            setFullName(result.data)
        }
    }

    useEffect(() => {
        getFullNameOfUser()
    }, [comment])

    return (
        <Row className={'gap-x-3'}>
            <Avatar className={'w-8 h-8'} />
            <Column className={'flex-1 gap-y-3'}>
                <Column className={'gap-y-1'}>
                    {fullName !== null ? <Text className={'text-gray-800 font-semibold font-manrope'}>
                        {fullName}
                    </Text> : <ShimmerAnimation>
                        <ShimmerItem className='h-6 w-52 mb-1' />
                    </ShimmerAnimation>}
                    <Caption>
                        {moment(comment.created_at).format('DD/MM/YYYY HH.mm')} WIB
                    </Caption>
                </Column>
                <Text className='text-gray-800 whitespace-pre-line mt-1'>
                    {comment.comment}
                </Text>
                {action !== 0 && <Row className={'w-fit mt-2.5'}>
                    {action === 10 ? <Row className='w-fit'>
                        <TextButton
                            color='indigo'
                            className={'text-xs'}
                            onClick={() => onApprove(comment.id_comment_rekognisi)}>
                            Approve
                        </TextButton>
                        <DotSeparated />
                        <TextButton c
                            olor='red'
                            className={'text-xs'}
                            onClick={() => onReject(comment.id_comment_rekognisi)}>
                            Reject
                        </TextButton>
                    </Row> : <TextButton
                        color='indigo'
                        className={'text-xs'}
                        onClick={() => onUndo(comment.id_comment_rekognisi)}>
                        Proses Ulang
                    </TextButton>}
                </Row>}
            </Column>
        </Row>
    )
}

export default ItemCommentRecognition
