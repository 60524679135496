import React from 'react'
import { ButtonHover, Column, LargeHeadline, PrimaryButton, Row, Subtitle, TertiaryButton } from '../../../shared/ui'
import { useNavigate } from 'react-router-dom'
import { loginRoute, recognitionRoute, refreshTokenKey } from '../../../shared/variable'
import Cookies from 'universal-cookie'
import { TbBuildingCommunity, TbPlayerPlay, TbStack2 } from 'react-icons/tb'

const HeroLandingMobile = ({ onClickMitra }) => {

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    const navigateTo = useNavigate()

    return (
        <Column className='sm:hidden bg-white pt-24 pb-16'>
            <Column className='px-4 gap-10'>
                <Column className='gap-3'>
                    <Row className='gap-x-2 gap-y-4 flex-wrap'>
                        <LargeHeadline className='font-semibold'>
                            Ayo mulai
                        </LargeHeadline>
                        <Column className='w-fit h-full relative'>
                            <div className='w-full h-[3.5rem] absolute inset-0 my-auto bg-gradient-to-r from-green-100/90 to-green-50/50 pl-4 pr-8 border-l-[3px] border-green-400 rounded-r-lg'></div>
                            <LargeHeadline className='font-semibold text-green-500 pl-3 pr-6 z-10'>
                                Berkarir
                            </LargeHeadline>
                        </Column>
                    </Row>
                    <Row className='gap-x-2 gap-y-4 flex-wrap -translate-y-2'>
                        <LargeHeadline className='font-semibold'>
                            dan Gapai
                        </LargeHeadline>
                        <Column className='w-fit h-full relative'>
                            <div className='w-full h-[3.5rem] absolute inset-0 my-auto bg-gradient-to-r from-amber-100/90 to-amber-50/50 pl-4 pr-8 border-l-[3px] border-amber-400 rounded-r-lg'></div>
                            <LargeHeadline className='font-semibold text-amber-500 pl-3 pr-6 z-10'>
                                Sukses
                            </LargeHeadline>
                        </Column>
                    </Row>
                    <Row className='gap-x-2 gap-y-4 flex-wrap -translate-y-4'>
                        <LargeHeadline className='font-semibold'>
                            bersama
                        </LargeHeadline>
                        <Column className='w-fit h-full relative'>
                            <div className='w-full h-[3.5rem] absolute inset-0 my-auto bg-gradient-to-r from-indigo-100/90 to-indigo-50/50 pl-4 pr-8 border-l-[3px] border-indigo-400 rounded-r-lg'></div>
                            <LargeHeadline className='font-semibold text-indigo-600 pl-3 pr-6 z-10'>
                                Simfora
                            </LargeHeadline>
                        </Column>
                    </Row>
                </Column>
                <Subtitle className='text-slate-700'>
                    Temukan berbagai Informasi seputar Kabar Alumni, Event Alumni, Lowongan Kerja, Konsultasi Karier yang siap membantu alumni dengan dukungan konselor pilihan dan Business to Business sebagai fasilitas dalam mengembangkan bisnis antar alumni.
                </Subtitle>
                <Row className='gap-3 mt-3'>
                    <ButtonHover>
                        <PrimaryButton pills onClick={() => navigateTo(tokenCookies !== null ? recognitionRoute : loginRoute)} leadingIcon={tokenCookies !== null ? <TbStack2 className='h-5 w-5 my-auto' /> : <TbPlayerPlay className='h-5 w-5 my-auto' />} color='indigo' className='pl-5 pr-6 py-3'>
                            Mulai Sekarang
                        </PrimaryButton>
                    </ButtonHover>
                    <ButtonHover>
                        <TertiaryButton onClick={onClickMitra} leadingIcon={<TbBuildingCommunity className='h-5 w-5 my-auto' />} color='indigo' className='pl-5 pr-6 py-3'>
                            Kemitraan UTY
                        </TertiaryButton>
                    </ButtonHover>
                </Row>
            </Column>
        </Column>
    )
}

export default HeroLandingMobile
