import moment from 'moment'
import React from 'react'
import { TbSend } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { getViewDisplay } from '../../../shared/lib'
import { Caption, Column, DotSeparated, Headline3, IconButton, Row, Text } from '../../../shared/ui'
import { eventRoute } from '../../../shared/variable'
import WListImageRecognition from '../../wlist_image_recognition'

const ItemEvent = ({ event, onShare }) => {

    const navigateTo = useNavigate()

    const onClickEvent = (ev) => {
        ev.stopPropagation();
        navigateTo(`${eventRoute}/${event.slug}`)
    }

    const onClickShare = (ev) => {
        ev.stopPropagation();
        onShare(event)
    }

    return (
        <Column
            className={'cursor-pointer bg-white px-4 py-6 gap-y-8 border-y border-gray-200 transition-all duration-200 sm:p-8 sm:shadow-sm sm:border-x lg:hover:shadow-md'}
            onClick={onClickEvent}>
            <Column className={'gap-y-5'}>
                <Headline3 className={'transition-all duration-200 cursor-pointer hover:text-indigo-600'}>
                    {event.title}
                </Headline3>
                <WListImageRecognition images={event.images} aspectRatio={event.rasio} action={20} />
                <div
                    className={'text-gray-800 font-inter text-justify text-base mt-2'}
                    dangerouslySetInnerHTML={{ __html: event.body.split('\n')[0].replaceAll('</p>', ' <span style="font-weight: 500; color: #4F46E5;">Baca selengkapnya</span></p>') }} />
            </Column>
            <Row className={'gap-x-4'}>
                <Column className={'gap-y-2'}>
                    <Row className={'gap-x-1 items-center'}>
                        <Text>
                            Diposting oleh
                        </Text>
                        <Text className={'font-semibold font-manrope capitalize'}>
                            Admin Alumni
                        </Text>
                    </Row>
                    <Row className={'flex-wrap gap-y-2 items-center'}>
                        <Caption>
                            {moment(event.created_at).format('DD/MM/YYYY HH.mm')} WIB
                        </Caption>
                        <DotSeparated />
                        <Caption>
                            {getViewDisplay(event.viewer ?? 0)}x dilihat
                        </Caption>
                    </Row>
                </Column>
                <IconButton
                    className={'transition-all translate-x-1 duration-200 p-1 group hover:bg-transparent hover:-translate-y-1'}
                    onClick={onClickShare}>
                    <TbSend className={'h-8 w-8 text-gray-600 rotate-12 stroke-1.5 group-hover:text-blue-600'} />
                </IconButton>
            </Row>
        </Column>
    )
}

export default ItemEvent