export const FullScreen = ({ id, refId, className = null, children }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`full-screen ${className}`}>
            {children}
        </div>
    )
}

export const WidthFull = ({ id, refId, className = null, children }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`width-full ${className}`}>
            {children}
        </div>
    )
}

export const HeightFull = ({ id, refId, className = null, children }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`height-full ${className}`}>
            {children}
        </div>
    )
}

export const WidthScreen = ({ id, refId, className = null, children }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`width-screen ${className}`}>
            {children}
        </div>
    )
}

export const HeightScreen = ({ id, refId, className = null, children }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`height-screen ${className}`}>
            {children}
        </div>
    )
}

export const Row = ({ id, refId, children, className = null, onClick }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`row ${className}`} onClick={onClick}>
            {children}
        </div>
    )
}

export const Column = ({ id, refId, children, className = null, onClick }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`column ${className}`} onClick={onClick}>
            {children}
        </div>
    )
}

export const GridRow = ({ id, refId, children, className = null }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`grid-row ${className}`}>
            {children}
        </div>
    )
}

export const GridCol = ({ id, refId, children, className = null }) => {
    return (
        <div
            id={id}
            ref={refId}
            className={`grid-col ${className}`}>
            {children}
        </div>
    )
}