import React, { useState } from 'react'
import { TbCalendarEvent, TbLogin2, TbLogout2, TbReport, TbStack2, TbUserCircle, TbX } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { resetRecoilState } from '../../atoms'
import { logoutAccountController } from '../../controllers/global_controllers'
import logo from '../../images/logo.png'
import { AlertDialog, Avatar, ButtonHover, Caption, Column, Headline3, LoadingDialog, PrimaryButton, Row, Sidebar, SidebarHeader, SidebarMenu, Subtitle, TertiaryButton, Text, TextButton } from '../../shared/ui'
import { getCurrentUser } from '../../shared/user'
import { almProfileRoute, almTracerRoute, eventRoute, loginRoute, recognitionRoute } from '../../shared/variable'

function AlmSidebar({ showOnMobile }) {

  const location = useLocation()
  const navigateTo = useNavigate()

  const { name, username } = getCurrentUser()

  const [resetRecoil, setResetRecoil] = useRecoilState(resetRecoilState)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const logoutAccount = async () => {
    setShowDialog(false)
    setLoading(true)
    const result = await logoutAccountController()
    if (result) {
      setTimeout(() => {
        localStorage.clear()
        setResetRecoil(true)
        setLoading(false)
        navigateTo(loginRoute, { replace: true })
      }, 300)
    }
  }

  return (
    <>
      <Sidebar
        hide
        showOnMobile={showOnMobile}>
        <SidebarMenu className={'p-4 gap-y-3.5 pb-36'}>
          <SidebarHeader className={'px-6'}>
            {username !== 'alumni' ?
              <Column className={'gap-y-5 items-center'}>
                <Avatar className={'h-20 w-20'} />
                <Column className={'gap-y-2'}>
                  <Subtitle className={'font-semibold text-center line-clamp-1'}>
                    {name}
                  </Subtitle>
                  <Caption className={'text-center'}>
                    {username}
                  </Caption>
                </Column>
              </Column> :
              <Column className={'gap-y-5 items-center'}>
                <Avatar
                  src={logo}
                  className={'h-20 w-20'} />
                <Column className={'gap-y-2'}>
                  <Subtitle className={'font-semibold text-center line-clamp-1'}>
                    Simfora UTY
                  </Subtitle>
                  <Caption className={'text-center'}>
                    Sistem Infromasi Alumni
                  </Caption>
                </Column>
              </Column>
            }
          </SidebarHeader>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbStack2 className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(recognitionRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(recognitionRoute)}>
            Berita Alumni
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbCalendarEvent className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(eventRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(eventRoute)}>
            Event Alumni
          </TextButton>
          {/* <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbBriefcase className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(careerRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(careerRoute)}>
            Lowongan
          </TextButton> */}
          {/* <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbMessage className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(almConsultationRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almConsultationRoute)}>
            Konsultasi
          </TextButton> */}
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbReport className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(almTracerRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almTracerRoute)}>
            Tracer Study
          </TextButton>
          {/* <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbHeartHandshake className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(almBusinessRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almBusinessRoute)}>
            Business to Business
          </TextButton> */}
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbUserCircle className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(almProfileRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almProfileRoute)}>
            Profil Alumni
          </TextButton>
        </SidebarMenu>
        <Column className={'absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200'}>
          <ButtonHover className={'w-full'}>
            <PrimaryButton
              pills
              color={username !== 'alumni' ? 'red' : 'indigo'}
              className={'w-full'}
              leadingIcon={
                username !== 'alumni' ? <TbLogout2 className={'h-5 w-5 my-auto'} /> :
                  <TbLogin2 className={'h-5 w-5 my-auto'} />
              }
              onClick={username !== 'alumni' ? () => setShowDialog(true) : () => navigateTo(loginRoute)}>
              {username !== 'alumni' ? 'Keluar Akun' : 'Masuk Akun'}
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Sidebar>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3>
              Keluar Akun
            </Headline3>
            <Text>
              Apakah anda yakin ingin keluar dari Simfora UTY?
            </Text>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbLogout2 className='h-5 w-5 my-auto' />
                }
                onClick={logoutAccount}>
                Keluar
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Keluar dari akun...' />
    </>
  )
}

export default AlmSidebar