import { Transition } from '@headlessui/react';
import jwtDecode from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { TbAlertTriangle } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';
import { acceptedConsultation, acceptedRecognition, blnWisudaTracer, currentIndexTabComments, currentIndexTabKonsul, currentIndexTabProfileAlumni, currentIndexTabRecognition, currentIndexTabTracer, currentUserData, dataAlumni, dataTracer, dateRangeKuesionerTracer, gRecognition, grafikOvRecognition, grafikOvTracer, grafikStatusOvTracer, indexInputTracer, initialProvinsi, inputTracer, inputTracerAction, masterPosisiTracer, masterProvinsiTracer, masterSumBiayaTracer, masterTingKerjaTracer, overviewRecognition, processedComments, processedConsultation, processedRecognition, readNotifications, rejectedComments, rejectedConsultation, rejectedRecognition, rekapInfoWisudaTracer, rekapKuesionerTracer, rekapOvRecognition, rekapWisudaTracer, resetRecoilState, savedRecognition, taggedRecognition, thnWisudaTracer, unreadNotifications } from '../atoms';
import { getFullNameUser, postLoginAccount, searchUserLoginController } from '../controllers/apicontrollers';
import bguty from '../images/bguty.jpeg';
import simfora from '../images/logo2.png';
import { AlertDialog, ButtonHover, Card, Column, Form, FullScHeight, FullScreen, Headline1, Headline3, HorizontalDivider, LoadingView, OutlineInput, PrimaryButton, Row, Text, TextButton, Title, TransitionPage } from '../shared/ui';
import { admRootRoute, admin, alumni, identifyUserKey, konRootRoute, konselor, landingRoute, mitRootRoute, mitra, proRootRoute, prodi, refreshTokenKey, tokenApiKey } from '../shared/variable';

const LoginPage = () => {

    const cookies = new Cookies()
    const usernameRef = useRef(null);
    const navigateTo = useNavigate()
    const location = useLocation()
    const action = location.state?.action ?? 10;

    const resetRecoil = useRecoilValue(resetRecoilState)
    const [user, setUser] = useRecoilState(currentUserData)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [listSuggestion, setListSuggestion] = useState([])
    const [showSuggestion, setShowSuggestion] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)
    const [emptySuggestion, setEmptySuggestion] = useState(false)

    const tokenCookies = cookies.get(refreshTokenKey) ?? null
    const identifyUser = localStorage.getItem(identifyUserKey)

    //? GLOBAL RECOIL STATE
    const resetCurrentUserData = useResetRecoilState(currentUserData);
    const resetReadNotifications = useResetRecoilState(readNotifications);
    const resetUnreadNotifications = useResetRecoilState(unreadNotifications);
    const resetResetRecoil = useResetRecoilState(resetRecoilState);

    //? ADMIN RECOIL STATE
    const resetCurrentIndexTabRecognition = useResetRecoilState(currentIndexTabRecognition);
    const resetOverviewRecognition = useResetRecoilState(overviewRecognition);
    const resetGrafikOvRecognition = useResetRecoilState(grafikOvRecognition);
    const resetRekapOvRecognition = useResetRecoilState(rekapOvRecognition);
    const resetAcceptedRecognition = useResetRecoilState(acceptedRecognition);
    const resetProcessedRecognition = useResetRecoilState(processedRecognition);
    const resetRejectedRecognition = useResetRecoilState(rejectedRecognition);
    const resetCurrentIndexTabKonsul = useResetRecoilState(currentIndexTabKonsul);
    const resetAcceptedConsultation = useResetRecoilState(acceptedConsultation);
    const resetProcessedConsultation = useResetRecoilState(processedConsultation);
    const resetRejectedConsultation = useResetRecoilState(rejectedConsultation);
    const resetCurrentIndexTabTracer = useResetRecoilState(currentIndexTabTracer);
    const resetGrafikOvTracer = useResetRecoilState(grafikOvTracer);
    const resetGrafikStatusOvTracer = useResetRecoilState(grafikStatusOvTracer);
    const resetRekapKuesionerTracer = useResetRecoilState(rekapKuesionerTracer);
    const resetDateRangeKuesionerTracer = useResetRecoilState(dateRangeKuesionerTracer);
    const resetRekapWisudaTracer = useResetRecoilState(rekapWisudaTracer);
    const resetThnWisudaTracer = useResetRecoilState(thnWisudaTracer);
    const resetBlnWisudaTracer = useResetRecoilState(blnWisudaTracer);
    const resetRekapInfoWisudaTracer = useResetRecoilState(rekapInfoWisudaTracer);
    const resetCurrentIndexTabComments = useResetRecoilState(currentIndexTabComments);
    const resetProcessedComments = useResetRecoilState(processedComments);
    const resetRejectedComments = useResetRecoilState(rejectedComments);

    //? ALUMNI RECOIL STATE
    const resetDataAlumni = useResetRecoilState(dataAlumni);
    const resetDataRecognition = useResetRecoilState(gRecognition);
    const resetInputTracer = useResetRecoilState(inputTracer);
    const resetIndexInputTracer = useResetRecoilState(indexInputTracer);
    const resetInputTracerAction = useResetRecoilState(inputTracerAction);
    const resetMasterProvinsiTracer = useResetRecoilState(masterProvinsiTracer);
    const resetInitProvTracer = useResetRecoilState(initialProvinsi);
    const resetMasterTingKerjaTracer = useResetRecoilState(masterTingKerjaTracer);
    const resetMasterPosisiTracer = useResetRecoilState(masterPosisiTracer);
    const resetMasterSumBiayaTracer = useResetRecoilState(masterSumBiayaTracer);
    const resetDataTracer = useResetRecoilState(dataTracer);
    const resetCurrentIndexTabProfileAlumni = useResetRecoilState(currentIndexTabProfileAlumni);
    const resetSavedRecognition = useResetRecoilState(savedRecognition);
    const resetTaggedRecognition = useResetRecoilState(taggedRecognition);

    const searchUserLogin = async (event) => {
        event.preventDefault();
        const query = event.target.value;
        if (query.length > 2) {
            setLoadingSearch(true)
            const props = {
                query: query,
                identify: action === 10 ? alumni : mitra
            }
            const result = await searchUserLoginController(props)
            if (result.status) {
                const data = [...result.data]
                setLoadingSearch(false)
                setListSuggestion(data)
                if (data.length > 0) {
                    setEmptySuggestion(false)
                } else {
                    setEmptySuggestion(true)
                }
            } else {
                setLoadingSearch(false)
            }
        } else {
            setLoadingSearch(false)
            setListSuggestion([])
        }
    }

    const selectSuggestionOption = (suggestion) => {
        usernameRef.current.value = suggestion.username
    }

    const onSubmitForm = async (event) => {
        event.preventDefault();
        const username = event.target.username.value;
        const password = event.target.password.value;

        setLoading(true)
        const result = await postLoginAccount(username, password)
        if (result.status) {
            const { kode } = jwtDecode(localStorage.getItem(tokenApiKey))
            if (kode === 2) {
                const { name, username, angkatan } = jwtDecode(localStorage.getItem(tokenApiKey))
                setUser({
                    name: name,
                    username: username,
                    angkatan: angkatan,
                })
            } else {
                await getFullNameOfUser(kode, username)
            }
            navigateTo(result.page, { replace: true })
        } else {
            setErrorMessage(result.message)
            setTimeout(() => {
                setLoading(false)
                setShowDialog(true)
            }, 300)
        }
    }

    const getFullNameOfUser = async (identify, username) => {
        const result = await getFullNameUser(identify, username)
        if (result.status) {
            setUser({
                name: result.data,
                username: username,
            })
        }
    }

    const requestResetRecoilState = () => {
        //?GLOBAL
        resetCurrentUserData()
        resetReadNotifications()
        resetUnreadNotifications()
        resetResetRecoil()

        //? ADMIN
        resetCurrentIndexTabRecognition()
        resetOverviewRecognition()
        resetGrafikOvRecognition()
        resetRekapOvRecognition()
        resetAcceptedRecognition()
        resetProcessedRecognition()
        resetRejectedRecognition()
        resetCurrentIndexTabKonsul()
        resetAcceptedConsultation()
        resetProcessedConsultation()
        resetRejectedConsultation()
        resetCurrentIndexTabTracer()
        resetGrafikOvTracer()
        resetGrafikStatusOvTracer()
        resetRekapKuesionerTracer()
        resetDateRangeKuesionerTracer()
        resetRekapWisudaTracer()
        resetThnWisudaTracer()
        resetBlnWisudaTracer()
        resetRekapInfoWisudaTracer()
        resetCurrentIndexTabComments()
        resetProcessedComments()
        resetRejectedComments()

        //? ALUMNI
        resetCurrentUserData()
        resetReadNotifications()
        resetUnreadNotifications()
        resetDataAlumni()
        resetDataRecognition()
        resetInputTracer()
        resetIndexInputTracer()
        resetInputTracerAction()
        resetMasterProvinsiTracer()
        resetInitProvTracer()
        resetMasterTingKerjaTracer()
        resetMasterPosisiTracer()
        resetMasterPosisiTracer()
        resetMasterSumBiayaTracer()
        resetDataTracer()
        resetCurrentIndexTabProfileAlumni()
        resetSavedRecognition()
        resetTaggedRecognition()
    }

    useEffect(() => {
        if (tokenCookies !== null) {
            if (identifyUser === admin) {
                navigateTo(admRootRoute)
            } else if (identifyUser === prodi) {
                navigateTo(proRootRoute)
            } else if (identifyUser === alumni) {
                navigateTo(landingRoute)
            } else if (identifyUser === konselor) {
                navigateTo(konRootRoute)
            } else {
                navigateTo(mitRootRoute)
            }
        }
        setTimeout(() => {
            if (resetRecoil) {
                requestResetRecoilState()
            }
        }, 500)
    }, [])

    return (
        <>
            <FullScreen>
                <TransitionPage>
                    <Row>
                        <FullScHeight className='w-full hidden lg:flex bg-white'>
                            <img src={bguty} className='object-cover' />
                        </FullScHeight>
                        <FullScHeight className='w-full lg:max-w-2xl bg-white'>
                            <Column className='h-screen p-5 sm:p-12 gap-12'>
                                {/* <img src={simfora} className='w-44' /> */}
                                <Column className='max-w-lg mx-auto my-auto gap-12 p-3 sm:p-8'>
                                    <img src={simfora} className='w-44' />
                                    <Column className='gap-y-2'>
                                        {action === 10 ?
                                            <Headline1 className='font-semibold'>
                                                Sistem Informasi <span className='text-indigo-600'>Alumni</span>
                                            </Headline1> : <Headline1 className='font-semibold'>
                                                Masuk Dashboard <span className='text-indigo-600'>Mitra</span>
                                            </Headline1>
                                        }
                                        {action === 10 ? <Title className='font-medium'>
                                            Universitas Teknologi Yogyakarta
                                        </Title> : <Title className='font-medium'>
                                            Sistem Informasi Alumni Universitas Teknologi Yogyakarta
                                        </Title>}
                                    </Column>
                                    <Column className='gap-y-10 pb-14'>
                                        <Form onSubmit={onSubmitForm}>
                                            <Column className='relative gap-y-8'>
                                                <OutlineInput
                                                    refs={usernameRef}
                                                    id='username'
                                                    type='text'
                                                    className='py-3'
                                                    inputWidth='w-full'
                                                    bgColor='bg-slate-50'
                                                    label={action === 10 ? 'Cari Data Alumni' : 'Cari Data Mitra'}
                                                    placeholder={action === 10 ? 'Masukkan nama lengkap' : 'Masukkan nama mitra'}
                                                    helperText={action === 10 ? 'Cari berdasarkan nama atau identitas diri lainnya' : 'Cari berdasarkan nama mitra'}
                                                    maxLength={20}
                                                    required={true}
                                                    onChange={searchUserLogin}
                                                    onFocus={() => setShowSuggestion(true)}
                                                    onBlur={() => setShowSuggestion(false)} />
                                                <Transition
                                                    className='absolute overflow-hidden top-[5rem] inset-x-0 bg-white z-20 rounded-lg border-[1px] border-gray-200 shadow-xl shadow-black/5'
                                                    show={showSuggestion}
                                                    enter="transition ease-in-out duration-200"
                                                    enterFrom="translate-y-8 opacity-0"
                                                    enterTo="translate-y-1 opacity-100"
                                                    leave="transition ease-out duration-200"
                                                    leaveFrom="translate-y-1 opacity-100"
                                                    leaveTo="translate-y-8 opacity-0">
                                                    <Column>
                                                        <Column className='max-h-72 divide-y-[1px] divide-gray-200 overflow-y-auto scrollbar-hide'>
                                                            {listSuggestion.map((suggestion, idx) => {
                                                                return (
                                                                    <button key={idx} type='button' className='row px-4 py-3 hover:bg-slate-50 cursor-pointer' onClick={() => selectSuggestionOption(suggestion)}>
                                                                        <Text key={idx} className='line-clamp-1'>
                                                                            {suggestion.username} - <span className='capitalize'>{suggestion.name.toLowerCase()}</span>
                                                                        </Text>
                                                                    </button>
                                                                )
                                                            })}
                                                        </Column>
                                                        {listSuggestion.length > 0 && <HorizontalDivider removeMargin />}
                                                        <Row className='px-4 py-3 gap-3'>
                                                            {loadingSearch && <LoadingView className='text-indigo-600 ' />}
                                                            <Text className='text-gray-500'>
                                                                {loadingSearch ? 'Mencari data...' : emptySuggestion ? `"${usernameRef.current.value}" tidak ditemukan!` : listSuggestion.length > 0 ? `Hasil pencarian "${usernameRef.current.value}"` : 'Masukkan minimal 3 karakter!'}
                                                            </Text>
                                                        </Row>
                                                    </Column>
                                                </Transition>
                                                <OutlineInput
                                                    id='password'
                                                    type='password'
                                                    className='py-3'
                                                    inputWidth='w-full'
                                                    bgColor='bg-slate-50'
                                                    label='Kata Sandi'
                                                    placeholder='Masukkan kata sandi'
                                                    helperText={action === 10 ? 'Tanggal lahir (DDMMYYYY)' : null}
                                                    maxLength={20}
                                                    togglePass={true}
                                                    required={true} />
                                                <ButtonHover className='w-full mt-2'>
                                                    <PrimaryButton disabled={loading} color='indigo' type='submit' className='w-full py-3'>
                                                        {loading && <LoadingView />}
                                                        {loading ? 'Memproses masuk...' : 'Masuk'}
                                                    </PrimaryButton>
                                                </ButtonHover>
                                            </Column>
                                        </Form>
                                        <Row className='justify-center gap-1'>
                                            <Text className='text-slate-700 my-auto translate-y-[0.02rem]'>
                                                {action === 10 ? 'Data Alumni tidak ditemukan?' : 'Lupa Kata Sandi?'}
                                            </Text>
                                            <a className='text-btn text-indigo-600 hover:text-indigo-700' target='_blank'
                                                href='whatsapp://send/?phone=628561236465&text=Halo&type=phone_number&app_absent=0'>
                                                Bantuan Masuk
                                            </a>
                                        </Row>
                                    </Column>
                                </Column>
                            </Column>
                        </FullScHeight>
                    </Row>
                </TransitionPage>
            </FullScreen>
            <AlertDialog className='max-w-xs' show={showDialog} onClose={() => setShowDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 py-10 items-center gap-8'>
                        <Card className='p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50'>
                            <TbAlertTriangle className='h-14 w-14 stroke-1.5 text-orange-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Terjadi Kesalahan
                            </Headline3>
                            <Text className='text-center'>
                                {errorMessage}
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Tutup
                    </TextButton>
                </Column>
            </AlertDialog>
        </>
    )
}

export default LoginPage