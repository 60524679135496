import React, { useState } from "react";
import { processMitraController } from "../../../../controllers/apicontrollers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableProcessedMitra from "./rows/row_processed_mitra";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Column,
  Form,
  Headline3,
  HorizontalDivider,
  Image,
  LoadingDialog,
  OutlineTextArea,
  PrimaryButton,
  Row,
  SecondaryButton,
  TertiaryButton,
  Text,
} from "../../../../shared/ui";
import {
  TbAlertTriangle,
  TbArrowUpRight,
  TbBrandWhatsapp,
  TbCertificate,
  TbChecks,
  TbMail,
  TbMapPin,
  TbPhone,
  TbUserCircle,
  TbX,
} from "react-icons/tb";
import toast from "react-hot-toast";

function TableProcessedMitra({ listMitra, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [currentMitra, setCurrentMitra] = useState(null);
  const [showNotes, setShowNotes] = useState(false);
  const [loading, setLoading] = useState(false);

  const onHandleClick = (mitra) => {
    setCurrentMitra(mitra);
    setShowDialog(true);
    console.log(mitra);
  };

  const processCurrentMitra = async (action, note) => {
    if (action === 20) {
      setShowNotes(false);
    } else {
      setShowDialog(false);
    }
    setLoading(true);
    const props = {
      idmitra: currentMitra.id_mitra,
      approve: action === 10 ? "ACCEPTED" : "REJECTED",
      alasan: note,
    };
    const result = await processMitraController(props);
    if (result.status) {
      setTimeout(() => {
        filterAccMitra();
        setLoading(false);
        toast.success(result.message);
      }, 200);
    }
  };

  const onShowNotes = () => {
    setShowDialog(false);
    setShowNotes(true);
  };

  const onSubmitReject = (event) => {
    event.preventDefault();
    const note = event.target.note.value;

    if (note) {
      processCurrentMitra(20, note);
    }
  };

  const filterAccMitra = () => {
    // const newAccMitra = listAccMitra.filter(
    //   (mitra) => mitra.id_mitra !== currentMitra.id_mitra
    // );
    // const rows = totalRow - 1;
    // const page = rows / limitRow;
    // setTotalRow(rows);
    // setTotalPage(page);
    // setListAccMitra(newAccMitra);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Logo</TableHeadCell>
            <TableHeadCell>Nama</TableHeadCell>
            <TableHeadCell>Deskripsi</TableHeadCell>
            <TableHeadCell>Legalitas</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listMitra.length > 0 ? (
            listMitra.map((mitra, idx) => {
              return (
                <RowTableProcessedMitra
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  mitra={mitra}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-3xl"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        {currentMitra !== null && (
          <Column className="w-full">
            <Image
              src={currentMitra.foto}
              className="aspect-[16/5]"
              imgClasName="aspect-[16/5] object-cover"
              hoverScale={false}
            />
            <Column className="px-8 py-8 relative gap-y-5">
              <Image
                src={currentMitra.logo}
                className="flex flex-row absolute -top-[4rem] w-full bg-white aspect-square p-2 max-w-[8rem] rounded-full border-2 border-gray-200 items-center ring-4 ring-white"
                imgClasName="w-full max-w-[8rem] object-fill"
                hoverScale={false}
              />
              <Column className="gap-y-3 mt-[3rem]">
                <Headline3>{currentMitra.nama_mitra}</Headline3>
                <Text>{currentMitra.deskripsi}</Text>
              </Column>
              <HorizontalDivider />
              <Row className="gap-x-2">
                <div className="h-5 w-5 flex-1">
                  <TbCertificate className="h-5 w-5 my-auto text-gray-500" />
                </div>
                <Column className="gap-y-1">
                  <Caption>Legalitas Perusahaan</Caption>
                  <Text>{currentMitra.legalitas.split("/").pop()}</Text>
                </Column>
                <ButtonHover>
                  <a
                    className="flex flex-row items-center gap-x-2 tertiary-btn pr-5 hover:border-blue-500"
                    href={currentMitra.legalitas}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TbArrowUpRight className="h-5 w-5" /> Legalitas
                  </a>
                </ButtonHover>
              </Row>
              <HorizontalDivider />
              <Column className="gap-y-8">
                <Row className="gap-x-5">
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbUserCircle className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Penanggung Jawab (PIC)</Caption>
                      <Text>{currentMitra.pic_name}</Text>
                    </Column>
                  </Row>
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbMail className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Email</Caption>
                      <Text>{currentMitra.email}</Text>
                    </Column>
                  </Row>
                </Row>
                <Row className="gap-x-5">
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbPhone className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Nomor Telepon</Caption>
                      <Text>{currentMitra.no_telp}</Text>
                    </Column>
                  </Row>
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbBrandWhatsapp className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Nomor WhatsApp</Caption>
                      <Text>{currentMitra.no_wa}</Text>
                    </Column>
                  </Row>
                </Row>
                <Row className="gap-x-2">
                  <div className="h-5 w-5 flex-1">
                    <TbMapPin className="h-5 w-5 text-gray-500" />
                  </div>
                  <Column className="gap-y-1">
                    <Caption>Alamat</Caption>
                    <Text>{currentMitra.alamat}</Text>
                  </Column>
                </Row>
              </Column>
            </Column>
            <HorizontalDivider />
            <Row className="gap-x-5 justify-between px-8 pt-4 pb-8">
              <Row className="w-fit gap-x-3">
                <ButtonHover>
                  <PrimaryButton
                    color="indigo"
                    leadingIcon={<TbChecks className="h-5 w-5 my-auto" />}
                    className="pr-5"
                    onClick={() => processCurrentMitra(10, "-")}
                  >
                    Terima
                  </PrimaryButton>
                </ButtonHover>
                <ButtonHover>
                  <TertiaryButton
                    color="red"
                    leadingIcon={
                      <TbAlertTriangle className="h-5 w-5 my-auto" />
                    }
                    className="pr-5"
                    onClick={onShowNotes}
                  >
                    Tolak
                  </TertiaryButton>
                </ButtonHover>
              </Row>
              <ButtonHover>
                <SecondaryButton
                  leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                  className="pr-5"
                  onClick={() => setShowDialog(false)}
                >
                  Tutup
                </SecondaryButton>
              </ButtonHover>
            </Row>
          </Column>
        )}
      </AlertDialog>
      <AlertDialog
        className="max-w-2xl"
        show={showNotes}
        onClose={() => setShowNotes(false)}
      >
        {currentMitra !== null && (
          <Form onSubmit={onSubmitReject}>
            <Column className="p-8 gap-y-5">
              <Headline3>
                Tolak Pendaftaran - {currentMitra.nama_mitra}
              </Headline3>
              <OutlineTextArea
                id="note"
                className="py-3"
                inputWidth="w-full"
                bgColor="bg-slate-50"
                label="Berikan Catatan"
                placeholder="Masukkan catatan"
                helperText="Catatan akan dikirim kepada pendaftar melalui email"
                initialRow={8}
                maxLength={500}
                required
              />
              <Row className="gap-x-3 justify-end">
                <ButtonHover>
                  <PrimaryButton
                    type="submit"
                    color="red"
                    leadingIcon={
                      <TbAlertTriangle className="h-5 w-5 my-auto" />
                    }
                    className="pr-5"
                  >
                    Tolak
                  </PrimaryButton>
                </ButtonHover>
                <ButtonHover>
                  <SecondaryButton
                    leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                    className="pr-5"
                    onClick={() => setShowNotes(false)}
                  >
                    Batal
                  </SecondaryButton>
                </ButtonHover>
              </Row>
            </Column>
          </Form>
        )}
      </AlertDialog>
      <LoadingDialog show={loading} message="Sedang memproses..." />
    </>
  );
}

export default TableProcessedMitra;
