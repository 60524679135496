import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowSurveiPengguna from "./rows/row_survei_pengguna";
import {
  AlertDialog,
  Card,
  Column,
  Headline3,
  ListTileView,
  Row,
  Text,
  TextButton,
} from "../../../../shared/ui";

function TableSurveiPengguna({ listSurvei, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [tanggalSurvei, setTanggalSurvei] = useState(null);
  const [pengisiNama, setPengisiNama] = useState(null);
  const [pengisiPerusahaan, setPengisiPerusahaan] = useState(null);
  const [mhsNama, setMhsNama] = useState(null);
  const [mhsJabatan, setMhsJabatan] = useState(null);
  const [pengisiJabatan, setPengisiJabatan] = useState(null);
  const [jenjangProdi, setJenjangProdi] = useState(null);
  const [keunggulan, setKeunggulan] = useState(null);
  const [kelemahan, setKelemahan] = useState(null);

  const handleClick = (detailSurvei) => {
    setShowDialog(true);
    setMhsNama(detailSurvei.mhs_nama);
    setPengisiNama(detailSurvei.pengisi_nama);
    setPengisiPerusahaan(detailSurvei.pengisi_perusahaan);
    setTanggalSurvei(detailSurvei.created_at);
    setKeunggulan(detailSurvei.m_keunggulan);
    setKelemahan(detailSurvei.m_kelemahan);
    setMhsJabatan(detailSurvei.mhs_jabatan);
    setPengisiJabatan(detailSurvei.pengisi_jabatan);
    setJenjangProdi(detailSurvei.nama_prodi);
  };

  const onHandleSort = (value) => {
    setCurrentSort(value);
    onShort(value);
  };

  return (
    <>
      <p>test</p>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Nama Pengisi
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="title"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Nama Perusahaan
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="viewer"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Nama Lulusan
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="likes"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Program Studi
            </TableHeadCell>
            <TableHeadCell
              sortable
              sortkey="likes"
              currentSortKey={currentSort !== null && currentSort.key}
              onShortable={onHandleSort}
            >
              Waktu Survei
            </TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listSurvei.length > 0 ? (
            listSurvei.map((survei, idx) => {
              return (
                <RowSurveiPengguna
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  survei={survei}
                  handleClick={handleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog show={showDialog} onClose={() => setShowDialog(false)}>
        <Column className="divide-y-[1px] divide-gray-200">
          <Column className="px-5 pt-10 pb-8 items-center gap-8">
            <Column className="gap-2">
              <Headline3 className="text-center">Pengguna Lulusan</Headline3>
              <Text className="text-center">
                Survei pada{" "}
                <span className="font-manrope font-semibold">
                  {pengisiPerusahaan}
                </span>
              </Text>
            </Column>
            <Column className="gap-5">
              <Card className="w-full p-0 shadow-none">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    className="py-3"
                    label="Nama Pengisi"
                    value={pengisiNama}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Jabatan"
                    value={pengisiJabatan}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                </Column>
              </Card>

              <Card className="w-full p-0 shadow-none">
                <Column className="divide-y-[1px] divide-gray-200">
                  <ListTileView
                    className="py-3"
                    label="Nama Mahasiswa"
                    value={mhsNama}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Program Studi"
                    value={jenjangProdi}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Jabatan"
                    value={mhsJabatan}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Keunggulan"
                    value={keunggulan}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                  <ListTileView
                    className="py-3"
                    label="Kelemahan"
                    value={kelemahan}
                    direction="flex-col"
                    valueClassName="text-base font-normal"
                  />
                </Column>
              </Card>

              <Card className="w-full p-0 shadow-none">
                <ListTileView
                  className="py-3"
                  label="Waktu Survei"
                  value={moment(tanggalSurvei).format("DD MMMM YYYY ")}
                  direction="flex-col"
                  valueClassName="text-base font-normal"
                />
              </Card>
            </Column>
          </Column>
          <TextButton
            onClick={() => setShowDialog(false)}
            className="w-full py-4 text-base hover:bg-slate-50/50"
          >
            Tutup
          </TextButton>
        </Column>
      </AlertDialog>
    </>
  );
}

export default TableSurveiPengguna;
