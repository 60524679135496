import React from 'react'
import MitraLayout from '../../layouts/mitra_layout'
import { Headline1, TransitionPage } from '../../shared/ui'

const MitDashboardPage = () => {
    return (
        <MitraLayout idpage='dashboard'>
            <TransitionPage>
                <Headline1>
                    Dashboard
                </Headline1>
            </TransitionPage>
        </MitraLayout>
    )
}

export default MitDashboardPage