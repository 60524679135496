import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { TbPencil, TbUpload } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { dataAlumni } from '../../../atoms'
import { getProfileAlumniController, updateContactController } from '../../../controllers/alumni_controllers'
import { AlertDialog, ButtonHover, Caption, Column, Form, Headline3, ListTileView, LoadingDialog, OutlineInput, PrimaryButton, Row, TertiaryButton, Text, Title } from '../../../shared/ui'

function AlmDetailProfilePage() {

  const result = useLoaderData()

  const [alumni, setAlumni] = useRecoilState(dataAlumni)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const getDetailFromLoader = () => {
    if (result.status) {
      setAlumni(result.data)
    }
  }

  const onSubmitForm = async (event) => {
    event.preventDefault()
    const email = event.target.email.value
    const nowa = event.target.nowa.value

    if (email && nowa) {
      setShowDialog(false)
      setLoading(true)
      const result = await updateContactController(email, nowa)
      if (result.status) {
        setTimeout(() => {
          setLoading(false)
          toast.success(result.message)
          setAlumni(alumni => { return { ...alumni, new_email: email, email: email, no_wa: nowa } })
        }, 200)
      } else {
        setTimeout(() => {
          setLoading(false)
          toast.error(result.message)
        }, 200)
      }
    }
  }

  useEffect(() => {
    getDetailFromLoader()
  }, [])

  return (
    <>
      <Column className='w-full max-w-4xl mx-auto gap-y-5 sm:gap-y-8 sm:px-14'>
        <Column className={'gap-y-3'}>
          <Column className={'p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x'}>
            <Row className={'items-center gap-x-3 justify-between'}>
              <Title className={'font-semibold'}>
                Data Lulusan
              </Title>
              <div className={'flex h-7 w-7 text-base font-inter font-medium text-gray-500 border bg-gray-100 rounded-full justify-center items-center'}>
                1
              </div>
            </Row>
            <Column className={'gap-y-2'}>
              {/* <ListTileView
                direction={'flex-col'}
                className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
                labelClassName={'text-sm'}
                valueClassName={`text-base font-normal ${alumni?.nik === null && 'text-gray-500'}`}
                label='NIK'
                value={alumni?.nik ?? 'Belum tersedia'} /> */}
              <ListTileView
                direction={'flex-col'}
                className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
                labelClassName={'text-sm'}
                valueClassName={'text-base font-normal'}
                label='Nama Lengkap'
                value={alumni?.nama} />
              <ListTileView
                direction={'flex-col'}
                className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
                labelClassName={'text-sm'}
                valueClassName={'text-base font-normal'}
                label='Tempat, Tanggal Lahir'
                value={`${alumni?.kotalhr}, ${moment(alumni?.tgllhr).format('DD MMMM YYYY')}`} />
              <ListTileView
                direction={'flex-col'}
                className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
                labelClassName={'text-sm'}
                valueClassName={'text-base font-normal'}
                label='Agama'
                value={alumni?.agama} />
              <ListTileView
                direction={'flex-col'}
                className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
                labelClassName={'text-sm'}
                valueClassName={`text-base font-normal ${alumni?.email === null && 'text-gray-500'}`}
                label='Email'
                value={alumni?.new_email ?? alumni?.email ?? 'Belum Tersedia'} />
              <ListTileView
                direction={'flex-col'}
                className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
                labelClassName={'text-sm'}
                valueClassName={`text-base font-normal ${alumni?.no_wa === null && 'text-gray-500'}`}
                label='No. WhatsApp'
                value={alumni?.no_wa ?? 'Belum Tersedia'} />
            </Column>
            <ButtonHover>
              <TertiaryButton
                pills
                color='indigo'
                className={'pl-5 pr-6'}
                leadingIcon={
                  <TbPencil className={'h-5 w-5 my-auto'} />
                }
                onClick={() => setShowDialog(true)}>
                Edit Kontak
              </TertiaryButton>
            </ButtonHover>
          </Column>
          <Caption className={'px-4'}>
            Jika Email atau No. WhatsApp sudah tidak aktif, Anda dapat memperbarui informasi kontak tersebut dengan klik tombol Edit Kontak.
          </Caption>
        </Column>
        <Column className={'p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x'}>
          <Row className={'items-center gap-x-3 justify-between'}>
            <Title className={'font-semibold'}>
              Data Akademik
            </Title>
            <div className={'flex h-7 w-7 text-base font-inter font-medium text-gray-500 border bg-gray-100 rounded-full justify-center items-center'}>
              2
            </div>
          </Row>
          <Column className={'gap-y-2'}>
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='NIM'
              value={alumni?.nim} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Tahun Angkatan'
              value={alumni?.angkatan} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Jenjang'
              value={alumni?.nama_jenjang} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Program Studi'
              value={alumni?.nama_jurusan} />
          </Column>
        </Column>
        <Column className={'p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x'}>
          <Row className={'tems-center gap-x-3 justify-between'}>
            <Title className={'font-semibold'}>
              Skripsi/Tugas Akhir
            </Title>
            <div className={'flex h-7 w-7 text-base font-inter font-medium text-gray-500 border bg-gray-100 rounded-full justify-center items-center'}>
              3
            </div>
          </Row>
          <Column className={'gap-y-2'}>
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Judul Skripsi/Tugas Akhir'
              value={alumni?.judulskripsi} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={`text-base font-normal ${alumni?.dosenpembimbing === null && 'text-gray-500'}`}
              label='Dosen Pembimbing'
              value={alumni?.dosenpembimbing ?? 'Belum tersedia'} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Tanggal Lulus'
              value={moment(alumni?.tgllulus).format('DD MMMM YYYY')} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Tanggal Wisuda'
              value={moment(alumni?.tglwisuda).format('DD MMMM YYYY')} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Gelar'
              value={alumni?.gelar} />
          </Column>
        </Column>
        <Column className={'p-5 gap-y-5 bg-white border-y sm:shadow-sm sm:p-8 sm:border-x'}>
          <Row className={'tems-center gap-x-3 justify-between'}>
            <Title className={'font-semibold'}>
              Ijazah & Transkrip
            </Title>
            <div className={'flex h-7 w-7 text-base font-inter font-medium text-gray-500 border bg-gray-100 rounded-full justify-center items-center'}>
              4
            </div>
          </Row>
          <Column className={'gap-y-2'}>
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='No. Ijazah'
              value={alumni?.noijasah} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='No. Transkrip'
              value={alumni?.notranskrip} />
            <ListTileView
              direction={'flex-col'}
              className={'transition-all duration-200 rounded-lg hover:bg-gray-50'}
              labelClassName={'text-sm'}
              valueClassName={'text-base font-normal'}
              label='Tanggal Dikeluarkan'
              value={moment(alumni?.tglwisuda).format('DD MMMM YYYY')} />
          </Column>
        </Column>
      </Column>
      <AlertDialog
        show={showDialog}
        className='max-w-lg'
        onClose={() => setShowDialog(false)}>
        <Form onSubmit={onSubmitForm}>
          <Column className={'p-5 gap-y-8 sm:p-8'}>
            <Column className='gap-y-2'>
              <Headline3>
                Edit Kontak
              </Headline3>
              <Text>
                Perbarui Email atau No. WhatsApp Anda yang sudah tidak aktif atau tidak bisa diakses kembali
              </Text>
            </Column>
            <OutlineInput
              id='email'
              inputWidth={'w-full'}
              label='Email'
              placeholder='Masukkan email'
              helperText='Contoh: info@uty.ac.id'
              bgColor='bg-gray-50'
              defaultValue={alumni?.new_email ?? alumni?.email}
              required />
            <OutlineInput
              id='nowa'
              inputWidth={'w-full'}
              label='Nomor Whatsapp'
              placeholder='Masukkan nomor whatsapp'
              helperText='Contoh: 0811283923'
              bgColor='bg-gray-50'
              defaultValue={alumni?.no_wa}
              maxLength={13}
              required />
            <Row className={'justify-end'}>
              <ButtonHover>
                <PrimaryButton
                  pills
                  type='submit'
                  color='indigo'
                  className='pl-5 pr-6'
                  leadingIcon={
                    <TbUpload className='h-5 w-5 my-auto' />
                  }>
                  Simpan
                </PrimaryButton>
              </ButtonHover>
            </Row>
          </Column>
        </Form>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Menyimpan kontak...' />
    </>
  )
}

export default AlmDetailProfilePage

export const getProfileAlumni = async () => {
  const result = await getProfileAlumniController()
  return result
}