import React, { useState, useEffect } from "react";
import { setupTahun, setupMonth } from "../shared/ta";
import {
  AlertDialog,
  ButtonGradient,
  ButtonHover,
  Column,
  Form,
  Headline2,
  Input,
  InputRadioButton,
  InputTextArea,
  PrimaryButton,
  Row,
  SelectInput,
  Text,
} from "../shared/ui";
import bgUty from "../images/bguty.jpg";
import logoUty from "../images/logo.png";
import { getMasterProdiController } from "../controllers/admin_controllers";
import { postSurveiController } from "../controllers/global_controllers";
import { BodyText, Headline3Text } from "../components/interfaces/text";

const SurveiLulusan = () => {
  const [selectedEtika, setSelectedEtika] = useState("");
  const [selectedKeahlian, setSelectedKeahlian] = useState("");
  const [selectedBahasa, setSelectedBahasa] = useState("");
  const [selectedInformasi, setSelectedInformasi] = useState("");
  const [selectedKomunikasi, setSelectedKomunikasi] = useState("");
  const [selectedKerjasama, setSelectedKerjasama] = useState("");
  const [selectedPengembangan, setSelectedPengembangan] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [selectedKesiapan, setSelectedKesiapan] = useState("");
  const [optionProdi, setOptionProdi] = useState([]);
  const [optionTahun, setOptionTahun] = useState([]);
  const [optionBulan, setOptionBulan] = useState([]);
  const [initProdi, setInitProdi] = useState({
    label: "Pilih program studi",
    value: null,
  });
  const [initTahun, setInitTahun] = useState({
    label: "Pilih tahun",
    value: null,
  });
  const [initBulan, setInitBulan] = useState({
    label: "Pilih bulan",
    value: null,
  });
  const [formData, setFormData] = useState({});

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e, fieldName) => {
    if (fieldName === "mhs_prodi") {
      setInitProdi(e);
      setFormData({
        ...formData,
        mhs_prodi: e.value.toString(),
      });
    } else if (fieldName === "mhs_kerja_bulan") {
      setInitBulan(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: e.label + " " + (initTahun.value || ""),
      }));
    } else if (fieldName === "mhs_kerja_tahun") {
      setInitTahun(e);
      setFormData((prevData) => ({
        ...prevData,
        mhs_kerja: (initBulan.label || "") + "" + e.value,
      }));
    }
  };

  const handleRadioChange = (value, radioName) => {
    if (radioName === "s_etika") {
      setSelectedEtika(value);
      setFormData({ ...formData, s_etika: value });
    } else if (radioName === "s_keahlian") {
      setSelectedKeahlian(value);
      setFormData({ ...formData, s_keahlian: value });
    } else if (radioName === "s_asing") {
      setSelectedBahasa(value);
      setFormData({ ...formData, s_asing: value });
    } else if (radioName === "s_teknologi") {
      setSelectedInformasi(value);
      setFormData({ ...formData, s_teknologi: value });
    } else if (radioName === "s_komunikasi") {
      setSelectedKomunikasi(value);
      setFormData({ ...formData, s_komunikasi: value });
    } else if (radioName === "s_kerjasama") {
      setSelectedKerjasama(value);
      setFormData({ ...formData, s_kerjasama: value });
    } else if (radioName === "s_pengembangan") {
      setSelectedPengembangan(value);
      setFormData({ ...formData, s_pengembangan: value });
    } else if (radioName === "s_terjun") {
      setSelectedKesiapan(value);
      setFormData({ ...formData, s_terjun: value });
    }
  };

  const getDataProdi = async () => {
    try {
      const dataProdi = await getMasterProdiController();
      const formattedOptions = dataProdi.data.map((prodi) => ({
        label: `${prodi.jenjang} - ${prodi.prodi}`,
        value: prodi.kode_prodi,
      }));
      setOptionProdi(formattedOptions);
    } catch (error) {
      console.error("error get data prodi", error.message);
    }
  };

  const option = [
    { label: "Sangat Baik", value: "sangat_baik" },
    { label: "Baik", value: "baik" },
    { label: "Cukup", value: "cukup" },
    { label: "Kurang", value: "kurang" },
  ];

  useEffect(() => {
    getDataProdi();
    setOptionTahun(setupTahun({ start: 2003 }));
    setOptionBulan(setupMonth({ start: "Januari" }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await postSurveiController(formData);
      setShowAlert(true);
    } catch (error) {
      console.error("error add data", error.message);
    }
  };

  return (
    <>
      <Column className="relative">
        <Row id="cover" className="fixed inset-0 z-0">
          <img
            src={bgUty}
            alt="Uty"
            className="object-cover md:object-scale-up h-screen w-full"
          />
        </Row>
        <Row
          id="content"
          className="relative z-10 flex justify-center min-h-screen"
        >
          <Column className="py-6 px-6 absolute top-0 mb-10 bg-none   max-w-6xl">
            <Column className="border px-5 md:px-16 py-10 space-y-6 bg-white rounded-2xl ">
              <Column className="space-y-4">
                <Row className="flex justify-center">
                  <img src={logoUty} alt="logo-uty" className="h-36" />
                </Row>
                <Column className="space-y-3">
                  <Headline2 className={"font-sans font-bold text-center"}>
                    Survei Kepuasan Pengguna Lulusan
                  </Headline2>
                  <Text className="uppercase font-times text-center text-[20px]">
                    universitas teknologi yogyakarta
                  </Text>
                </Column>
              </Column>
              <Column className="px-6">
                <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white  my-4" />
              </Column>

              <Column className="space-y-4">
                <Column>
                  <Text className="font-sans">Kepada Yth.</Text>
                  <Text className="font-bold font-sans">
                    Pengguna Lulusan UTY
                  </Text>
                </Column>

                <Column className="space-y-4">
                  <Text className="text-justify font-sans">
                    Dalam rangka meningkatkan kualitas lulusan Universitas
                    Teknologi Yogyakarta, kami melakukan survey terhadap
                    pengguna lulusan sebagai salah satu indikator evaluasi
                    perbaikan Universitas Teknologi Yogyakarta. Kami sangat
                    mengharapkan kesediaan Bapak/Ibu untuk dapat memberikan
                    penilaian terhadap lulusan Universitas Teknologi Yogyakarta
                    yang bekerja pada instansi atau perusahaan yang Bapak/Ibu
                    pimpin dengan cara mengisi kuisioner pengguna lulusan
                    Universitas Teknologi Yogyakarta berikut ini.
                  </Text>
                  <Text className="text-justify font-sans">
                    Penilaian ini akan menjadi umpan balik bagi kami dalam
                    melakukan evaluasi terhadap lulusan Universitas Teknologi
                    Yogyakarta. Penilaian dan masukan yang Bapak/Ibu berikan
                    sangat berharga bagi kami dan kami akan menjaga kerahasiaan
                    informasi yang diberikan tersebut.
                  </Text>
                  <Text className="text-justify font-sans">
                    Atas nama Rektor Universitas Teknologi Yogyakarta, kami
                    mengucapkan terima kasih yang sebesar-besarnya atas
                    kesediaan Bapak/Ibu meluangkan waktu sejenak untuk
                    berpartisapasi dalam pengisian survey pengguna lulusan
                    Universitas Teknologi Yogyakarta.
                  </Text>
                </Column>
                <Column>
                  <Text className="font-sans">Hormat Kami</Text>
                  <Text className="font-sans">Tim Tracer Study</Text>
                  <Text className="font-sans font-bold">
                    Universitas Teknologi Yogyakarta
                  </Text>
                </Column>
              </Column>

              <Column className="px-6">
                <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white  my-4" />
              </Column>

              <Form onSubmit={handleSubmit}>
                <Column className="space-y-6 md:space-y-4">
                  <Input
                    label="Nama Pengisi"
                    placeholder="Masukkan nama pengisi"
                    name="pengisi_nama"
                    value={formData.pengisi_nama}
                    onChange={handleInputChange}
                    required
                  />
                  <Input
                    label="Nama Perusahaan/Instansi"
                    placeholder="Masukkan nama Perusahaan/Instansi"
                    name="pengisi_perusahaan"
                    value={formData.pengisi_perusahaan}
                    onChange={handleInputChange}
                    required
                  />
                  <Input
                    label="Alamat Perusahaan/Instansi"
                    placeholder="Masukkan Alamat Perusahaan/Instansi"
                    name="pengisi_alamat"
                    value={formData.pengisi_alamat}
                    onChange={handleInputChange}
                    required
                  />
                  <Input
                    label="Posisi Jabatan Pengisi"
                    placeholder="Masukkan posisi jabatan pengisi"
                    name="pengisi_jabatan"
                    value={formData.pengisi_jabatan}
                    onChange={handleInputChange}
                    required
                  />
                  <Input
                    label="Email Pengguna"
                    placeholder="Masukkan email pengguna"
                    name="pengisi_email"
                    value={formData.pengisi_email}
                    onChange={handleInputChange}
                    required
                  />
                  <Input
                    label="Nama lulusan UTY yang dinilai"
                    placeholder="Masukkan nama lulusan UTY"
                    name="mhs_nama"
                    value={formData.mhs_nama}
                    onChange={handleInputChange}
                    required
                  />
                  <Column className="grid md:grid-cols-2 z-50 ">
                    <Text
                      htmlFor="prodi"
                      className="flex justify-start items-center font-sans mb-3 md:mb-0"
                    >
                      <span>
                        Program Studi lulusan UTY yang dinilai{" "}
                        <span>{"("}</span>
                        <span className="italic">opsional</span>
                        <span>{")"}</span>
                      </span>
                    </Text>
                    <SelectInput
                      width={"w-full"}
                      initial={initProdi}
                      name="mhs_prodi"
                      onChange={(prodi) =>
                        handleSelectChange(prodi, "mhs_prodi")
                      }
                      btnClassName={"py-2.5 pl-4 pr-3"}
                      maxHeight="max-h-36"
                      options={optionProdi}
                    />
                  </Column>

                  <Column className="grid md:grid-cols-2 ">
                    <Text
                      htmlFor="tanggal-masuk "
                      className="flex justify-start items-center font-sans mb-3 md:mb-0 d:w-[calc(100%-2.5rem)]  max-w-full"
                    >
                      <span>
                        Tanggal masuk perusahaan/instansi {" ("}
                        <span className="italic">optional</span>
                        {")"}
                      </span>
                    </Text>
                    <Column className="grid md:grid-cols-4 lg:grid-cols-3  gap-x-4 gap-y-3 md:gap-y-0 ">
                      <Column className="md:col-span-2 z-10">
                        <SelectInput
                          width={"w-full"}
                          initial={initBulan}
                          name="mhs_kerja_bulan"
                          onChange={(bulan) =>
                            handleSelectChange(bulan, "mhs_kerja_bulan")
                          }
                          btnClassName={"py-2.5 pl-4 pr-3"}
                          maxHeight="max-h-36"
                          options={optionBulan}
                        />
                      </Column>
                      <Column className={"md:col-span-2 lg:col-span-1"}>
                        <SelectInput
                          width={"w-full"}
                          initial={initTahun}
                          name="mhs_kerja_tahun"
                          onChange={(tahun) =>
                            handleSelectChange(tahun, "mhs_kerja_tahun")
                          }
                          btnClassName={"py-2.5 pl-4 pr-3"}
                          maxHeight="max-h-36"
                          options={optionTahun}
                        />
                      </Column>
                    </Column>
                  </Column>

                  <Input
                    label="Jabatan/posisi lulusan UTY yang dinilai"
                    placeholder="Masukkan nama lulusan UTY"
                    name="mhs_jabatan"
                    value={formData.mhs_jabatan}
                    onChange={handleInputChange}
                    optional
                  />

                  <Column className="px-6">
                    <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white  my-4" />
                  </Column>
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana <span className="font-bold">sikap/etika</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedEtika}
                    id="s_etika"
                    name="s_etika"
                    value={option.value}
                    onChange={(etika) => handleRadioChange(etika, "s_etika")}
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana <span className="font-bold">keahlian</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu berdasarkan
                        bidang ilmunya?
                      </>
                    }
                    checkedValue={selectedKeahlian}
                    id="s_keahlian"
                    name="s_keahlian"
                    onChange={(keahlian) =>
                      handleRadioChange(keahlian, "s_keahlian")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">berbahasa asing</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedBahasa}
                    id="s_asing"
                    name="s_asing"
                    onChange={(asing) => handleRadioChange(asing, "s_asing")}
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">
                          penggunaan teknologi informasi
                        </span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedInformasi}
                    id="s_teknologi"
                    name="s_teknologi"
                    onChange={(teknologi) =>
                      handleRadioChange(teknologi, "s_teknologi")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">berkomunikasi</span> lulusan
                        UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedKomunikasi}
                    id="s_komunikasi"
                    name="s_komunikasi"
                    onChange={(komunikasi) =>
                      handleRadioChange(komunikasi, "s_komunikasi")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">kerjasama</span> lulusan UTY
                        yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedKerjasama}
                    id="s_kerjasama"
                    name="s_kerjasama"
                    onChange={(kerjasama) =>
                      handleRadioChange(kerjasama, "s_kerjasama")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana kemampuan{" "}
                        <span className="font-bold">pengembangan diri</span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedPengembangan}
                    id="s_pengembangan"
                    name="s_pengembangan"
                    onChange={(pengembangan) =>
                      handleRadioChange(pengembangan, "s_pengembangan")
                    }
                    radioOptions={option}
                    required
                  />
                  <InputRadioButton
                    label={
                      <>
                        Bagaimana{" "}
                        <span className="font-bold">
                          kesiapan terjun di masyarakat
                        </span>{" "}
                        lulusan UTY yang bekerja di tempat Bapak/Ibu?
                      </>
                    }
                    checkedValue={selectedKesiapan}
                    id="s_terjun"
                    name="s_terjun"
                    onChange={(terjun) => handleRadioChange(terjun, "s_terjun")}
                    radioOptions={option}
                    required
                  />
                  <Column className="px-6">
                    <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white  my-4" />
                  </Column>
                  <InputTextArea
                    label="Menurut Bapak/Ibu, Apa keunggulan dari lulusan Universitas Teknologi Yogyakarta?"
                    name="m_keunggulan"
                    value={formData.m_keunggulan}
                    onChange={handleInputChange}
                  />
                  <InputTextArea
                    label="Menurut Bapak/Ibu, Apa kelemahan/kekurangan dari lulusan Universitas Teknologi Yogyakarta?"
                    name="m_kelemahan"
                    value={formData.m_kelemahan}
                    onChange={handleInputChange}
                  />
                  <InputTextArea
                    label="Mengingat berkembangnya tantangan kerja terbaru di lembaga Bapak/Ibu, keterampilan/kemampuan/kompetensi apakah yang perlu diberikan dan dikuasai oleh lulusan Universitas Teknologi Yogyakarta?"
                    name="m_dikuasai"
                    value={formData.m_dikuasai}
                    onChange={handleInputChange}
                  />
                  <InputTextArea
                    label="Apa harapan, Saran, dan/atau masukan Bapak/Ibu terhadap lulusan Universitas Teknologi Yogyakarta?"
                    name="m_saran"
                    value={formData.m_saran}
                    onChange={handleInputChange}
                  />
                </Column>
                <ButtonGradient type="submit">simpan dan kirim</ButtonGradient>
                <Column className="px-6">
                  <hr className="w-full h-[0.1rem] bg-gradient-to-r from-white via-slate-600 to-white  my-4" />
                </Column>
              </Form>

              <AlertDialog
                show={showAlert}
                className={"max-w-md"}
                onClose={closeAlert}
              >
                <Column className={"p-5 gap-y-8 sm:p-8"}>
                  <Column className="gap-y-2">
                    <Headline3Text>Berhasil Mengirim Kuisioner</Headline3Text>

                    <BodyText>
                      Terimakasih sudah mengisi dan mengirim kuisioner{" "}
                      <span className={"font-medium"}>
                        Pengguna Lulusan UTY
                      </span>
                    </BodyText>
                  </Column>

                  <Row className={"gap-x-3 justify-end"}>
                    <ButtonHover>
                      <PrimaryButton
                        pills
                        color={"red"}
                        className="pl-5 pr-6"
                        onClick={closeAlert}
                      >
                        tutup
                      </PrimaryButton>
                    </ButtonHover>
                  </Row>
                </Column>
              </AlertDialog>
            </Column>
          </Column>
        </Row>
      </Column>
    </>
  );
};

export default SurveiLulusan;
