import React from 'react'
import { ButtonHover, Column, ExtraLargeHeadline1, FullScHeight, PrimaryButton, Row, TertiaryButton, Title } from '../../../shared/ui'
import { useNavigate } from 'react-router-dom'
import { loginRoute, recognitionRoute, refreshTokenKey } from '../../../shared/variable'
import imglanding from '../../../images/img-landing2.png'
import path1 from '../../../images/path1.png'
import Cookies from 'universal-cookie'
import { TbBuildingCommunity, TbPlayerPlay, TbStack2 } from 'react-icons/tb'

const HeroLandingWeb = ({ onClickMitra }) => {

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    const navigateTo = useNavigate()

    return (
        <FullScHeight className='relative overflow-hidden hidden sm:flex h-screen pt-10'>
            <div className='w-full h-full absolute bg-white -z-20'></div>
            <img className='absolute -z-10 -right-10 -bottom-10 blur-3xl opacity-30' src={path1} />
            <Column className='h-full'>
                <Column className='relative max-w-7xl mx-auto my-auto gap-14'>
                    <Column className='gap-5'>
                        <Row className='gap-2 flex-wrap'>
                            <ExtraLargeHeadline1 className='font-semibold'>
                                Ayo mulai
                            </ExtraLargeHeadline1>
                            <Column className='w-fit h-full relative'>
                                <div className='w-full h-[4.5rem] absolute inset-0 my-auto bg-gradient-to-r from-green-100/90 to-green-50/50 pl-4 pr-8 border-l-[3px] border-green-400 rounded-r-lg'></div>
                                <ExtraLargeHeadline1 className='font-semibold text-green-500 pl-3 pr-6 z-10'>
                                    Berkarir
                                </ExtraLargeHeadline1>
                            </Column>
                        </Row>
                        <Row className='gap-2 flex-wrap -translate-y-2'>
                            <ExtraLargeHeadline1 className='font-semibold'>
                                dan Gapai
                            </ExtraLargeHeadline1>
                            <Column className='w-fit h-full relative'>
                                <div className='w-full h-[4.5rem] absolute inset-0 my-auto bg-gradient-to-r from-amber-100/90 to-amber-50/50 pl-4 pr-8 border-l-[3px] border-amber-400 rounded-r-lg'></div>
                                <ExtraLargeHeadline1 className='font-semibold text-amber-500 pl-3 pr-6 z-10'>
                                    Sukses
                                </ExtraLargeHeadline1>
                            </Column>
                        </Row>
                        <Row className='gap-2 flex-wrap -translate-y-4'>
                            <ExtraLargeHeadline1 className='font-semibold'>
                                bersama
                            </ExtraLargeHeadline1>
                            <Column className='w-fit h-full relative'>
                                <div className='w-full h-[4.5rem] absolute inset-0 my-auto bg-gradient-to-r from-indigo-100/90 to-indigo-50/50 pl-4 pr-8 border-l-[3px] border-indigo-400 rounded-r-lg'></div>
                                <ExtraLargeHeadline1 className='font-semibold text-indigo-600 pl-3 pr-6 z-10'>
                                    Simfora
                                </ExtraLargeHeadline1>
                            </Column>
                        </Row>
                    </Column>
                    <Title className='font-normal font-inter max-w-2xl leading-8 text-slate-700'>
                        Temukan berbagai Informasi seputar Kabar Alumni, Event Alumni, Lowongan Kerja, Konsultasi Karier yang siap membantu alumni dengan dukungan konselor pilihan dan Business to Business sebagai fasilitas dalam mengembangkan bisnis antar alumni.
                    </Title>
                    <Row className='gap-x-5 mt-8'>
                        <ButtonHover>
                            <PrimaryButton pills onClick={() => navigateTo(tokenCookies !== null ? recognitionRoute : loginRoute)} color='indigo' leadingIcon={tokenCookies !== null ? <TbStack2 className='h-5 w-5 my-auto' /> : <TbPlayerPlay className='h-5 w-5 my-auto' />} className='pl-7 pr-8 py-3.5'>
                                {tokenCookies !== null ? 'Eksplor Berita' : 'Mulai Sekarang'}
                            </PrimaryButton>
                        </ButtonHover>
                        <ButtonHover>
                            <TertiaryButton pills onClick={onClickMitra} leadingIcon={<TbBuildingCommunity className='h-5 w-5 my-auto' />} color='indigo' className='pl-7 pr-8 py-3.5'>
                                Kemitraan UTY
                            </TertiaryButton>
                        </ButtonHover>
                    </Row>
                    <img className='w-96 h-auto absolute inset-y-0 my-auto right-0' src={imglanding}></img>
                </Column>
            </Column>
        </FullScHeight>
    )
}

export default HeroLandingWeb
