import React from 'react'
import { TbArrowRight, TbChecks } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { ButtonHover, IconButton, TagView } from '../../../../../shared/ui'

function RowTableCounselorConsultation({ idx, counselor, page = 1, limit = 10, handleClick }) {
  return (
    <TableRow
      striped
      onClick={() => handleClick(counselor)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {counselor.nik}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {counselor.nama}
        </BodyText>
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            <TbChecks className={'h-4 w-4'} />
          }
          label='Aktif'
          color='green' />
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            className='p-1.5'
            color='indigo'
            bordered
            onClick={() => handleClick(counselor)}>
            <TbArrowRight className='h-5 w-5' />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableCounselorConsultation