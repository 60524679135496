import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { TbArrowRight } from 'react-icons/tb'
import { useLoaderData } from 'react-router-dom'
import { Column, Row } from '../../../components/interfaces/block'
import { Card } from '../../../components/interfaces/card'
import { OutlineInput, SearchInput } from '../../../components/interfaces/input'
import { BodyText, Headline2Text } from '../../../components/interfaces/text'
import { Pagination, PaginationOption, useDebounce } from '../../../components/interfaces/utility'
import { getKuesionerTracerController } from '../../../controllers/admin_controllers'
import TableKuesionerTracer from './tables/table_kuesioner_tracer'

function AdmKuesionerTracerPage() {

  const result = useLoaderData()

  const [listKuesioner, setListKuesioner] = useState([])
  const [limitRow, setLimitRow] = useState(10)
  const [totalRow, setTotalRow] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(null)
  const [currentSort, setCurrentSort] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [stsGetLoader, setStsGetLoader] = useState(false)

  const debounceSearch = useDebounce({ value: searchQuery, delay: 1000 })

  const [filterStart, setFilterStart] = useState(new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().slice(0, 10))
  const [filterEnd, setFilterEnd] = useState(new Date().toISOString().slice(0, 10))

  const getKuesionerTracerFromLoader = () => {
    if (result.status) {
      setListKuesioner(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
    setStsGetLoader(true)
  }

  const regetKuesionerTarcer = async () => {
    const result = await getKuesionerTracerController({
      search: searchQuery,
      page: currentPage,
      limit: limitRow,
      order: currentSort !== null ? currentSort.key : null,
      asc: currentSort !== null ? currentSort.type : null,
      start: filterStart,
      end: filterEnd,
    })
    if (result.status) {
      setListKuesioner(result.data)
      setTotalRow(result.totalRows)
      setTotalPage(result.totalPage)
    }
  }

  const onHandlePaginationOption = (value) => {
    setLimitRow(value)
    setCurrentPage(1)
  }

  const onHandleSearch = (event) => {
    event.preventDefault()
    setSearchQuery(event.target.value.length > 0 ? event.target.value : null)
    setCurrentPage(1)
    if (currentSort !== null) {
      setCurrentSort(null)
    }
  }

  const onHandlePagination = (value) => {
    setCurrentPage(value)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
  }

  const onHandleFilterStart = (event) => {
    event.preventDefault()
    setFilterStart(event.target.value)
  }

  const onHandleFilterEnd = (event) => {
    event.preventDefault()
    setFilterEnd(event.target.value)
  }

  useEffect(() => {
    getKuesionerTracerFromLoader()
  }, [])

  useEffect(() => {
    if (stsGetLoader) {
      regetKuesionerTarcer()
    }
  }, [debounceSearch, limitRow, currentPage, currentSort, filterStart, filterEnd])

  return (
    <Column className={'p-4 gap-y-5 sm:p-8'}>
      <Column className={'gap-y-2 flex-1'}>
        <Headline2Text className={'font-semibold'}>
          Data Kuesioner
        </Headline2Text>
        <BodyText>
          Semua data alumni yang lulus pada rentang tanggal {moment(filterStart).format('DD MMM YYYY')} s.d {moment(filterEnd).format('DD MMM YYYY')} dan sudah mengisi tracer studi.
        </BodyText>
      </Column>
      <Card className={'w-full'}>
        <Column className={'gap-y-5'}>
          <Column className='gap-x-4 gap-y-2 md:flex-row md:justify-between'>
            <Column className='w-full gap-x-4 gap-y-2 md:w-fit md:flex-row'>
              <PaginationOption
                initial={limitRow}
                onChange={onHandlePaginationOption} />
              <Row className='w-full h-[2.64rem] gap-3'>
                <div className={'w-full sm:w-fit'}>
                  <OutlineInput
                    id='start'
                    type='date'
                    label='Tanggal mulai'
                    maxDate={filterEnd}
                    className='appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50'
                    placeholder='dd/mm/yyyy'
                    onChange={onHandleFilterStart}
                    defaultValue={filterStart}
                    hideLabel
                    required />
                </div>
                <TbArrowRight className='hidden h-5 w-5 my-auto text-gray-500 sm:flex' />
                <div className={'w-full sm:w-fit'}>
                  <OutlineInput
                    id='end'
                    type='date'
                    label='Tanggal selesai'
                    minDate={filterStart}
                    maxDate={new Date().toISOString().slice(0, 10)}
                    className='appearance-none h-[2.64rem] px-3 hover:border-indigo-500 hover:ring-2 hover:ring-indigo-50'
                    placeholder='dd/mm/yyyy'
                    onChange={onHandleFilterEnd}
                    defaultValue={filterEnd}
                    hideLabel
                    required />
                </div>
              </Row>
            </Column>
            <SearchInput
              defaultValue={searchQuery}
              placeholder='Cari kuesioner'
              onChange={onHandleSearch} />
          </Column>
          <TableKuesionerTracer
            limit={limitRow}
            page={currentPage}
            listKuesioner={listKuesioner}
            onShort={onHandleSort} />
          {listKuesioner.length > 0 && <Pagination
            limitRow={limitRow}
            totalRow={totalRow}
            totalPage={totalPage}
            triger={currentSort !== null && currentSort.key}
            onChange={onHandlePagination} />}
        </Column>
      </Card>
    </Column>
  )
}

export default AdmKuesionerTracerPage

export const getAdmKuesionerTracer = async ({ search = null, page = 1, limit = 10, order = null, asc = null }) => {

  const start = new Date(new Date().setDate(new Date().getDate() - 365)).toISOString().slice(0, 10)
  const end = new Date().toISOString().slice(0, 10)

  const props = {
    search: search,
    page: page,
    limit: limit,
    order: order,
    asc: asc,
    start: start,
    end: end
  }
  const result = await getKuesionerTracerController(props)
  return result
}