import React, { useEffect, useState } from 'react'
import { TbArrowRight } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { getFullNameUser } from '../../../../../controllers/apicontrollers'
import { getViewDisplay } from '../../../../../shared/lib'
import { ButtonHover, IconButton, ShimmerAnimation, ShimmerItem } from '../../../../../shared/ui'

function RowTableAcceptedRecognition({ idx, recognition, page = 1, limit = 10, handleClick }) {

  const [fullName, setFullName] = useState(null)

  const getFullNameOfUser = async () => {
    const result = await getFullNameUser(recognition.id_identify, recognition.id_user)
    if (result.status) {
      setFullName(result.data)
    }
  }

  useEffect(() => {
    getFullNameOfUser()
  }, [])

  return (
    <TableRow
      striped
      onClick={() => handleClick(recognition)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        {
          fullName ? <BodyText className={'line-clamp-2'}>
            {fullName}
          </BodyText> : <ShimmerAnimation>
            <ShimmerItem className='h-5 w-full' />
          </ShimmerAnimation>
        }
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {recognition.title}
        </BodyText>
      </TableCell>
      <TableCell className={'text-center'}>
        {getViewDisplay(recognition.viewer)}
      </TableCell>
      <TableCell className={'text-center'}>
        {getViewDisplay(recognition.likes)}
      </TableCell>
      <TableCell className={'text-center'}>
        {getViewDisplay(recognition.comments.length ?? 0)}
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            className='p-1.5'
            color='indigo'
            bordered
            onClick={() => handleClick(recognition)}>
            <TbArrowRight className='h-5 w-5' />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableAcceptedRecognition