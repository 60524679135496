import React from 'react'
import { Caption, Column, Overline, Row, Text } from '../shared/ui'
import { TbBellRinging } from 'react-icons/tb'
import moment from 'moment'
import CNotifications from './cards/cnotifications'

const WListNotification = ({ action, listNotification, onReadNotification }) => {
    return listNotification.length > 0 && (
        <Column className='gap-y-2'>
            <Overline className='pl-2 font-medium uppercase'>
                {action === 10 ? 'Belum Dibaca' : 'Sudah Dibaca'}
            </Overline>
            {listNotification.map((notif, idx) => {
                return (
                    <CNotifications key={idx} notif={notif} action={action} onReadNotification={onReadNotification} />
                )
            })}
        </Column>
    )
}

export default WListNotification
