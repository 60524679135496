import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { TbAlertTriangle, TbArrowRight, TbPhotoPlus, TbUpload } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import WAspectRatioImage from '../../components/waspect_ratio_image'
import WPreviewEvent from '../../components/wpreview_event'
import { saveEventController } from '../../controllers/apicontrollers'
import { AlertDialog, ButtonHover, Caption, Card, Column, Form, Headline1, Headline3, Image, LoadingDialog, OutlineInput, PrimaryButton, Row, SelectView, Text, TextButton, TextEditor, ToasterView } from '../../shared/ui'

const AdmAddEventPage = () => {

    const navigateTo = useNavigate()
    const [foto, setFoto] = useState(null)
    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')
    const [ratio, setRatio] = useState('aspect-video')
    const [loading, setLoading] = useState(false)
    const [showLargeDialog, setShowLargeDialog] = useState(false)
    const [initialCategory, setInitialCategory] = useState({ label: 'Event Alumni', value: 101, })
    const [dateRange, setDateRange] = useState({
        start: new Date().toISOString().slice(0, 10),
        end: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().slice(0, 10),
    })
    const opsiCategory = [
        {
            label: 'Event Alumni',
            value: 101,
        },
        {
            label: 'Event Karir',
            value: 102,
        },
    ]

    const onChangeFoto = (event) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 2048) {
                setFoto(file)
            } else {
                setShowLargeDialog(true)
            }
        }
    }

    const updateImageAspectRatio = (ratio) => {
        setRatio(ratio)
    }

    const onChangeTitle = (event) => {
        event.preventDefault();
        setTitle(event.target.value)
    }

    const onChangeBody = (value) => {
        setBody(value)
    }

    const onChangeDate = (event) => {

    }

    const onSubmitForm = async (event) => {
        event.preventDefault()
        const title = event.target.title.value
        const body = event.target.body.value
        const start = event.target.start.value
        const end = event.target.end.value

        if (foto && title && body && start && end) {
            setLoading(true)
            const result = await saveEventController(foto, ratio, title, body, initialCategory.value === 101 ? 'ALUMNI' : 'KARIR', start, end)
            if (result.status) {
                setTimeout(() => {
                    setLoading(false)
                    toast.success(result.message)
                    navigateTo(-1)
                }, 200)
            } else {
                setTimeout(() => {
                    setLoading(false)
                    toast.error(result.message)
                }, 200)
            }
        } else {
            toast.error('Lengkapi semua isian formulir tambah event!')
        }
    }

    return (
        <>
            <Card className='w-full rounded-none border-none min-h-screen px-0 gap-y-5 pt-14 pb-5'>
                <Column className='px-4 py-6 sm:p-6 xl:p-8 gap-y-8'>
                    <Headline1>
                        Buat Event
                    </Headline1>
                    <Form onSubmit={onSubmitForm}>
                        <Column className='gap-y-8'>
                            <Column className='gap-1.5'>
                                <Caption>
                                    Gambar Event <span className='text-red-500'>*</span>
                                </Caption>
                                <Card className='w-full relative aspect-video p-0 overflow-hidden bg-slate-50'>
                                    <input
                                        id='foto'
                                        type='file'
                                        className='hidden'
                                        accept='image/*'
                                        onChange={onChangeFoto}
                                        onClick={(event) => {
                                            event.currentTarget.value = null
                                        }} />
                                    <label htmlFor='foto' className='w-full aspect-video flex items-center cursor-pointer'>
                                        {foto ? <Image src={URL.createObjectURL(foto)} imgClasName='w-full aspect-video object-cover' hoverScale={false} /> : <Column
                                            className='items-center gap-3'>
                                            <TbPhotoPlus className='h-10 w-10 text-indigo-400 stroke-1.5' />
                                            <Caption className='text-center new-line'>
                                                {'Tambahkan\nGambar Event'}
                                            </Caption>
                                        </Column>}
                                    </label>
                                </Card>
                                <Row className='justify-between mt-2'>
                                    <WAspectRatioImage onChange={updateImageAspectRatio} />
                                </Row>
                            </Column>
                            <OutlineInput
                                id='title'
                                inputWidth='w-full'
                                label='Judul Event'
                                placeholder='Masukkan judul event'
                                helperText='Contoh: Penelusuran Studi Periode Agustus 2023'
                                onChange={onChangeTitle}
                                bgColor='bg-slate-50'
                                required />
                            <TextEditor
                                id='body'
                                label='Deskripsi Event'
                                onChange={onChangeBody}
                                required />
                            <SelectView
                                width='w-full'
                                label='Kategori Event'
                                helperText='Pilih salah satu'
                                initial={initialCategory}
                                options={opsiCategory}
                                bgColor='bg-slate-50'
                                py='py-2.5'
                                pl='pl-4'
                                pr='pr-3'
                                zindex='z-[15]'
                                required
                                onChangeOptions={(value) => setInitialCategory(value)} />
                            <Row className='gap-x-4 items-center'>
                                <OutlineInput
                                    action={10}
                                    id='start'
                                    type='date'
                                    label='Tanggal Event Dimulai'
                                    helperText='Pilih tanggal event akan dimulai'
                                    minDate={dateRange.start}
                                    maxDate={new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().slice(0, 10)}
                                    inputWidth='w-full'
                                    className='appearance-none pl-3 pr-2'
                                    bgColor='bg-slate-50'
                                    placeholder='dd/mm/yyyy'
                                    onChange={onChangeDate}
                                    defaultValue={dateRange.start}
                                    required />
                                <div className='flex h-6 translate-y-0.5 aspect-square rounded-full bg-gray-700 my-auto justify-center items-center'>
                                    <TbArrowRight className='h-5 w-5 text-white' />
                                </div>
                                <OutlineInput
                                    action={20}
                                    id='end'
                                    type='date'
                                    label='Tanggal Event Selesai'
                                    helperText='Pilih tanggal event selesai'
                                    minDate={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10)}
                                    maxDate={new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().slice(0, 10)}
                                    className='appearance-none pl-3 pr-2'
                                    inputWidth='w-full'
                                    bgColor='bg-slate-50'
                                    placeholder='dd/mm/yyyy'
                                    onChange={onChangeDate}
                                    defaultValue={dateRange.end}
                                    required />
                            </Row>
                            <ButtonHover className='self-end mt-5'>
                                <PrimaryButton disabled={loading} leadingIcon={<TbUpload className='h-5 w-5 my-auto' />} type='submit' className='pr-6' color='indigo'>
                                    Simpan Event
                                </PrimaryButton>
                            </ButtonHover>
                        </Column>
                    </Form>
                </Column>
            </Card>
            <AlertDialog className='max-w-xs' show={showLargeDialog} onClose={() => setShowLargeDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50'>
                            <TbAlertTriangle className='h-14 w-14 stroke-1.5 text-orange-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Perhatian!
                            </Headline3>
                            <Text className='text-center'>
                                Ukuran file gambar yang dipilih tidak boleh lebih dari 2 MB
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowLargeDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Tutup
                    </TextButton>
                </Column>
            </AlertDialog>
            <WPreviewEvent images={foto} title={title} body={body} ratio={ratio} />
            <LoadingDialog show={loading} message='Menyimpan event...' />
            <ToasterView />
        </>
    )
}

export default AdmAddEventPage