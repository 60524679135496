import React from 'react'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'

function RowSaranTracer({ idx, saran, page = 1, limit = 10, }) {
  return (
    <TableRow striped>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        {saran.nimhsmsmh}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2 capitalize'}>
          {saran.nmmhsmsmh.toLowerCase()}
        </BodyText>
      </TableCell>
      <TableCell>
        {saran.jenjang}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {saran.prodi}
        </BodyText>
      </TableCell>
      <TableCell className={'w-[40%]'}>
        <BodyText className={'line-clamp-2'}>
          {saran.saran}
        </BodyText>
      </TableCell>
    </TableRow>
  )
}

export default RowSaranTracer