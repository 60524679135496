import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowTableProcessedRecognition from './rows/row_processed_recognition'

function TableProcessedRecognition({ listRecognition, page, limit, onShort }) {

  const [currentSort, setCurrentSort] = useState(null)

  const onHandleClick = (recognition) => {
    console.log(recognition)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell>
            Penulis
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='title'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Judul Berita
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='created_at'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Pengajuan
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Status
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Detail
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listRecognition.length > 0 ? listRecognition.map((recognition, idx) => {
          return (
            <RowTableProcessedRecognition
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              recognition={recognition}
              handleClick={onHandleClick} />
          )
        }) : <TableRow>
          <TableCell colSpan={6} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TableProcessedRecognition