import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowSurveiPengguna from './rows/row_survei_pengguna'

function TableSurveiPengguna({ listSurvei, page, limit, onShort }) {

  const [currentSort, setCurrentSort] = useState(null)

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='title'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Nama Pengisi
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='title'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Nama Perusahaan
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='viewer'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Nama Lulusan
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='likes'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Program Studi
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='likes'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Waktu Survei
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listSurvei.length > 0 ? listSurvei.map((survei, idx) => {
          return (
            <RowSurveiPengguna
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              survei={survei} />
          )
        }) : <TableRow>
          <TableCell colSpan={6} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TableSurveiPengguna