import { axios_delete, axios_get, axios_post } from "../services/apiservice"
import { admB2BApiUrl, admLegalitasB2BApiUrl } from "../shared/apiadmin"
import { admCommentsRecognitionApiUrl, admConsultationNewApiUrl, admCounselorConsultationNewApiUrl, admEventApiUrl, admMasterKonselorApiUrl, admMitraApiUrl, admOvGrafikRecognitionApiUrl, admOvGrafikTracerApiUrl, admOvRekapRecognitionApiUrl, admRecognitionApiUrl, admRekapKuesTracerApiUrl, admRekapWisudaTracerApiUrl, admSearchKonselorApiUrl, admSurveiPenggunaApiUrl, admTopicConsultationNewApiUrl, gFilterConsultationApiUrl, grafikDapatKerjaOvTracerApiUrl, grafikHubStudiKerjaOvTracerApiUrl, grafikJenisPerusahaanOvTracerApiUrl, grafikPosisiWiraOvTracerApiUrl, grafikStatusOvTracerApiUrl, grafikSumberBiayaOvTracerApiUrl, grafikTingkatPendOvTracerApiUrl, grafikTingkatWiraOvTracerApiUrl, masterProdiApiUrl, rataDapatKerjaOvTracerApiUrl, rataPendapatanOvTracerApiUrl, sertifAkreditasiApiUrl, tableSaranTracerStudiApiUrl } from "../shared/variable"

//? GET -- OVERVIEW REKOGNISI
export const getOverviewRecognitionController = async () => {
    const result = await axios_get(admRecognitionApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW GRAFIK REKOGNISI
export const getGrafikOverviewRecognitionController = async () => {
    const result = await axios_get(admOvGrafikRecognitionApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW REKAP REKOGNISI
export const getRekapOverviewRecognitionController = async () => {
    const result = await axios_get(admOvRekapRecognitionApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- REKOGNISI (ACCEPTED, PROCESSED AND REJECTED)
export const getRecognitionController = async ({ status = 'ACCEPTED', search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const body = {
        approve: status
    }
    const params = {
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_post(admRecognitionApiUrl, body, true, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- KOMENTAR REKOGNISI (PROCESSED AND REJECTED)
export const getCommentsController = async ({ status = 'PROCESSED', search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admCommentsRecognitionApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- EVENT ALUMNI
export const getEventController = async ({ status = 'ACCEPTED', search = null, page = 1, limit = 10 }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit
    }
    const result = await axios_get(admEventApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET - LOWONGAN BERDASARKAN MITRA
export const getLokerByMitraController = async ({ status = 'ACCEPTED', search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admMitraApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- MITRA UTY (ACCEPTED, PROCESSED AND REJECTED)
export const getMitraController = async ({ status = 'ACCEPTED', search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admMitraApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- FILTER KONSULTASI
export const getFilterConsultationController = async () => {
    const result = await axios_get(gFilterConsultationApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- KONSULTASI (ACCEPTED, PROCESSED AND REJECTED)
export const getConsultationController = async ({ status = null, search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admConsultationNewApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- APPROVE KONSULTASI (ACCEPTED OR REJECTED)
export const approveConsultationController = async ({ id, approve, reason }) => {
    const body = {
        id_konsultasi: id,
        approve,
        alasan_tolak: reason
    }
    const result = await axios_post(admConsultationNewApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DATA DOSEN / MITRA UNTUK PLOTING KONSELOR
export const searchDosenForCounselorController = async ({ search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        s: search ?? '',
        limit
    }
    const result = await axios_get(admSearchKonselorApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- PLOTING KONSELOR KONSULTASI
export const getCounselorConsultationController = async ({ search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admCounselorConsultationNewApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- SAVE PLOTING KONSELOR KONSULTASI
export const saveCounselorConsultationController = async ({ nik = [] }) => {
    const body = {
        nik
    }
    const result = await axios_post(admMasterKonselorApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- PLOTING KONSELOR KONSULTASI BERDASARKAN TOPIK KONSULTASI
export const getTopicConsultationController = async ({ search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admTopicConsultationNewApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- SIMPAN PLOTING TOPIK KONSULTASI UNTUK KONSELOR
export const saveTopicConsultationController = async ({ topic, nik = [] }) => {
    const body = {
        id_topik: topic,
        nik,
    }
    const result = await axios_post(admTopicConsultationNewApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? DELETE -- HAPUS PLOTING TOPIK KONSULTASI UNTUK KONSELOR
export const deleteTopicConsultationController = async ({ topic, nik }) => {
    const body = {
        id_topik: topic,
        nik,
    }
    const result = await axios_delete({
        url: admTopicConsultationNewApiUrl,
        body: body
    })
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW GRAFIK PENGISIAN TRACER STUDI
export const getOvGrafikIsiTracerController = async ({ tahun = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
    }
    const result = await axios_get(admOvGrafikTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW GRAFIK STATUS PEKERJAAN ALUMNI
export const getOvGrafikStatusTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikStatusOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW RATA-RATA PENGHASILAN ALUMNI
export const getOvRataPendapatanTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(rataPendapatanOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW RATA-RATA WAKTU ALUMNI MENDAPATKAN KERJA
export const getOvRataDapatKerjaTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(rataDapatKerjaOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW GRAFIK MENDAPATKAN KERJA KURANG DARI 6 BULAN
export const getOv6BulanDapatKerjaTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikDapatKerjaOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW GRAFIK HUBUNGAN BIDANG STUDI DENGAN PEKERJAAN
export const getOvHubStudiKerjaTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikHubStudiKerjaOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW GRAFIK JENIS PERUSAHAAN ALUMNI BEKERJA
export const getOvJenisPerusahaanTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikJenisPerusahaanOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW POSISI LULUSAN JIKA BERWIRAUSAHA
export const getOvPosisiWiraTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikPosisiWiraOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW TINGKAT WIRAUSAHA LULUSAN
export const getOvTingkatWiraTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikTingkatWiraOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW KESESUAIAN TINGKAT PENDIDIKAN DAN PEKERJAAN
export const getOvTingkatPendTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikTingkatPendOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- OVERVIEW SUMBER BIAYA KULIAH LULUSAN
export const getOvSumberBiayaTracerController = async ({ tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        tahun: tahun,
        prodi: prodi
    }
    const result = await axios_get(grafikSumberBiayaOvTracerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- REKAP KUESIONER TRACER STUDI
export const getKuesionerTracerController = async ({ search = null, page = 1, limit = 10, order = null, asc = null, start = null, end = null }) => {
    const header = {}
    const body = {
        date_start: start,
        date_end: end
    }
    const params = {
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_post(admRekapKuesTracerApiUrl, body, true, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- REKAP WISUDA TRACER STUDI
export const getWisudaTracerController = async ({ year = null, month = null }) => {
    const body = {
        tahun: year,
        bulan: month
    }
    const result = await axios_post(admRekapWisudaTracerApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- REKAP SARAN TRACER STUDI
export const getSaranTracerController = async ({ search = null, page = 1, limit = 10, order = null, asc = null, tahun = null, prodi = null }) => {
    const header = {}
    const params = {
        search,
        page,
        limit,
        order,
        asc,
        tahun,
        prodi,
    }
    const result = await axios_get(tableSaranTracerStudiApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- REKAP SURVEI PENGGUNA LULUSAN
export const getSurveiPenggunaController = async ({ search = null, page = 1, limit = 10, order = null, asc = null, date_start = null, date_end = null, mhs_prodi = null }) => {
    const header = {}
    const params = {
        search,
        page,
        limit,
        order,
        asc,
        date_start,
        date_end,
        mhs_prodi
    }
    const result = await axios_get(admSurveiPenggunaApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- POSTINGAN ALUMNI B2B
export const getB2BController = async ({ status = 'ACCEPTED', search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admB2BApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- APPROVE POSTINGAN ALUMNI B2B
export const approveB2BController = async ({ id, approve }) => {
    const body = {
        approve: approve
    }
    const result = await axios_post(`${admB2BApiUrl}/${id}`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- LEGALITAS USAHA B2B
export const getLegalitasB2BController = async ({ status = 'ACCEPTED', search = null, page = 1, limit = 10, order = null, asc = null }) => {
    const header = {}
    const params = {
        approve: status,
        search,
        page,
        limit,
        order,
        asc,
    }
    const result = await axios_get(admLegalitasB2BApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- APPROVE LEGALITAS USAHA B2B
export const approveLegalitasB2BController = async ({ id, approve }) => {
    const body = {
        approve: approve
    }
    const result = await axios_post(`${admLegalitasB2BApiUrl}/${id}`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- SERTIFIKAT AKREDITASI
export const getCertificateController = async ({ action = 10, search = null, page = 1, limit = 10 }) => {
    const header = {}
    const params = {
        page,
        limit,
        search,
    }
    const result = await axios_get(action === 10 ? sertifAkreditasiApiUrl : `${sertifAkreditasiApiUrl}/univ`, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- SIMPAN SERTIFIKAT AKREDITASI
export const saveCertificateController = async ({ action, prodi, tahun, file }) => {
    const bodyFromData = new FormData()
    bodyFromData.append('kode_prodi', prodi)
    bodyFromData.append('tahun', tahun)
    bodyFromData.append('file', file)
    const result = await axios_post(action === 10 ? sertifAkreditasiApiUrl : `${sertifAkreditasiApiUrl}/univ`, bodyFromData, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? DELETE - HAPUS SERTIFIKAT AKREDITASI
export const deleteCertificateController = async ({ action, prodi, tahun }) => {
    const result = await axios_delete({
        url: action === 10 ? `${sertifAkreditasiApiUrl}/${prodi}/${tahun}` : `${sertifAkreditasiApiUrl}/${tahun}`
    })
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

export const getMasterProdiController = async () => {
    const result = await axios_get(masterProdiApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}