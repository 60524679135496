import React, { useState } from 'react'
import { TbAlertHexagon, TbBell, TbBriefcase, TbBuildingCommunity, TbCalendarEvent, TbCategory, TbCertificate, TbChartDonut2, TbClipboardList, TbFileCertificate, TbHeartHandshake, TbLogout2, TbMessage, TbMessageCheck, TbMessageCircle, TbMessageQuestion, TbMessages, TbNews, TbProgress, TbReport, TbReportAnalytics, TbSchool, TbUserCircle, TbX } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { resetRecoilState } from '../../atoms'
import { logoutAccountController } from '../../controllers/global_controllers'
import logo from '../../images/logo.png'
import { AlertDialog, Avatar, ButtonHover, Caption, Column, HorizontalDivider, LoadingDialog, PrimaryButton, Row, Sidebar, SidebarAccordionMenu, SidebarHeader, SidebarMenu, Subtitle, TertiaryButton, TextButton } from '../../shared/ui'
import { getCurrentUser } from '../../shared/user'
import { admAcceptedConsultationRoute, admAcceptedMitraRoute, admAcceptedRecognitionRoute, admBusinessRoute, admCounselorConsultationRoute, admEventRoute, admKuesionerTracerRoute, admLegalitasBusinessRoute, admLokerCareerRoute, admNotificationRoute, admOverviewCareerRoute, admOverviewPenggunaLulusanRoute, admOverviewRecognitionRoute, admOverviewTracerRoute, admProcessedCommentsRoute, admProcessedConsultationRoute, admProcessedMitraRoute, admProcessedRecognitionRoute, admProdiCertificateRoute, admRejectedCommentsRoute, admRejectedConsultationRoute, admRejectedMitraRoute, admRejectedRecognitionRoute, admRootRoute, admSaranTracerRoute, admSurveiPenggunaLulusanRoute, admTopicConsultationRoute, admUnivCertificateRoute, admWisudaTracerRoute, loginRoute } from '../../shared/variable'
import { BodyText, Headline3Text } from '../interfaces/text'

function AdmSidebar({ showOnMobile }) {

  const location = useLocation()
  const navigateTo = useNavigate()

  const { name, username } = getCurrentUser()

  const [resetRecoil, setResetRecoil] = useRecoilState(resetRecoilState)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const logoutAccount = async () => {
    setShowDialog(false)
    setLoading(true)
    const result = await logoutAccountController()
    if (result) {
      setTimeout(() => {
        localStorage.clear()
        setResetRecoil(true)
        setLoading(false)
        navigateTo(loginRoute, { replace: true })
      }, 300)
    }
  }

  return (
    <>
      <Sidebar
        showOnMobile={showOnMobile}>
        <SidebarMenu className={'p-4 gap-y-3.5 pb-36'}>
          <SidebarHeader className={'px-4'}>
            <Column className={'gap-y-5 py-3 items-center'}>
              <Avatar
                src={logo}
                className={'h-20 w-20'} />
              <Column className={'gap-y-1'}>
                <Subtitle className={'font-semibold text-center line-clamp-1'}>
                  {name ?? 'Admin'}
                </Subtitle>
                <Caption className={'text-center'}>
                  {username ?? 'Administrator'}
                </Caption>
              </Column>
            </Column>
          </SidebarHeader>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbCategory className={'h-6 w-6'} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admRootRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(admRootRoute)}>
            <span className={'line-clamp-1 flex-1 text-left'}>Dashboard</span>
          </TextButton>
          <SidebarAccordionMenu
            label={'Berita Alumni'}
            icon={<TbNews className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/berita')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbChartDonut2 className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admOverviewRecognitionRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admOverviewRecognitionRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Ringkasan</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbNews className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admAcceptedRecognitionRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admAcceptedRecognitionRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Berita Terverifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbProgress className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admProcessedRecognitionRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admProcessedRecognitionRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Menunggu Verifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbAlertHexagon className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admRejectedRecognitionRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admRejectedRecognitionRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Berita Ditolak</span>
            </TextButton>
          </SidebarAccordionMenu>
          <SidebarAccordionMenu
            label={'Komentar Berita'}
            icon={<TbMessageCircle className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/komentar')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbProgress className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admProcessedCommentsRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admProcessedCommentsRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Menunggu Verifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbAlertHexagon className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admRejectedCommentsRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admRejectedCommentsRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Komentar Ditolak</span>
            </TextButton>
          </SidebarAccordionMenu>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbCalendarEvent className={'h-6 w-6'} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admEventRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(admEventRoute)}>
            <span className={'line-clamp-1 flex-1 text-left'}>Event Alumni</span>
          </TextButton>
          <SidebarAccordionMenu
            label={'Pusat Karir'}
            icon={<TbBriefcase className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/karir')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbChartDonut2 className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admOverviewCareerRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admOverviewCareerRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Ringkasan Karir</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbBriefcase className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admLokerCareerRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admLokerCareerRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Lowongan Kerja</span>
            </TextButton>
          </SidebarAccordionMenu>
          <SidebarAccordionMenu
            label={'Mitra UTY'}
            icon={<TbBuildingCommunity className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/mitra')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbBuildingCommunity className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admAcceptedMitraRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admAcceptedMitraRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Mitra Terverifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbProgress className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admProcessedMitraRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admProcessedMitraRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Menunggu Verifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbAlertHexagon className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admRejectedMitraRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admRejectedMitraRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Mitra Ditolak</span>
            </TextButton>
          </SidebarAccordionMenu>
          <SidebarAccordionMenu
            label={'Konsultasi'}
            icon={<TbMessage className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/konsultasi')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbMessageCheck className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admAcceptedConsultationRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admAcceptedConsultationRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Konsultasi Verifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbProgress className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admProcessedConsultationRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admProcessedConsultationRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Menunggu Verifikasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbAlertHexagon className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admRejectedConsultationRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admRejectedConsultationRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Konsultasi Ditolak</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbUserCircle className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admCounselorConsultationRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admCounselorConsultationRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Konselor Konsultasi</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbMessageQuestion className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admTopicConsultationRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admTopicConsultationRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Ploting Konselor</span>
            </TextButton>
          </SidebarAccordionMenu>
          <SidebarAccordionMenu
            label={'Tracer Studi'}
            icon={<TbReport className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/tracer')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbChartDonut2 className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admOverviewTracerRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admOverviewTracerRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Ringkasan</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbClipboardList className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admKuesionerTracerRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admKuesionerTracerRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Data Kuesioner</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbSchool className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admWisudaTracerRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admWisudaTracerRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Data Wisuda</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbMessages className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admSaranTracerRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admSaranTracerRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Data Saran</span>
            </TextButton>
          </SidebarAccordionMenu>
          <SidebarAccordionMenu
            label={'Pengguna Lulusan'}
            icon={<TbReportAnalytics className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/pengguna-lulusan')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbChartDonut2 className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admOverviewPenggunaLulusanRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admOverviewPenggunaLulusanRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Ringkasan</span>
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbClipboardList className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admSurveiPenggunaLulusanRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admSurveiPenggunaLulusanRoute)}>
              <span className={'line-clamp-1 flex-1 text-left'}>Survei Pengguna</span>
            </TextButton>
          </SidebarAccordionMenu>
          {/* <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbReportAnalytics className={'h-6 w-6'} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admPenggunaLulusanRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(admPenggunaLulusanRoute)}>
            Pengguna Lulusan
          </TextButton> */}
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbHeartHandshake className={'h-6 w-6'} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admBusinessRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(admBusinessRoute)}>
            Business to Business
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbFileCertificate className={'h-6 w-6'} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admLegalitasBusinessRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(admLegalitasBusinessRoute)}>
            Legalitas Usaha
          </TextButton>
          <HorizontalDivider />
          <SidebarAccordionMenu
            label={'Sertifikat Akreditasi'}
            icon={<TbCertificate className={'h-6 w-6'} />}
            childrenClassName={'gap-y-3.5 pl-4'}
            active={location.pathname.includes('admin/sertifikat')}>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbCertificate className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admUnivCertificateRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admUnivCertificateRoute)}>
              Institusi UTY
            </TextButton>
            <TextButton
              align='left'
              color='custom'
              leadingIcon={<TbCertificate className={'h-6 w-6'} />}
              className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === admProdiCertificateRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
              onClick={() => navigateTo(admProdiCertificateRoute)}>
              Program Studi
            </TextButton>
          </SidebarAccordionMenu>
          <HorizontalDivider />
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbBell className={'h-6 w-6'} />}
            className={`w-full px-4 py-2.5 rounded-xl transition-all duration-200 ${location.pathname.includes(admNotificationRoute) && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(admNotificationRoute)}>
            Notifikasi
          </TextButton>
        </SidebarMenu>
        <Column className={'absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200'}>
          <ButtonHover className={'w-full'}>
            <PrimaryButton
              pills
              color='red'
              className={'w-full'}
              leadingIcon={
                <TbLogout2 className={'h-5 w-5 my-auto'} />
              }
              onClick={() => setShowDialog(true)}>
              Keluar Akun
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Sidebar>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3Text>
              Keluar Akun
            </Headline3Text>
            <BodyText>
              Apakah anda yakin ingin keluar dari Simfora UTY?
            </BodyText>
          </Column>
          <Row className={'gap-x-3 justify-between'}>
            <ButtonHover>
              <TertiaryButton
                pills
                leadingIcon={
                  <TbX className='h-5 w-5 my-auto' />
                }
                className={'pl-5 pr-6'}
                onClick={() => setShowDialog(false)}>
                Batal
              </TertiaryButton>
            </ButtonHover>
            <ButtonHover>
              <PrimaryButton
                pills
                color='red'
                className='pl-5 pr-6'
                leadingIcon={
                  <TbLogout2 className='h-5 w-5 my-auto' />
                }
                onClick={logoutAccount}>
                Keluar
              </PrimaryButton>
            </ButtonHover>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Keluar dari akun...' />
    </>
  )
}

export default AdmSidebar