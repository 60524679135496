import React, { useEffect, useState } from 'react'
import { getDataProCariKerjaOvTracer } from '../controllers/apicontrollers'
import { Caption, Card, Column, DotSeparated, HorizontalDivider, Row, Subtitle, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '../shared/ui'

const WTableProCariKerjaOvTracer = ({ onDoneLoadData, tahun, prodi }) => {

    const [title, setTitle] = useState('')
    const [listMetBelajar, setLisMetBelajar] = useState([])
    const [listMetBelajarHead, setListMetBelajarHead] = useState([])
    const [listKeterangan, setListKeterangan] = useState([])
    const [searchQuery, setSearchQuery] = useState(null)
    const [sortTable, setShortTable] = useState(null)
    const [limitRow, setLimitRow] = useState(10)
    const [totalRow, setTotalRow] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(null)

    const getDataProCariKerja = async () => {
        const props = {
            tahun: tahun.value,
            prodi: prodi.value,
            limit: limitRow,
            page: currentPage,
            search: searchQuery,
            order: sortTable !== null ? sortTable.key : null,
            asc: sortTable !== null ? sortTable.type : null,
        }
        const result = await getDataProCariKerjaOvTracer(props)
        if (result.status) {
            setTitle(result.title)
            setLisMetBelajar(result.data)
            setListMetBelajarHead(result.table_head)
            setListKeterangan(result.keterangan)
            setTotalRow(result.totalRows)
            setTotalPage(result.totalPage)
        }
        onDoneLoadData(140)
    }

    const onChangeShowNumber = (value) => {
        setCurrentPage(1)
        setLimitRow(value)
    }

    const onChangeQuerySearch = (value) => {
        setCurrentPage(1)
        setSearchQuery(value)
    }

    const onChangePageNumber = (value) => {
        setCurrentPage(value)
    }

    const onShortableValue = (value) => {
        setCurrentPage(1)
        setShortTable(value)
    }

    useEffect(() => {
        getDataProCariKerja()
    }, [searchQuery, sortTable, limitRow, currentPage, tahun, prodi])

    return (
        <Card className='w-full p-0'>
            <Column>
                <Column className='px-4 py-3 gap-1'>
                    <Subtitle className='font-semibold'>
                        {title}
                    </Subtitle>
                    <Row className='flex-wrap gap-y-1'>
                        <Caption className='font-medium'>
                            {prodi.value ? prodi.label : 'Semua Program Studi'}
                        </Caption>
                        <DotSeparated />
                        <Caption className='font-medium'>
                            {tahun.value ? tahun.label : 'Semua Tahun'}
                        </Caption>
                    </Row>
                </Column>
                <HorizontalDivider className='mt-0 mb-0' />
                <Row className='gap-3 px-4 py-2.5 flex-wrap gap-y-2'>
                    <Caption>
                        Keterangan:
                    </Caption>
                    {listKeterangan.map((ket, idx) => {
                        return (
                            <Caption key={idx}>
                                {ket}
                            </Caption>
                        )
                    })}
                </Row>
                <HorizontalDivider className='mt-0 mb-0' />
                <div className='p-4'>
                    <Table
                        className='border border-gray-200'
                        limitRow={limitRow}
                        totalRow={totalRow}
                        totalPage={totalPage}
                        trigerPagination={sortTable}
                        onChangeShowNumber={onChangeShowNumber}
                        onChangeQuerySearch={onChangeQuerySearch}
                        onChangePageNumber={onChangePageNumber}>
                        <TableHead>
                            <TableRow className='bg-slate-100/50 border-b border-b-gray-200'>
                                {listMetBelajarHead.map((head, idx) => {
                                    return (
                                        <TableCell key={idx} className={`font-semibold jakarta`} sortable={head.col !== null ? true : false} sortkey={head.col} onShortable={onShortableValue}>
                                            {head.label}
                                        </TableCell>
                                    )
                                })}
                                <TableCell className='pl-1 pr-1'>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='divide-y divide-gray-200'>
                            {listMetBelajar.length > 0 ? listMetBelajar.map((data, idx) => {
                                return (
                                    <TableRow key={idx} className={`${idx % 2 === 1 ? 'bg-slate-50' : 'bg-white'} hover:bg-slate-100/50 cursor-pointer`}>
                                        {listMetBelajarHead.map((head, idxx) => {
                                            return (
                                                idxx === 0 ? <TableCell key={idxx}>
                                                    {limitRow * (currentPage - 1) + (idx + 1)}
                                                </TableCell> : <TableCell key={idxx} className={`${idxx !== 1 && idxx !== 2 && idxx !== 3 && 'text-center'} ${idxx > 2 && idxx < listMetBelajarHead.length - 1 && 'border-r border-r-gray-200'}`}>
                                                    {idxx === 1 || idxx === 3 ? <Tooltip message={`${data[head.col]}`}>
                                                        <Caption className='line-clamp-1 text-gray-800'>
                                                            {data[head.col]}
                                                        </Caption>
                                                    </Tooltip> : <Caption className='line-clamp-1 text-gray-800'>
                                                        {data[head.col]}
                                                    </Caption>}
                                                </TableCell>
                                            )
                                        })}
                                        <TableCell className='pl-1 pr-1'>
                                        </TableCell>
                                    </TableRow>
                                )
                            }) : <TableRow>
                                <TableCell colSpan={listMetBelajar.length} className='text-center text-gray-500'>
                                    No data to display
                                </TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </div>
            </Column>
        </Card>
    )
}

export default WTableProCariKerjaOvTracer
