import moment from 'moment'
import React from 'react'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'

function RowSurveiPengguna({ idx, survei, page = 1, limit = 10, }) {
  return (
    <TableRow striped>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className='capitalize line-clamp-2'>
          {survei.pengisi_nama}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='capitalize line-clamp-2'>
          {survei.pengisi_perusahaan}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='capitalize line-clamp-2'>
          {survei.mhs_nama}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className='capitalize line-clamp-2'>
          {survei.nama_prodi}
        </BodyText>
      </TableCell>
      <TableCell>
        {moment(survei.created_at).format('DD MMM YYYY HH.mm')}
      </TableCell>

    </TableRow>
  )
}

export default RowSurveiPengguna