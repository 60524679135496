import { useEffect, useState } from "react"
import { Column, Row } from "../../../../components/interfaces/block"
import { Card } from "../../../../components/interfaces/card"
import { CaptionText, Headline1Text, TitleText } from "../../../../components/interfaces/text"
import { getOvRataDapatKerjaTracerController } from "../../../../controllers/admin_controllers"
import { DotSeparated } from "../../../../shared/ui"

function CardRataDapatKerja({ prodi, year, duration }) {

  const [locDuration, setLocDuration] = useState(0)

  const getOvRataDapatKerjaTracer = async () => {
    const props = {
      prodi: prodi.value,
      tahun: year.value,
    }
    const result = await getOvRataDapatKerjaTracerController(props)
    if (result.status) {
      setLocDuration(result.data)
    }
  }

  useEffect(() => {
    if (prodi.value != null || year.value != null) {
      getOvRataDapatKerjaTracer()
    }
  }, [prodi, year])

  return (
    <Card className='w-full h-full p-6'>
      <Column className={'h-full gap-y-6'}>
        <Column className={'gap-y-1'}>
          <TitleText>
            Rata-Rata Lama Lulusan Mendapat Pekerjaan
          </TitleText>
          <Row>
            <CaptionText>
              {prodi.label}
            </CaptionText>
            <DotSeparated />
            <CaptionText>
              {year.value ? `Tahun ${year.label}` : year.label}
            </CaptionText>
          </Row>
        </Column>
        <Headline1Text className={'font-semibold my-auto'}>
          {((prodi.value != null || year.value != null) ? locDuration : duration) ?? '0 Bulan'}
        </Headline1Text>
      </Column>
    </Card>
  )
}

export default CardRataDapatKerja