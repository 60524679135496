import React, { useEffect, useState } from "react";
import { TbCheck } from "react-icons/tb";
import { getDataCaraCariKerjaOvTracer2 } from "../controllers/apicontrollers";
import {
  Caption,
  Card,
  Column,
  DotSeparated,
  HorizontalDivider,
  Row,
  Subtitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "../shared/ui";

const WTableCCKerjaOvTracer = ({ onDoneLoadData, tahun, prodi }) => {
  const [title, setTitle] = useState(null);
  const [label, setLabel] = useState([]);
  const [header, setHeader] = useState([]);
  const [rows, setRows] = useState([]);
  const [sortTable, setShortTable] = useState(null);

  const getDataCCKerjapetensi = async () => {
    const props = {
      tahun: tahun.value,
      prodi: prodi.value,
      order: sortTable !== null ? sortTable.key : null,
      asc: sortTable !== null ? sortTable.type : null,
    };
    const result = await getDataCaraCariKerjaOvTracer2(props);
    if (result.status) {
      setTitle(result.title);
      setLabel(result.label);
      setHeader(result.header);
      setRows(result.rows);
    }
    onDoneLoadData(160);
  };

  const onShortableValue = (value) => {
    setShortTable(value);
  };

  useEffect(() => {
    getDataCCKerjapetensi();
  }, [sortTable, tahun, prodi]);

  return (
    <Card className="w-full p-0">
      <Column>
        <Column className="px-4 py-3 gap-1">
          <Subtitle className="font-semibold">{title}</Subtitle>
          <Row className="flex-wrap gap-y-1">
            <Caption className="font-medium">
              {prodi.value ? prodi.label : "Semua Program Studi"}
            </Caption>
            <DotSeparated />
            <Caption className="font-medium">
              {tahun.value ? tahun.label : "Semua Tahun"}
            </Caption>
          </Row>
        </Column>
        <div className="p-4">
          <Table
            className="border border-gray-200"
            trigerPagination={sortTable}
            showPaginationAndSearch={false}
          >
            <TableHead>
              <TableRow className="bg-slate-100/50 border-b border-b-gray-200">
                {header.map((head, idx) => {
                  return (
                    <TableCell key={idx} className={`font-semibold jakarta`}>
                      {head}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="divide-y divide-gray-200">
              {label.length > 0 ? (
                label.map((lab, index) => (
                  <TableRow
                    key={index}
                    className={`${
                      index % 2 === 1 ? "bg-slate-50" : "bg-white"
                    } hover:bg-slate-100/100 cursor-pointer`}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Tooltip message={`${lab}`}>
                        <Caption className="line-clamp-1 text-gray-800 text-cnter ">
                          {lab}
                        </Caption>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip message={`${rows[index]}${"%"}`}>
                        <Caption className="line-clamp-1 text-gray-800 ">
                          {rows[index]}
                          {"%"}
                        </Caption>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    Belum ada data untuk ditampilkan
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Column>
    </Card>
  );
};

export default WTableCCKerjaOvTracer;
