import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { TbAlertHexagon, TbArrowRight } from 'react-icons/tb'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'
import { getFullNameUser } from '../../../../../controllers/apicontrollers'
import { ButtonHover, IconButton, ShimmerAnimation, ShimmerItem, TagView } from '../../../../../shared/ui'

function RowTableRejecetedRecognition({ idx, recognition, page = 1, limit = 10, handleClick }) {

  const [fullName, setFullName] = useState(null)

  const getFullNameOfUser = async () => {
    const result = await getFullNameUser(recognition.id_identify, recognition.id_user)
    if (result.status) {
      setFullName(result.data)
    }
  }

  useEffect(() => {
    getFullNameOfUser()
  }, [])

  return (
    <TableRow
      striped
      onClick={() => handleClick(recognition)}>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        {
          fullName ? <BodyText className={'line-clamp-2'}>
            {fullName}
          </BodyText> : <ShimmerAnimation>
            <ShimmerItem className={'h-5 w-full'} />
          </ShimmerAnimation>
        }
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {recognition.title}
        </BodyText>
      </TableCell>
      <TableCell>
        {moment(recognition.created_at).format('DD MMM YYYY HH.mm')}
      </TableCell>
      <TableCell>
        {moment(recognition.updated_at).format('DD MMM YYYY HH.mm')}
      </TableCell>
      <TableCell>
        <TagView
          className={'mx-auto'}
          leadingIcon={
            <TbAlertHexagon className={'h-4 w-4'} />
          }
          label='Ditolak'
          color='red' />
      </TableCell>
      <TableCell>
        <ButtonHover className={'mx-auto'}>
          <IconButton
            bordered
            color='indigo'
            className={'p-1.5'}
            onClick={() => handleClick(recognition)}>
            <TbArrowRight className={'h-5 w-5'} />
          </IconButton>
        </ButtonHover>
      </TableCell>
    </TableRow>
  )
}

export default RowTableRejecetedRecognition