import React, { useEffect, useState } from 'react'
import MitraLayout from '../../layouts/mitra_layout'
import { AlertDialog, ButtonHover, Caption, Card, Column, Form, Headline1, Headline3, Image, LoadingDialog, OutlineInput, OutlineTextArea, Overline, PrimaryButton, Row, SelectView, Text, TextButton, TextEditor, TransitionPage } from '../../shared/ui'
import { getFilterCareerController, saveLowonganController } from '../../controllers/apicontrollers'
import { TbAlertTriangle, TbArrowRight, TbPhotoPlus, TbUpload } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { refreshTokenKey } from '../../shared/variable'
import jwtDecode from 'jwt-decode'

const MitAddCareerPage = () => {

    let decodeCookies;

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    if (tokenCookies != null) {
        decodeCookies = jwtDecode(tokenCookies)
    }

    const navigateTo = useNavigate()
    const [masterProvinsi, setMasterProvinsi] = useState([])
    const [flyer, setFlyer] = useState(null)
    const [opsiType, setOpsiType] = useState([])
    const [initialType, setInitialType] = useState({ label: 'Pilih Jenis Pekerjaan', value: null, })
    const [opsiProvinsi, setOpsiProvinsi] = useState([])
    const [initialProvinsi, setInitialProvinsi] = useState({ label: 'Pilih Provinsi', value: null, })
    const [opsiKota, setOpsiKota] = useState([])
    const [initialKota, setInitialKota] = useState({ label: 'Pilih Kabupaten/Kota', value: null, })
    const [opsiProdi, setOpsiProdi] = useState([])
    const [initialProdi, setInitialProdi] = useState({ label: 'Pilih Program Studi', value: null, })
    const [dateRange, setDateRange] = useState({
        start: new Date().toISOString().slice(0, 10),
        end: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().slice(0, 10),
    })
    const [showLargeDialog, setShowLargeDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const getFilterCareer = async () => {
        const result = await getFilterCareerController()
        if (result.status) {
            const type = [...result.jenis_pekerjaan]
            const typeTemp = [];
            type.map((tp) => {
                const opsi = {
                    label: tp.jenis,
                    value: tp.id_jenispekerjaan
                }
                typeTemp.push(opsi)
            })
            setOpsiType(typeTemp)

            const provinsi = [...result.provinsi]
            const provTemp = []
            provinsi.map((prov) => {
                const opsi = {
                    label: prov.prov_nama,
                    value: prov.prov_id
                }
                provTemp.push(opsi)
            })
            provTemp.sort((a, b) => (a.label > b.label) ? 1 : -1)
            setOpsiProvinsi(provTemp)
            setMasterProvinsi(provinsi)

            const prodi = [...result.kode_prodi]
            const prodiTemp = []
            prodi.map((pro) => {
                const opsi = {
                    label: `${pro.jenjang} ${pro.prodi}`,
                    value: pro.kode_prodi
                }
                prodiTemp.push(opsi)
            })
            prodiTemp.sort((a, b) => (a.label > b.label) ? 1 : -1)
            prodiTemp.unshift({
                label: 'Semua Program Studi',
                value: -100
            })
            setOpsiProdi(prodiTemp)
        }
    }

    const onChangeFlyer = (event) => {
        if (event.target.files) {
            const file = event.target.files[0]
            const size = file.size

            if ((size / 1024) <= 2048) {
                setFlyer(file)
            } else {
                setShowLargeDialog(true)
            }
        }
    }

    const onChangeProv = (prov) => {
        const findProv = masterProvinsi.filter((provinsi) => provinsi.prov_id === prov.value) ?? []
        if (findProv.length > 0) {
            const kotaTemp = []
            findProv[0].kota.map((kota) => {
                const opsi = {
                    label: kota.kota_nama,
                    value: kota.kota_id
                }
                kotaTemp.push(opsi)
            })
            kotaTemp.sort((a, b) => (a.label > b.label) ? 1 : -1)
            if (initialKota.value !== null) {
                setInitialKota({ label: 'Pilih Kabupaten/Kota', value: null, })
            }
            setOpsiKota(kotaTemp)
        }
        setInitialProvinsi(prov)
    }

    const onChangeInput = (result) => {
        if (result['action'] === 10) {
            setDateRange({
                start: result['value'],
                end: dateRange.end,
            })
        } else {
            setDateRange({
                start: dateRange.start,
                end: result['value'],
            })
        }
    }

    const onSubmitForm = async (event) => {
        event.preventDefault()
        const position = event.target.position.value
        const email = event.target.email.value
        const jobdesc = event.target.jobdesc.value
        const terms = event.target.terms.value
        const condition = event.target.condition.value
        const salary = event.target.salary.value
        const notes = event.target.notes.value
        const start = event.target.start.value
        const end = event.target.end.value

        if (initialType.value !== null && initialProvinsi.value !== null && initialKota.value !== null && initialProdi.value !== null) {
            setLoading(true)
            let listProdi = []
            if (initialProdi.value === -100) {
                opsiProdi.map((prodi) => {
                    if (prodi.value !== -100) {
                        listProdi.push(prodi.value)
                    }
                })
            } else {
                listProdi.push(initialProdi.value)
            }
            console.log(listProdi)
            const result = await saveLowonganController(flyer, position, initialType.value, email, initialProvinsi.value, initialKota.value, listProdi, jobdesc, terms, condition, salary, notes, start, end)
            if (result.status) {
                setTimeout(() => {
                    setLoading(false)
                    navigateTo(-1)
                }, 200)
            } else {
                setTimeout(() => {
                    setLoading(false)
                }, 200)
            }
        } else {

        }
    }

    useEffect(() => {
        getFilterCareer()
    }, [])

    return (
        <>
            <MitraLayout idpage='career'>
                <TransitionPage>
                    <Column className='gap-y-5'>
                        <Headline1>
                            Buat Lowongan
                        </Headline1>
                        <Card className='w-full p-8'>
                            <Form onSubmit={onSubmitForm}>
                                <Row className='gap-x-8'>
                                    <Column className='gap-1.5 max-w-lg sticky top-28'>
                                        <Caption>
                                            Flyer Lowongan (Opsional)
                                        </Caption>
                                        <Card className='w-full relative aspect-[3/4] p-0 overflow-hidden bg-slate-50'>
                                            <input
                                                id='flyer'
                                                type='file'
                                                className='hidden'
                                                accept='image/*'
                                                onChange={onChangeFlyer}
                                                onClick={(event) => {
                                                    event.currentTarget.value = null
                                                }} />
                                            <label htmlFor='flyer' className='w-full aspect-[3/4] flex items-center cursor-pointer'>
                                                {flyer ? <Image src={URL.createObjectURL(flyer)} imgClasName='w-full aspect-[3/4] object-cover' hoverScale={false} /> : <Column
                                                    className='items-center gap-3'>
                                                    <TbPhotoPlus className='h-10 w-10 text-indigo-400 stroke-1.5' />
                                                    <Caption className='text-center new-line'>
                                                        {'Tambahkan\nFlayer Lowongan'}
                                                    </Caption>
                                                </Column>}
                                            </label>
                                        </Card>
                                        <Overline className='px-4'>
                                            Maksimal ukuran Flyer 2 MB
                                        </Overline>
                                    </Column>
                                    <Column className='gap-y-8'>
                                        <OutlineInput
                                            id='position'
                                            inputWidth='w-full'
                                            label='Posisi Pekerjaan'
                                            placeholder='Masukkan posisi pekerjaan'
                                            helperText='Contoh: Backend Developer'
                                            required
                                            bgColor='bg-slate-50' />
                                        <Row className='gap-x-5'>
                                            <SelectView
                                                width='w-full'
                                                label='Jenis Pekerjaan'
                                                helperText='Pilih salah satu'
                                                initial={initialType}
                                                options={opsiType}
                                                bgColor='bg-slate-50'
                                                py='py-2.5'
                                                pl='pl-4'
                                                pr='pr-3'
                                                zindex='z-[25]'
                                                required
                                                onChangeOptions={(value) => setInitialType(value)} />
                                            <OutlineInput
                                                id='email'
                                                inputWidth='w-full'
                                                label='Kontak Email'
                                                placeholder='Masukkan alamat email'
                                                helperText='Contoh: info@uty.ac.id'
                                                defaultValue={decodeCookies?.email}
                                                required
                                                bgColor='bg-slate-50' />
                                        </Row>
                                        <Row className='gap-x-5'>
                                            <SelectView
                                                width='w-full'
                                                label='Provinsi'
                                                helperText='Pilih salah satu'
                                                initial={initialProvinsi}
                                                options={opsiProvinsi}
                                                bgColor='bg-slate-50'
                                                py='py-2.5'
                                                pl='pl-4'
                                                pr='pr-3'
                                                zindex='z-20'
                                                required
                                                onChangeOptions={(value) => onChangeProv(value)} />
                                            <SelectView
                                                width='w-full'
                                                label='Kabupaten/Kota'
                                                helperText={initialProvinsi.value !== null ? 'Pilih salah satu' : 'Pilih Provinsi terlebih dahulu'}
                                                initial={initialKota}
                                                options={opsiKota}
                                                bgColor='bg-slate-50'
                                                py='py-2.5'
                                                pl='pl-4'
                                                pr='pr-3'
                                                zindex='z-[15]'
                                                required
                                                onChangeOptions={(value) => setInitialKota(value)} />
                                        </Row>
                                        {/* <Column className='gap-y-4'>
                                            {listInitialProdi.map((prodi, idx) => {
                                                return (
                                                    <SelectView
                                                        key={idx}
                                                        width='w-full'
                                                        label='Program Studi'
                                                        helperText='Pilih salah satu'
                                                        initial={prodi}
                                                        options={opsiProdi}
                                                        bgColor='bg-slate-50'
                                                        py='py-2.5'
                                                        pl='pl-4'
                                                        pr='pr-3'
                                                        required
                                                        onChangeOptions={(value) => onSelectProdi(prodi.id, value)} />
                                                )
                                            })}
                                            <ButtonHover>
                                                <TertiaryButton leadingIcon={<TbPlus className='h-5 w-5 my-auto' />} className='pr-5'>
                                                    Tambah Prodi
                                                </TertiaryButton>
                                            </ButtonHover>
                                        </Column> */}
                                        <SelectView
                                            width='w-full'
                                            label='Program Studi'
                                            helperText='Pilih salah satu'
                                            initial={initialProdi}
                                            options={opsiProdi}
                                            bgColor='bg-slate-50'
                                            py='py-2.5'
                                            pl='pl-4'
                                            pr='pr-3'
                                            required
                                            onChangeOptions={(value) => setInitialProdi(value)} />
                                        <TextEditor height={300} id='jobdesc' label='Deskripsi Pekerjaan' showMenuBar={false} required />
                                        <TextEditor height={300} id='terms' label='Persyaratan' showMenuBar={false} required />
                                        <TextEditor height={300} id='condition' label='Ketentuan' showMenuBar={false} required />
                                        <OutlineInput
                                            id='salary'
                                            inputWidth='w-full'
                                            label='Penawaran Gaji (Posisi yang dibuka)'
                                            placeholder='Masukkan penawaran gaji untuk posisi yang dibuka'
                                            helperText='Contoh: 5 - 10 Juta'
                                            required
                                            bgColor='bg-slate-50' />
                                        <OutlineTextArea
                                            id='notes'
                                            className='py-3'
                                            inputWidth='w-full'
                                            bgColor='bg-slate-50'
                                            label='Keterangan (Opsional)'
                                            placeholder='Masukkan keterangan'
                                            initialRow={3}
                                            maxLength={500} />
                                        <Row className='gap-x-4 items-center'>
                                            <OutlineInput
                                                action={10}
                                                id='start'
                                                type='date'
                                                label='Dibuka Mulai'
                                                helperText='Pilih tanggal lowongan akan dibuka'
                                                minDate={dateRange.start}
                                                maxDate={new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().slice(0, 10)}
                                                inputWidth='w-full'
                                                className='appearance-none pl-3 pr-2'
                                                bgColor='bg-slate-50'
                                                placeholder='dd/mm/yyyy'
                                                onChange={onChangeInput}
                                                defaultValue={dateRange.start}
                                                required />
                                            <div className='flex h-6 translate-y-0.5 aspect-square rounded-full bg-gray-700 my-auto justify-center items-center'>
                                                <TbArrowRight className='h-5 w-5 text-white' />
                                            </div>
                                            <OutlineInput
                                                action={20}
                                                id='end'
                                                type='date'
                                                label='Ditutup Pada'
                                                helperText='Pilih tanggal lowongan akan ditutup'
                                                minDate={new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10)}
                                                maxDate={new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().slice(0, 10)}
                                                className='appearance-none pl-3 pr-2'
                                                inputWidth='w-full'
                                                bgColor='bg-slate-50'
                                                placeholder='dd/mm/yyyy'
                                                onChange={onChangeInput}
                                                defaultValue={dateRange.end}
                                                required />
                                        </Row>
                                        <ButtonHover className='mt-8 self-end'>
                                            <PrimaryButton disabled={loading} leadingIcon={<TbUpload className='h-5 w-5 my-auto' />} type='submit' className='pr-6' color='indigo'>
                                                Simpan Lowongan
                                            </PrimaryButton>
                                        </ButtonHover>
                                    </Column>
                                </Row>
                            </Form>
                        </Card>
                    </Column>
                </TransitionPage>
            </MitraLayout>
            <AlertDialog className='max-w-xs' show={showLargeDialog} onClose={() => setShowLargeDialog(false)}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 pt-10 pb-8 gap-8 items-center'>
                        <Card className='p-1.5 rounded-2xl bg-orange-50/50 border-orange-200/50'>
                            <TbAlertTriangle className='h-14 w-14 stroke-1.5 text-orange-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Perhatian!
                            </Headline3>
                            <Text className='text-center'>
                                Ukuran file flayer yang dipilih tidak boleh lebih dari 2 MB
                            </Text>
                        </Column>
                    </Column>
                    <TextButton onClick={() => { setShowLargeDialog(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                        Tutup
                    </TextButton>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loading} message='Menyimpan lowongan...' />
        </>
    )
}

export default MitAddCareerPage