import React, { useEffect, useState } from 'react'
import { ButtonHover, Caption, Card, Column, FullScHeight, Headline1, Headline3, HorizontalDivider, PrimaryButton, Row, ShimmerAnimation, ShimmerItem, Subtitle, Text, Title, TransitionPage } from '../../shared/ui'
import { checkUserFilledTracer, getDataMasterPosisiTracer, getDataMasterProvinsiTracer, getDataMasterSumBiayaTracer, getDataMasterTingKerjaTracer, getDataTracerUser } from '../../controllers/apicontrollers'
import AlumniLayout from '../../layouts/alumni_layout'
import { useRecoilState } from 'recoil'
import { dataTracer, indexInputTracer, inputTracer, inputTracerAction, masterPosisiTracer, masterProvinsiTracer, masterSumBiayaTracer, masterTingKerjaTracer, sertifAkreditasiInstitusiTracer, sertifAkreditasiTracer } from '../../atoms'
import { useNavigate } from 'react-router-dom'
import { almTracerRoute, refreshTokenKey } from '../../shared/variable'
import moment from 'moment'
import { convertDateToString } from '../../shared/lib'
import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode'
import { TbCertificate, TbPencil } from 'react-icons/tb'

function AlmTracerPage() {

    let decodeCookies;

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    if (tokenCookies != null) {
        decodeCookies = jwtDecode(tokenCookies)
    }

    const navigateTo = useNavigate()
    const [tracer, setTracer] = useRecoilState(dataTracer)
    const [stsInputTracer, setStsInputTracer] = useRecoilState(inputTracer)
    const [listSertifikat, setListSertifikat] = useRecoilState(sertifAkreditasiTracer)
    const [listSertifInstitusi, setListSertifInstitusi] = useRecoilState(sertifAkreditasiInstitusiTracer)
    const [masterProvinsi, setMasterProvinsi] = useRecoilState(masterProvinsiTracer)
    const [masterTingKerja, setMasterTingKerja] = useRecoilState(masterTingKerjaTracer)
    const [masterPosisi, setMasterPosisi] = useRecoilState(masterPosisiTracer)
    const [masterSumBiaya, setMasterSumBiaya] = useRecoilState(masterSumBiayaTracer)
    const [inputAction, setInputAction] = useRecoilState(inputTracerAction)
    const [currentIndex, setCurrentIndex] = useRecoilState(indexInputTracer)
    const [showProgress, setShowProgress] = useState(true)
    const [createdAt, setCreatedAt] = useState(null)
    const [updatedAt, setUpdatedAt] = useState(null)

    const checkTracerStudi = async () => {
        const result = await checkUserFilledTracer()
        if (result.status) {
            setStsInputTracer(result.data)
            setListSertifikat(result.sertifikat)
            setListSertifInstitusi(result.institusi)
            await getDataTracerAlumni(result.data)
        }
    }

    const getDataTracerAlumni = async (stsInput) => {
        const result = await getDataTracerUser()
        if (result.status) {
            if (stsInput) {
                const created = convertDateToString({ date: result.data.created, utc: true })
                const updated = convertDateToString({ date: result.data.update, utc: true })
                const createdDate = Date.parse(created)
                const updatedDate = Date.parse(updated)
                setCreatedAt(moment(createdDate).format('DD MMMM YYYY HH.mm'))
                setUpdatedAt(moment(updatedDate).format('DD MMMM YYYY HH.mm'))
                setTracer(result.data)
            } else {
                setTracer(result.template)
            }
        }
    }

    const getMasterProvinsi = async () => {
        const result = await getDataMasterProvinsiTracer()
        if (result.status) {
            setMasterProvinsi(result.data)
        }
    }

    const getMasterTingkatKerja = async () => {
        const result = await getDataMasterTingKerjaTracer()
        if (result.status) {
            setMasterTingKerja(result.data)
        }
    }

    const getMasterPosisi = async () => {
        const result = await getDataMasterPosisiTracer()
        if (result.status) {
            setMasterPosisi(result.data)
        }
    }

    const getMasterSumBiaya = async () => {
        const result = await getDataMasterSumBiayaTracer()
        if (result.status) {
            setMasterSumBiaya(result.data)
        }
    }

    const getData = async () => {
        await checkTracerStudi()
        await getMasterProvinsi()
        await getMasterTingkatKerja()
        await getMasterPosisi()
        await getMasterSumBiaya()
        setShowProgress(false)
    }

    const inputTracerStudy = () => {
        setCurrentIndex(0)
        setInputAction(stsInputTracer ? 20 : 10)
        navigateTo(`${almTracerRoute}/${stsInputTracer ? 'update' : 'input'}`)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <FullScHeight className='flex max-w-7xl pt-20 mx-auto'>
            {stsInputTracer !== null ? <Card className='w-full my-auto p-0 shadow-lg shadow-gray-200'>
                <Row className='divide-x-[1px]'>
                    <Column className='sm:h-[80vh]'>
                        <Column className='gap-y-2 p-8'>
                            <Headline1 className='font-semibold'>
                                Tracer Study
                            </Headline1>
                            <Subtitle className='font-inter font-normal'>
                                Guna mengetahui kontribusi yang diberikan oleh alumni UTY bagi bangsa dan negara serta memetakan daya saing alumni dalam dunia kerja dan industri, diperlukan penelusuran alumni secara komprehensif, terstruktur, dan berkesinambungan melalui Tracer Study. Kegiatan ini juga dimaksudkan untuk mendapatkan umpan balik dari alumni UTY yang sudah berkiprah di masyarakat. Umpan balik dari alumni UTY akan memberikan masukan dan kontribusi positif kepada Universitas Teknologi Yogykarta dalam menyelenggarakan pendidikan yang berkualitas dan menyiapkan SDM unggul.
                            </Subtitle>
                        </Column>
                        <HorizontalDivider removeMargin />
                        <Column className='p-8 gap-y-8'>
                            <Caption className={`${stsInputTracer ? 'text-emerald-600' : 'text-orange-600'} font-medium`}>
                                {stsInputTracer ? 'SUDAH MENGISI TRACER STUDY' : 'BELUM MENGISI TRACER STUDY'}
                            </Caption>
                            <Column className='gap-y-2'>
                                <Headline3>
                                    Halo, {decodeCookies.name}
                                </Headline3>
                                <Text>
                                    {stsInputTracer ? 'Terima kasih sudah berpartisipasi dalam mengisi Tracer Study' : 'Anda belum berpartisipasi dalam mengisi Tracer Study loh! Yuk isi sekarang'}
                                </Text>
                            </Column>
                        </Column>
                        <Column className='px-8 py-8 mt-auto gap-y-5'>
                            <ButtonHover>
                                <PrimaryButton onClick={inputTracerStudy} color='indigo' leadingIcon={<TbPencil className='h-5 w-5 my-auto' />} className='pr-5'>
                                    {stsInputTracer ? 'Perbarui Tracer Study' : 'Isi Tracer Study'}
                                </PrimaryButton>
                            </ButtonHover>
                            <Caption className='text-gray-500'>
                                {stsInputTracer ? `Terakhir diperbarui ${updatedAt}` : 'Belum terdapat Riwayat pengisian Tracer Study'}
                            </Caption>
                        </Column>
                    </Column>
                    <Column className='max-w-xl overflow-y-auto scrollbar-hide scroll-smooth gap-y-8 sm:h-[80vh]'>
                        {stsInputTracer ? <Column className='gap-y-6'>
                            <Column className='px-8 pt-8 gap-y-2'>
                                <TbCertificate className='h-8 w-8 stroke-1.5 text-amber-400' />
                                <Title>
                                    Sertifikat Akreditasi
                                </Title>
                            </Column>
                            <Column className='divide-y-[1px] divide-gray-200'>
                                <Caption className='px-8 pb-3'>
                                    Akreditasi Institusi
                                </Caption>
                                <Column>
                                    {listSertifInstitusi.map((sertifikat, idx) => {
                                        return (
                                            <Column>
                                                <Row key={idx} className='gap-x-4 px-8 py-3'>
                                                    <Column>
                                                        <Text className='font-semibold font-manrope'>
                                                            Universitas Teknologi Yogyakarta
                                                        </Text>
                                                        <Caption>
                                                            Akreditasi Tahun {sertifikat.tahun}
                                                        </Caption>
                                                    </Column>
                                                    <ButtonHover className='my-auto'>
                                                        <a href={sertifikat.name} target='_blank' download className='tertiary-btn py-2 hover:border-indigo-500'>
                                                            Unduh
                                                        </a>
                                                    </ButtonHover>
                                                </Row>
                                                {idx < listSertifInstitusi.length - 1 && <HorizontalDivider className='my-0 ml-8' />}
                                            </Column>
                                        )
                                    })}
                                </Column>
                            </Column>
                            <Column className='divide-y-[1px] divide-gray-200'>
                                <Caption className='px-8 pb-3'>
                                    Akreditasi Program Studi
                                </Caption>
                                <Column className='mb-5'>
                                    {listSertifikat.map((sertifikat, idx) => {
                                        return (
                                            <Column>
                                                <Row key={idx} className='gap-x-4 px-8 py-3'>
                                                    <Column>
                                                        <Text className='font-semibold font-manrope'>
                                                            {decodeCookies.prodi}
                                                        </Text>
                                                        <Caption>
                                                            Akreditasi Tahun {sertifikat.tahun}
                                                        </Caption>
                                                    </Column>
                                                    <ButtonHover className='my-auto'>
                                                        <a href={sertifikat.name} target='_blank' download className='tertiary-btn py-2 hover:border-indigo-500'>
                                                            Unduh
                                                        </a>
                                                    </ButtonHover>
                                                </Row>
                                                {idx < listSertifikat.length - 1 && <HorizontalDivider className='my-0 ml-8' />}
                                            </Column>
                                        )
                                    })}
                                </Column>
                            </Column>
                        </Column> : <Column className='sm:h-[80vh] px-8 py-8 gap-y-2'>
                            <TbCertificate className='h-8 w-8 stroke-1.5 text-amber-400' />
                            <Title>
                                Sertifikat Akreditasi
                            </Title>
                            <Text>
                                Sertifikat Aktreditasi Institusi dan Program Studi dapat diunduh setelah Anda berpartisipasi mengisi Tracer Study.
                            </Text>
                        </Column>}
                    </Column>
                </Row>
            </Card> : <ShimmerAnimation className='w-full max-w-7xl my-auto'>
                <ShimmerItem className='w-full sm:h-[80vh] rounded-xl' />
            </ShimmerAnimation>}
        </FullScHeight>
    )
}

export default AlmTracerPage