import HTMLParse from 'html-react-parser'
import jwtDecode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { HiOutlineBookmark } from 'react-icons/hi2'
import { TbDotsVertical, TbHeart, TbMessageCircle2 } from 'react-icons/tb'
import { getFullNameUser } from '../controllers/apicontrollers'
import avatar1 from '../images/avatar1.png'
import { Avatar, Caption, Card, Column, DotSeparated, FullScHeight, IconButton, Row, ShimmerAnimation, ShimmerItem, Text, Title } from '../shared/ui'
import { tokenApiKey } from '../shared/variable'
import WGridImageRecognition from './wgrid_image_recognition'
import WListImageRecognition from './wlist_image_recognition'

const WPreviewRecognition = ({ images = [], tags = [], title, content, ratio, view }) => {

    const [fullName, setFullName] = useState(null)
    const [showPreviewRec, setShowPreviewRec] = useState(false)

    const getCurrentUser = () => {
        const user = jwtDecode(localStorage.getItem(tokenApiKey))
        getFullNameOfUser(user.kode, user.username)
    }

    const getFullNameOfUser = async (identify, username) => {
        const result = await getFullNameUser(identify, username)
        if (result.status) {
            setFullName(result.data)
        }
    }

    const showPreviewAfterTimeOut = () => {
        if (!showPreviewRec) {
            setTimeout(() => {
                setShowPreviewRec(true)
            }, 100)
        }
    }

    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.class === 'remove') {
                return <></>;
            }
        },
    };

    useEffect(() => {
        getCurrentUser()
        showPreviewAfterTimeOut()
    }, [])

    return (
        <FullScHeight className={`fixed py-14 right-0 top-0 w-[33%] z-10 border-l-[1px] bg-white overflow-y-auto overscroll-y-contain scrollbar-hide scroll-smooth transition-transform ${showPreviewRec ? 'translate-x-0' : 'translate-x-full'} duration-200 ease-in-out`}>
            <Column className='gap-8'>
                <Row className='sticky top-0 bg-white border-b-[1px] z-10 px-8 pt-6 pb-5'>
                    <Title>
                        Pratinjau Berita Alumni
                    </Title>
                </Row>
                <div className='px-8 mb-8'>
                    <Card className='w-full border-2'>
                        <Column className='gap-5'>
                            <Row className='gap-2.5 align-middle'>
                                <Avatar src={avatar1} className='translate-y-0.5' />
                                <Column className='flex-1'>
                                    <Row className='justify-between'>
                                        <Column className='w-fit'>
                                            {fullName !== null ? <Text className='flex-1 font-semibold font-manrope cursor-pointer'>
                                                <span className='hover:text-indigo-700'>{fullName}</span>
                                                {tags.length > 0 && <span className='font-normal inter'> bersama </span>}
                                                {tags.length > 0 && <span className='hover:text-indigo-700'>{tags[0].name}</span>}
                                                {tags.length > 1 && <span className='font-normal inter'> dan </span>}
                                                {tags.length > 1 && <span className='hover:text-indigo-700'>{tags.length - 1} lainnya</span>}
                                            </Text> : <ShimmerAnimation>
                                                <ShimmerItem className='h-4 w-32 mb-1' />
                                            </ShimmerAnimation>}
                                            <Caption>
                                                Baru saja diunggah
                                            </Caption>
                                        </Column>
                                        <IconButton className='translate-x-1 -translate-y-1'>
                                            <TbDotsVertical className='h-5 w-5' />
                                        </IconButton>
                                    </Row>
                                </Column>
                            </Row>
                            <Column className='gap-7'>
                                {images.length > 0 ? view === 'list' ? <WListImageRecognition images={images} aspectRatio={ratio} /> : <WGridImageRecognition images={images} aspectRatio={ratio} /> : <div className={`w-full ${ratio} bg-slate-100 rounded-lg`} />}
                                {title !== '' ? <Title>{title}</Title> : <div className='w-[80%] h-8 bg-slate-100 rounded-full' />}
                                {content !== '' ? <Column className='text-base inter'>{HTMLParse(content, options)}</Column> : <Column className='gap-1.5'>
                                    <div className='w-full h-5 bg-slate-100 rounded-full' />
                                    <div className='w-full h-5 bg-slate-100 rounded-full' />
                                    <div className='w-full h-5 bg-slate-100 rounded-full' />
                                    <div className='w-[55%] h-5 bg-slate-100 rounded-full' />
                                </Column>}
                                <Column className='gap-3'>
                                    <Row>
                                        <Row className='gap-4 my-auto'>
                                            <IconButton className='p-0 hover:bg-transparent'>
                                                <TbHeart className='h-7 w-7 text-gray-600 stroke-1.7' />
                                            </IconButton>
                                            <IconButton className='p-0 hover:bg-transparent'>
                                                <TbMessageCircle2 className='h-7 w-7 text-gray-600 stroke-1.7' />
                                            </IconButton>
                                        </Row>
                                        <IconButton className='p-0 hover:bg-transparent'>
                                            <HiOutlineBookmark className='h-7 w-7 text-gray-600 stroke-1.7' />
                                        </IconButton>
                                    </Row>
                                    <Row>
                                        <Caption>
                                            21 Menyukai
                                        </Caption>
                                        <DotSeparated />
                                        <Caption>
                                            20x Dilihat
                                        </Caption>
                                        <DotSeparated />
                                        <Caption>
                                            4 Komentar
                                        </Caption>
                                    </Row>
                                </Column>
                            </Column>
                        </Column>
                    </Card>
                </div>
            </Column>
        </FullScHeight>
    )
}

export default WPreviewRecognition
