import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowTableCounselorConsultation from './rows/row_counselor_consultation'

function TableCounselorConsultation({ listCounselor, page, limit, onShort }) {

  const [currentSort, setCurrentSort] = useState(null)

  const onHandleClick = (mitra) => {
    console.log(mitra)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell>
            NIK
          </TableHeadCell>
          <TableHeadCell>
            Nama
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Status
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Aksi
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listCounselor.length > 0 ? listCounselor.map((counselor, idx) => {
          return (
            <RowTableCounselorConsultation
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              counselor={counselor}
              handleClick={onHandleClick} />
          )
        }) : <TableRow>
          <TableCell colSpan={7} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TableCounselorConsultation