import React, { Suspense, lazy, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Column, GridCol } from "../../../components/interfaces/block";
import { SelectInput } from "../../../components/interfaces/input";
import { Headline2Text } from "../../../components/interfaces/text";
import SPieOvTracer from "../../../components/shimmer/sadm_pie_ov_tracer";
import WTableBedaKerjaOvTracer from "../../../components/wtable_bedakerja_ov_tracer";
import WTableCCKerjaOvTracer from "../../../components/wtable_cckerja_ov_tracer";
import WTableMetodeBelajarOvTracer from "../../../components/wtable_metodebelajar_ov_tracer";
import WTableProCariKerjaOvTracer from "../../../components/wtable_procarikerja_ov_tracer";
import WTableTingkatKerjaOvTracer from "../../../components/wtable_tingkatkerja_ov_tracer";
import WTableTingkatKomOvTracer from "../../../components/wtable_tingkatkom_ov_tracer";
import {
  getOv6BulanDapatKerjaTracerController,
  getOvGrafikIsiTracerController,
  getOvGrafikStatusTracerController,
  getOvHubStudiKerjaTracerController,
  getOvJenisPerusahaanTracerController,
  getOvPosisiWiraTracerController,
  getOvRataDapatKerjaTracerController,
  getOvRataPendapatanTracerController,
  getOvSumberBiayaTracerController,
  getOvTingkatPendTracerController,
  getOvTingkatWiraTracerController,
} from "../../../controllers/admin_controllers";
import { getFilterTracerController } from "../../../controllers/global_controllers";
import CardRataDapatKerja from "./cards/card_rata_dapat_kerja";
import CardRataPenghasilan from "./cards/card_rata_penghasilan";
import Char6BulanKerja from "./charts/chart_6bulan_kerja";
import ChartHubEratStudiKerja from "./charts/chart_hub_erat_studi_kerja";
import ChartIsiTracer from "./charts/chart_isi_tracer";
import ChatJenisPerusahaan from "./charts/chart_jenis_perusahaan";
import ChartPosisiWirausaha from "./charts/chart_posisi_wirausaha";
import CharStatusLulusan from "./charts/chart_status_lulusan";
import ChartSumberBiaya from "./charts/chart_sumber_biaya";
import ChartTingkatPendKerja from "./charts/chart_tingkat_pend_kerja";
import ChartTingkatWirausaha from "./charts/chart_tingkat_wirausaha";

const CChartCariKerjaOverviewTracer = lazy(() =>
  import("../../../components/cards/cchart_carikerja_ov_tracer")
);
const CChartAktifCariKerjaOverviewTracer = lazy(() =>
  import("../../../components/cards/cchart_aktifcarikerja_ov_tracer")
);

function AdmOverviewTracerPage() {
  const result = useLoaderData();

  const [currentYear, setCurrentYear] = useState({
    label: "Semua Tahun",
    value: null,
  });
  const [currentProdi, setCurrentProdi] = useState({
    label: "Semua Program Studi",
    value: null,
  });

  const [filterTahun, setFilterTahun] = useState([]);
  const [filterProdi, setFilterProdi] = useState([]);

  const [isiTracer, setIsiTracer] = useState([]);

  const [statusTracer, setStatusTracer] = useState([]);
  const [labelStatusTracer, setLabelStatusTracer] = useState([]);
  const [rasioStatusTracer, setRasioStatusTracer] = useState(0);

  const [rataPendapatan, setRataPendapatan] = useState(0);
  const [rataDapatKerja, setRataDapatKerja] = useState(0);

  const [kurang6Bulan, setKurang6Bulan] = useState([]);
  const [labelKurang6Bulan, setLabelKurang6Bulan] = useState([]);

  const [hubEratKerja, setHubEratKerja] = useState([]);
  const [labelHubEratKerja, setLabelHubEratKerja] = useState([]);

  const [jenisPerusahaan, setJenisPerusahaan] = useState([]);
  const [labelJenisPerusahaan, setLabelJenisPerusahaan] = useState([]);

  const [posisiWirausaha, setPosisiWirausaha] = useState([]);
  const [labelPosisiWirausaha, setLabelPosisiWirausaha] = useState([]);

  const [tingkatWirausaha, setTingkatWirausaha] = useState([]);
  const [labelTingkatWirausaha, setLabelTingkatWirausaha] = useState([]);

  const [tingkatPendKerja, setTingkatPendKerja] = useState([]);
  const [labelTingkatPendKerja, setLabelTingkatPendKerja] = useState([]);

  const [sumberBiaya, setSumberBiaya] = useState([]);
  const [labelSumberBiaya, setLabelSumberBiaya] = useState([]);

  const getOverviewFromLoader = () => {
    if (result.filter.status === "fulfilled") {
      const res = result.filter.value;
      if (res.status) {
        const tahun = res.data.tahun;
        const prodi = res.data.prodi;

        const listTahun = [];
        const listProdi = [];

        // const currentYear = new Date().getFullYear();
        // const haveCurrentYear = false;
        // tahun.map((tahun) => {
        //   const t = {
        //     label: tahun,
        //     value: tahun,
        //   };
        //   listTahun.unshift(t);
        //   if (tahun === currentYear) {
        //     haveCurrentYear = true;
        //   }
        // });
        // if (!haveCurrentYear) {
        //   listTahun.unshift({ label: currentYear, value: currentYear });
        // }
        // listTahun.unshift({ label: "Semua Tahun", value: null });

        tahun.map((tahun) => {
          const t = {
            label: tahun,
            value: tahun,
          };
          listTahun.unshift(t);
        });
        listTahun.unshift({ label: "Semua Tahun", value: null });

        prodi.map((prodi) => {
          const p = {
            label: prodi.label,
            value: prodi.kode_prodi,
          };
          listProdi.unshift(p);
        });
        listProdi.unshift({ label: "Semua Program Studi", value: null });

        setFilterTahun(listTahun);
        setFilterProdi(listProdi);
      }
    }
    if (result.ovIsi.status === "fulfilled") {
      const res = result.ovIsi.value;
      if (res.status) {
        setIsiTracer(res.data);
      }
    }
    if (result.ovStatus.status === "fulfilled") {
      const res = result.ovStatus.value;
      if (res.status) {
        var rasio = 0;
        res.data[0].data.map((value) => {
          rasio += value;
        });
        setRasioStatusTracer(rasio);
        setStatusTracer(res.data);
        setLabelStatusTracer(res.labels);
      }
    }
    if (result.ovPendapatan.status === "fulfilled") {
      const res = result.ovPendapatan.value;
      if (res.status) {
        setRataPendapatan(res.data);
      }
    }
    if (result.ovDapatKerja.status === "fulfilled") {
      const res = result.ovDapatKerja.value;
      if (res.status) {
        setRataDapatKerja(res.data);
      }
    }
    if (result.ov6Bulan.status === "fulfilled") {
      const res = result.ov6Bulan.value;
      if (res.status) {
        setKurang6Bulan(res.data);
        setLabelKurang6Bulan(res.labels);
      }
    }
    if (result.ovHubStudiKerja.status === "fulfilled") {
      const res = result.ovHubStudiKerja.value;
      if (res.status) {
        setHubEratKerja(res.data);
        setLabelHubEratKerja(res.labels);
      }
    }
    if (result.ovPerusahaan.status === "fulfilled") {
      const res = result.ovPerusahaan.value;
      if (res.status) {
        setJenisPerusahaan(res.data);
        setLabelJenisPerusahaan(res.labels);
      }
    }
    if (result.ovPosisiWira.status === "fulfilled") {
      const res = result.ovPosisiWira.value;
      if (res.status) {
        setPosisiWirausaha(res.data);
        setLabelPosisiWirausaha(res.labels);
      }
    }
    if (result.ovTingkatWira.status === "fulfilled") {
      const res = result.ovTingkatWira.value;
      if (res.status) {
        setTingkatWirausaha(res.data);
        setLabelTingkatWirausaha(res.labels);
      }
    }
    if (result.ovTingkatPend.status === "fulfilled") {
      const res = result.ovTingkatPend.value;
      if (res.status) {
        setTingkatPendKerja(res.data);
        setLabelTingkatPendKerja(res.labels);
      }
    }
    if (result.sumberBiaya.status === "fulfilled") {
      const res = result.sumberBiaya.value;
      if (res.status) {
        setSumberBiaya(res.data);
        setLabelSumberBiaya(res.labels);
      }
    }
  };

  const onChangeYear = (value) => {
    setCurrentYear(value);
  };

  const onChangeProdi = (value) => {
    setCurrentProdi(value);
  };

  useEffect(() => {
    getOverviewFromLoader();
  }, []);

  return (
    <Column className={"p-4 gap-y-5 sm:p-8"}>
      <Headline2Text className={"font-semibold"}>
        Ringkasan Tracer - Update
      </Headline2Text>
      <ChartIsiTracer series={isiTracer} />
      <Column className="gap-3 sm:flex-row mt-8">
        <div>
          <SelectInput
            width="w-full sm:w-80"
            initial={currentProdi}
            options={filterProdi}
            onChange={onChangeProdi}
            btnClassName={"bg-white"}
            zindex="z-20"
          />
        </div>
        <div>
          <SelectInput
            width="w-full sm:w-40"
            initial={currentYear}
            options={filterTahun}
            onChange={onChangeYear}
            btnClassName={"bg-white"}
            zindex="z-10"
          />
        </div>
      </Column>
      <div>
        <GridCol className={"grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5"}>
          <CharStatusLulusan
            prodi={currentProdi}
            year={currentYear}
            series={statusTracer}
            labels={labelStatusTracer}
            rasio={rasioStatusTracer}
          />
          <Char6BulanKerja
            prodi={currentProdi}
            year={currentYear}
            series={kurang6Bulan}
            labels={labelKurang6Bulan}
          />
          <Column className={"h-full gap-5"}>
            <CardRataPenghasilan
              prodi={currentProdi}
              year={currentYear}
              takehome={rataPendapatan}
            />
            <CardRataDapatKerja
              prodi={currentProdi}
              year={currentYear}
              duration={rataDapatKerja}
            />
          </Column>
          <ChartHubEratStudiKerja
            prodi={currentProdi}
            year={currentYear}
            series={hubEratKerja}
            labels={labelHubEratKerja}
          />
          <ChatJenisPerusahaan
            prodi={currentProdi}
            year={currentYear}
            series={jenisPerusahaan}
            labels={labelJenisPerusahaan}
          />
          <ChartPosisiWirausaha
            prodi={currentProdi}
            year={currentYear}
            series={posisiWirausaha}
            labels={labelPosisiWirausaha}
          />
          <ChartTingkatWirausaha
            prodi={currentProdi}
            year={currentYear}
            series={tingkatWirausaha}
            labels={labelTingkatWirausaha}
          />
          <ChartTingkatPendKerja
            prodi={currentProdi}
            year={currentYear}
            series={tingkatPendKerja}
            labels={labelTingkatPendKerja}
          />
          <ChartSumberBiaya
            prodi={currentProdi}
            year={currentYear}
            series={sumberBiaya}
            labels={labelSumberBiaya}
          />
        </GridCol>
      </div>
      <WTableTingkatKomOvTracer prodi={currentProdi} tahun={currentYear} />
      <WTableTingkatKerjaOvTracer prodi={currentProdi} tahun={currentYear} />
      <WTableMetodeBelajarOvTracer prodi={currentProdi} tahun={currentYear} />
      <div>
        <GridCol className="grid-cols-1 sm:grid-cols-2  gap-5">
          <Suspense fallback={<SPieOvTracer />}>
            <CChartCariKerjaOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
            />
          </Suspense>
          <Suspense fallback={<SPieOvTracer />}>
            <CChartAktifCariKerjaOverviewTracer
              prodi={currentProdi}
              tahun={currentYear}
            />
          </Suspense>
        </GridCol>
      </div>
      <WTableCCKerjaOvTracer prodi={currentProdi} tahun={currentYear} />
      <WTableProCariKerjaOvTracer prodi={currentProdi} tahun={currentYear} />
      <WTableBedaKerjaOvTracer prodi={currentProdi} tahun={currentYear} />
    </Column>
  );
}

export default AdmOverviewTracerPage;

export const getAdmOviewTracer = async () => {
  const [
    filter,
    ovIsi,
    ovStatus,
    ovPendapatan,
    ovDapatKerja,
    ov6Bulan,
    ovHubStudiKerja,
    ovPerusahaan,
    ovPosisiWira,
    ovTingkatWira,
    ovTingkatPend,
    sumberBiaya,
  ] = await Promise.allSettled([
    getFilterTracerController(),
    getOvGrafikIsiTracerController({ tahun: new Date().getFullYear() }),
    getOvGrafikStatusTracerController({ prodi: null, tahun: null }),
    getOvRataPendapatanTracerController({ prodi: null, tahun: null }),
    getOvRataDapatKerjaTracerController({ prodi: null, tahun: null }),
    getOv6BulanDapatKerjaTracerController({ prodi: null, tahun: null }),
    getOvHubStudiKerjaTracerController({ prodi: null, tahun: null }),
    getOvJenisPerusahaanTracerController({ prodi: null, tahun: null }),
    getOvPosisiWiraTracerController({ prodi: null, tahun: null }),
    getOvTingkatWiraTracerController({ prodi: null, tahun: null }),
    getOvTingkatPendTracerController({ prodi: null, tahun: null }),
    getOvSumberBiayaTracerController({ prodi: null, tahun: null }),
  ]);
  return {
    filter: filter,
    ovIsi: ovIsi,
    ovStatus: ovStatus,
    ovPendapatan: ovPendapatan,
    ovDapatKerja: ovDapatKerja,
    ov6Bulan: ov6Bulan,
    ovHubStudiKerja: ovHubStudiKerja,
    ovPerusahaan: ovPerusahaan,
    ovPosisiWira: ovPosisiWira,
    ovTingkatWira: ovTingkatWira,
    ovTingkatPend: ovTingkatPend,
    sumberBiaya: sumberBiaya,
  };
};
