import React, { useState } from 'react'
import { TbBell, TbBookmark, TbBriefcase, TbFile, TbLogout2, TbPin, TbSchool, TbX } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { resetRecoilState } from '../../atoms'
import { logoutAccountController } from '../../controllers/global_controllers'
import { AlertDialog, Avatar, ButtonHover, Column, Headline3, LoadingDialog, PrimaryButton, Row, Sidebar, SidebarHeader, SidebarMenu, Subtitle, TertiaryButton, Text, TextButton } from '../../shared/ui'
import { getCurrentUser } from '../../shared/user'
import { almApplyProfileRoute, almDocProfileRoute, almNotificationProfileRoute, almProfileRoute, almSavedProfileRoute, almTaggedProfileRoute, loginRoute } from '../../shared/variable'

function AlmProfileSidebar() {

  const location = useLocation()
  const navigateTo = useNavigate()

  const { name, jenjang, prodi, alias_prodi } = getCurrentUser()

  const [resetRecoil, setResetRecoil] = useRecoilState(resetRecoilState)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const logoutAccount = async () => {
    setShowDialog(false)
    setLoading(true)
    const result = await logoutAccountController()
    if (result) {
      setTimeout(() => {
        localStorage.clear()
        setResetRecoil(true)
        setLoading(false)
        navigateTo(loginRoute, { replace: true })
      }, 200)
    }
  }

  return (
    <>
      <Sidebar
        showOnMobile={'0'}
        className={'z-20'}>
        <SidebarMenu className={'p-4 gap-y-3.5 pt-24 pb-36'}>
          <SidebarHeader className={'px-6'}>
            <Column className={'gap-y-5 py-3 items-center'}>
              <Avatar className={'h-20 w-20'} />
              <Column className='gap-y-2'>
                <Subtitle className={'font-semibold line-clamp-1 text-center'}>
                  {name}
                </Subtitle>
                <Text className={'line-clamp-1 text-center'}>
                  {jenjang} - {prodi}
                </Text>
              </Column>
            </Column>
          </SidebarHeader>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbSchool className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === almProfileRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almProfileRoute)}>
            Data Lulusan
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbFile className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === almDocProfileRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almDocProfileRoute)}>
            Dokumen Pendukung
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbBriefcase className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === almApplyProfileRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almApplyProfileRoute)}>
            Apply Lowongan
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbPin className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === almTaggedProfileRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almTaggedProfileRoute)}>
            Berita Menandai Saya
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbBookmark className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === almSavedProfileRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almSavedProfileRoute)}>
            Berita Tersimpan
          </TextButton>
          <TextButton
            align='left'
            color='custom'
            leadingIcon={<TbBell className={'h-6 w-6'} />}
            className={`w-full px-6 py-2.5 rounded-xl transition-all duration-200 ${location.pathname === almNotificationProfileRoute && 'text-indigo-600 bg-gray-50'} hover:text-indigo-600 hover:bg-gray-50`}
            onClick={() => navigateTo(almNotificationProfileRoute)}>
            Notifikasi
          </TextButton>
        </SidebarMenu>
        <Column className={'absolute bottom-0 inset-x-0 p-4 bg-white border-t border-gray-200'}>
          <ButtonHover className={'w-full'}>
            <PrimaryButton
              pills
              color='red'
              className={'w-full'}
              leadingIcon={
                <TbLogout2 className={'h-5 w-5 my-auto'} />
              }
              onClick={() => setShowDialog(true)}>
              Keluar Akun
            </PrimaryButton>
          </ButtonHover>
        </Column>
      </Sidebar>
      <AlertDialog
        show={showDialog}
        className={'max-w-md'}
        onClose={() => { setShowDialog(false) }}>
        <Column className={'p-5 gap-y-8 sm:p-8'}>
          <Column className='gap-y-2'>
            <Headline3>
              Keluar Akun
            </Headline3>
            <Text>
              Apakah anda yakin ingin keluar dari Simfora UTY?
            </Text>
          </Column>
          <Row className={'justify-end'}>
            <Row className={'gap-x-3 justify-between'}>
              <ButtonHover>
                <TertiaryButton
                  pills
                  leadingIcon={
                    <TbX className='h-5 w-5 my-auto' />
                  }
                  className={'pl-5 pr-6'}
                  onClick={() => setShowDialog(false)}>
                  Batal
                </TertiaryButton>
              </ButtonHover>
              <ButtonHover>
                <PrimaryButton
                  pills
                  color='red'
                  className='pl-5 pr-6'
                  leadingIcon={
                    <TbLogout2 className='h-5 w-5 my-auto' />
                  }
                  onClick={logoutAccount}>
                  Keluar
                </PrimaryButton>
              </ButtonHover>
            </Row>
          </Row>
        </Column>
      </AlertDialog>
      <LoadingDialog
        show={loading}
        message='Keluar dari akun...' />
    </>
  )
}

export default AlmProfileSidebar