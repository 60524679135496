import React, { useEffect, useState } from "react";
import { getDataTingkatKomKerjaOvTracer } from "../controllers/apicontrollers";
import {
  Caption,
  Card,
  Column,
  DotSeparated,
  HorizontalDivider,
  Row,
  Subtitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "../shared/ui";

const WTableTingkatKerjaOvTracer = ({ onDoneLoadData, tahun, prodi }) => {
  const [title, setTitle] = useState(null);
  const [data, setData] = useState(null);
  const [labelKategori, setLabelkategori] = useState([]);
  const [labelPenilaian, setLabelPenilaian] = useState([]);
  const [sortTable, setShortTable] = useState(null);

  const getDataTingkatKompetensiKerja = async () => {
    const props = {
      tahun: tahun.value,
      prodi: prodi.value,
      order: sortTable !== null ? sortTable.key : null,
      asc: sortTable !== null ? sortTable.type : null,
    };
    const result = await getDataTingkatKomKerjaOvTracer(props);
    if (result.status) {
      setTitle(result.message);
      setData(result.data);
      setLabelkategori(result.label_kategori);
      setLabelPenilaian(result.label_penilaian);
    }
    onDoneLoadData(130);
  };

  const onShortableValue = (value) => {
    setShortTable(value);
  };

  useEffect(() => {
    getDataTingkatKompetensiKerja();
  }, [sortTable, tahun, prodi]);

  return (
    <Card className="w-full p-0">
      <Column>
        <Column className="px-4 py-3 gap-1">
          <Subtitle className="font-semibold">{title}</Subtitle>
          <Row className="flex-wrap gap-y-1">
            <Caption className="font-medium">
              {prodi.value ? prodi.label : "Semua Program Studi"}
            </Caption>
            <DotSeparated />
            <Caption className="font-medium">
              {tahun.value ? tahun.label : "Semua Tahun"}
            </Caption>
          </Row>
        </Column>
        <div className="p-4">
          <Table
            className="border border-gray-200"
            trigerPagination={sortTable}
            showPaginationAndSearch={false}
          >
            <TableHead>
              <TableRow className="bg-slate-100/50 border-b border-b-gray-200">
                <TableCell className="font-semibold jakarta">No</TableCell>
                <TableCell className="font-semibold jakarta">
                  Kompetensi
                </TableCell>
                {labelPenilaian.map((penilaian, index) => (
                  <TableCell
                    key={index}
                    className={`font-semibold font-jakarta`}
                  >
                    {penilaian}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="divide-y divide-gray-200">
              {data &&
                Object.entries(data).map(([key, values], index) => (
                  <TableRow
                    key={index}
                    className={`${
                      index % 2 === 1 ? "bg-slate-50" : "bg-white"
                    } hover:bg-slate-100/100 cursor-pointer`}
                  >
                    <TableCell className={"text-center"}>{index + 1}</TableCell>
                    <TableCell>
                      <Tooltip message={`${labelKategori[index]}`}>
                        <Caption className="line-clamp-1 text-gray-800 ">
                          {labelKategori[index]}
                        </Caption>
                      </Tooltip>
                    </TableCell>
                    {values.map((value, i) => (
                      <TableCell key={i} className={"text-center"}>
                        <Tooltip message={`${value}${"%"}`}>
                          <Caption className=" text-center text-gray-800 ">
                            {value}
                            {"%"}
                          </Caption>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Column>
    </Card>
  );
};

export default WTableTingkatKerjaOvTracer;
