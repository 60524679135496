import { Headline2, TransitionPage } from '../../shared/ui'
import ProdiLayout from '../../layouts/prodi_layout'

const ProDashboardPage = () => {
    return (
        <ProdiLayout idpage='dashboard'>
            <TransitionPage>
                <Headline2>
                    Dashboard
                </Headline2>
            </TransitionPage>
        </ProdiLayout>
    )
}

export default ProDashboardPage
