import { useEffect, useState } from "react"
import { Column, Row } from "../../../../components/interfaces/block"
import { Card } from "../../../../components/interfaces/card"
import { CaptionText, OverlineText, TitleText } from "../../../../components/interfaces/text"
import { getOvJenisPerusahaanTracerController } from "../../../../controllers/admin_controllers"
import { BarChart, DotSeparated } from "../../../../shared/ui"

function ChatJenisPerusahaan({ prodi, year, series, labels }) {

  const colors = [
    '#4CAF50',
    '#2196F3',
    '#3F51B5',
    '#FF9800',
    '#795548',
    '#9C27B0',
    '#F44336',
  ]

  const legendColors = [
    'bg-[#4CAF50]',
    'bg-[#2196F3]',
    'bg-[#3F51B5]',
    'bg-[#FF9800]',
    'bg-[#795548]',
    'bg-[#9C27B0]',
    'bg-[#F44336]',
  ]

  const [locSeries, setLocSeries] = useState([])
  const [locLabels, setLocLabels] = useState([])

  const getOvJenisPerusahaanTracer = async () => {
    const props = {
      prodi: prodi.value,
      tahun: year.value,
    }
    const result = await getOvJenisPerusahaanTracerController(props)
    if (result.status) {
      setLocSeries(result.data)
      setLocLabels(result.labels)
    }
  }

  useEffect(() => {
    if (prodi.value != null || year.value != null) {
      getOvJenisPerusahaanTracer()
    }
  }, [prodi, year])

  return (
    <Card className={'w-full h-full p-0'}>
      <Column>
        <Column className={'p-6 gap-y-1'}>
          <TitleText>
            Jenis Perusahaan Tempat Bekerja
          </TitleText>
          <Row>
            <CaptionText>
              {prodi.label}
            </CaptionText>
            <DotSeparated />
            <CaptionText>
              {year.value ? `Tahun ${year.label}` : year.label}
            </CaptionText>
          </Row>
        </Column>
        <div className={'pr-2 pl-1 pt-1'}>
          <BarChart
            series={(prodi.value != null || year.value != null) ? locSeries : series}
            height={300}
            listColors={colors} />
        </div>
        <ChartLegend
          labels={(prodi.value != null || year.value != null) ? locLabels : labels}
          colors={legendColors} />
      </Column>
    </Card>
  )
}

export default ChatJenisPerusahaan

export const ChartLegend = ({ labels, colors }) => {
  return (
    <Row className={'px-6 pb-6 gap-x-3 gap-y-2 flex-wrap justify-center'}>
      {labels.map((label, idx) => {
        return (
          <Row key={idx} className={'w-fit gap-1.5'}>
            <Card className={`${colors[idx]} border-none p-1.5 rounded-full my-auto`} />
            <OverlineText className={'my-auto text-gray-800 font-normal'}>
              {label}
            </OverlineText>
          </Row>
        )
      })}
    </Row>
  )
}