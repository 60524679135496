import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowTableProcessedMitra from './rows/row_processed_mitra'

function TableProcessedMitra({ listMitra, page, limit, onShort }) {

  const [currentSort, setCurrentSort] = useState(null)

  const onHandleClick = (mitra) => {
    console.log(mitra)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell>
            Logo
          </TableHeadCell>
          <TableHeadCell>
            Nama
          </TableHeadCell>
          <TableHeadCell>
            Deskripsi
          </TableHeadCell>
          <TableHeadCell>
            Legalitas
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Status
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Detail
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listMitra.length > 0 ? listMitra.map((mitra, idx) => {
          return (
            <RowTableProcessedMitra
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              mitra={mitra}
              handleClick={onHandleClick} />
          )
        }) : <TableRow>
          <TableCell colSpan={7} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TableProcessedMitra