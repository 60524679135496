import { useState } from 'react'
import { Column, Headline2, TabPanel, Tabs, TransitionPage } from '../../shared/ui'
import ProdiLayout from '../../layouts/prodi_layout'
import { useRecoilState } from 'recoil'
import { currentIndexTabTracer } from '../../atoms'
import { TbChartDonut2, TbClipboardList, TbSchool } from 'react-icons/tb'
import TableRekapKuesionerTracer from '../../components/tabels/rekap_kuesioner_tracer'
import TableRekapWisudaTracer from '../../components/tabels/rekap_wisuda_tracer'
import OvTracer from '../../components/overview/ov_tracer'

const ProTracerPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabTracer)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <ProdiLayout idpage='tracer' showProgress={showProgress}>
            <TransitionPage>
                <Column className='gap-5'>
                    <Headline2>
                        Tracer Studi
                    </Headline2>
                    <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                        <TabPanel icon={<TbChartDonut2 className='h-5 w-5' />} title='Overview'>
                            <OvTracer onDoneLoadData={onDoneLoadData} action={20} />
                        </TabPanel>
                        <TabPanel icon={<TbClipboardList className='h-5 w-5' />} title='Kuesioner'>
                            <TableRekapKuesionerTracer onDoneLoadData={onDoneLoadData} action={20} />
                        </TabPanel>
                        <TabPanel icon={<TbSchool className='h-5 w-5' />} title='Wisuda'>
                            <TableRekapWisudaTracer onDoneLoadData={onDoneLoadData} action={20} />
                        </TabPanel>
                    </Tabs>
                </Column>
            </TransitionPage>
        </ProdiLayout>
    )
}

export default ProTracerPage
