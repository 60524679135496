import React, { useEffect, useState } from "react";
import { TbCheck } from "react-icons/tb";
import { getDataBedaPekerjaanOvTracer } from "../controllers/apicontrollers";
import {
  Caption,
  Card,
  Column,
  DotSeparated,
  HorizontalDivider,
  Row,
  Subtitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "../shared/ui";

const WTableBedaKerjaOvTracer = ({ onDoneLoadData, tahun, prodi }) => {
  const [title, setTitle] = useState(null);
  const [header, setHeader] = useState([]);
  const [rows, setRows] = useState([]);
  const [label, setLabel] = useState([]);
  const [sortTable, setShortTable] = useState(null);

  const getDataBedaKerjapetensi = async () => {
    const props = {
      tahun: tahun.value,
      prodi: prodi.value,
      order: sortTable !== null ? sortTable.key : null,
      asc: sortTable !== null ? sortTable.type : null,
    };
    const result = await getDataBedaPekerjaanOvTracer(props);
    if (result.status) {
      setTitle(result.title);
      setHeader(result.header);
      setRows(result.rows);
      setLabel(result.label);
    }
    onDoneLoadData(180);
  };

  const onShortableValue = (value) => {
    setShortTable(value);
  };

  useEffect(() => {
    getDataBedaKerjapetensi();
  }, [sortTable, tahun, prodi]);

  return (
    <Card className="w-full p-0">
      <Column>
        <Column className="px-4 py-3 gap-1">
          <Subtitle className="font-semibold">{title}</Subtitle>
          <Row className="flex-wrap gap-y-1">
            <Caption className="font-medium">
              {prodi.value ? prodi.label : "Semua Program Studi"}
            </Caption>
            <DotSeparated />
            <Caption className="font-medium">
              {tahun.value ? tahun.label : "Semua Tahun"}
            </Caption>
          </Row>
        </Column>
        <div className="p-4">
          <Table
            className="border border-gray-200"
            trigerPagination={sortTable}
            showPaginationAndSearch={false}
          >
            <TableHead>
              <TableRow className="bg-slate-100/50 border-b border-b-gray-200">
                {header.map((head, idx) => {
                  return (
                    <TableCell key={idx} className={`font-semibold jakarta`}>
                      {head}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="divide-y divide-gray-200">
              {label.length > 0 ? (
                label.map((lab, idx) => (
                  <TableRow
                    key={idx}
                    className={`${
                      idx % 2 === 1 ? "bg-slate-50" : "bg-white"
                    } hover:bg-slate-100/100 cursor-pointer`}
                  >
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>
                      <Tooltip message={lab}>
                        <Caption>{lab}</Caption>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip message={`${rows[idx]}${"%"}`}>
                        <Caption>
                          {rows[idx]}
                          {"%"}
                        </Caption>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>Belum ada data untuk ditampilkan</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Column>
    </Card>
  );
};

export default WTableBedaKerjaOvTracer;
