import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../components/interfaces/table";
import RowTableAcceptedMitra from "./rows/row_accepted_mitra";
import {
  AlertDialog,
  ButtonHover,
  Caption,
  Column,
  Headline3,
  HorizontalDivider,
  Image,
  Row,
  SecondaryButton,
  Text,
} from "../../../../shared/ui";
import {
  TbArrowUpRight,
  TbBrandWhatsapp,
  TbCertificate,
  TbMail,
  TbMapPin,
  TbPhone,
  TbUserCircle,
  TbX,
} from "react-icons/tb";

function TableAcceptedMitra({ listMitra, page, limit, onShort }) {
  const [currentSort, setCurrentSort] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [currentMitra, setCurrentMitra] = useState(null);

  const onHandleClick = (mitra) => {
    setShowDialog(true);
    setCurrentMitra(mitra);
    console.log("daftar mitra", mitra);
  };

  // const onHandleSort = (value) => {
  //   setCurrentSort(value);
  //   onShort(value);
  // };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>No</TableHeadCell>
            <TableHeadCell>Logo</TableHeadCell>
            <TableHeadCell>Nama</TableHeadCell>
            <TableHeadCell>Deskripsi</TableHeadCell>
            <TableHeadCell>PIC</TableHeadCell>
            <TableHeadCell className={"text-center"}>Status</TableHeadCell>
            <TableHeadCell className={"text-center"}>Detail</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listMitra.length > 0 ? (
            listMitra.map((mitra, idx) => {
              return (
                <RowTableAcceptedMitra
                  key={idx}
                  idx={idx}
                  page={page}
                  limit={limit}
                  mitra={mitra}
                  handleClick={onHandleClick}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={7} className="text-center text-gray-500">
                No data to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <AlertDialog
        className="max-w-3xl"
        show={showDialog}
        onClose={() => setShowDialog(false)}
      >
        {currentMitra !== null && (
          <Column className="w-full">
            <Image
              src={currentMitra.foto}
              className="aspect-[16/5]"
              imgClasName="aspect-video object-cover"
              hoverScale={false}
            />
            <Column className="px-8 py-8 relative gap-y-5">
              <Image
                src={currentMitra.logo}
                className="flex flex-row absolute -top-[4rem] w-full bg-white aspect-square p-2 max-w-[8rem] rounded-full border-2 border-gray-200 items-center ring-4 ring-white"
                imgClasName="w-full max-w-[8rem] object-fill"
                hoverScale={false}
              />
              <Column className="gap-y-3 mt-[3rem]">
                <Headline3>{currentMitra.nama_mitra}</Headline3>
                <Text>{currentMitra.deskripsi}</Text>
              </Column>
              <HorizontalDivider />
              <Row className="gap-x-2">
                <div className="h-5 w-5 flex-1">
                  <TbCertificate className="h-5 w-5 my-auto text-gray-500" />
                </div>
                <Column className="gap-y-1">
                  <Caption>Legalitas Perusahaan</Caption>
                  <Text>{currentMitra.legalitas.split("/").pop()}</Text>
                </Column>
                <ButtonHover>
                  <a
                    className="flex flex-row items-center gap-x-2 tertiary-btn pr-5 hover:border-blue-500"
                    href={currentMitra.legalitas}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TbArrowUpRight className="h-5 w-5" /> Legalitas
                  </a>
                </ButtonHover>
              </Row>
              <HorizontalDivider />
              <Column className="gap-y-8">
                <Row className="gap-x-5">
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbUserCircle className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Penanggung Jawab (PIC)</Caption>
                      <Text>{currentMitra.pic_name}</Text>
                    </Column>
                  </Row>
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbMail className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Email</Caption>
                      <Text>{currentMitra.email}</Text>
                    </Column>
                  </Row>
                </Row>
                <Row className="gap-x-5">
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbPhone className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Nomor Telepon</Caption>
                      <Text>{currentMitra.no_telp}</Text>
                    </Column>
                  </Row>
                  <Row className="gap-x-2">
                    <div className="h-5 w-5 flex-1">
                      <TbBrandWhatsapp className="h-5 w-5 text-gray-500" />
                    </div>
                    <Column className="gap-y-1">
                      <Caption>Nomor WhatsApp</Caption>
                      <Text>{currentMitra.no_wa}</Text>
                    </Column>
                  </Row>
                </Row>
                <Row className="gap-x-2">
                  <div className="h-5 w-5 flex-1">
                    <TbMapPin className="h-5 w-5 text-gray-500" />
                  </div>
                  <Column className="gap-y-1">
                    <Caption>Alamat</Caption>
                    <Text>{currentMitra.alamat}</Text>
                  </Column>
                </Row>
              </Column>
            </Column>
            <HorizontalDivider />
            <Row className="gap-x-5 justify-end px-8 pt-4 pb-8">
              <ButtonHover>
                <SecondaryButton
                  leadingIcon={<TbX className="h-5 w-5 my-auto" />}
                  className="pr-5"
                  onClick={() => setShowDialog(false)}
                >
                  Tutup
                </SecondaryButton>
              </ButtonHover>
            </Row>
          </Column>
        )}
      </AlertDialog>
    </>
  );
}

export default TableAcceptedMitra;
