import { useEffect, useState } from 'react'
import { ButtonHover, Card, Column, Form, Headline2, LoadingDialog, OutlineInput, PrimaryButton, Row, TextEditor, TransitionPage } from '../../shared/ui'
import WPreviewRecognition from '../../components/wpreview_recognition'
import { saveNewRecognition } from '../../controllers/apicontrollers'
import { useNavigate } from 'react-router-dom'
import WInputImageRecognition from '../../components/winput_image_recognition'
import WInputTagsRecognition from '../../components/winput_tags_recognition'
import { TbUpload } from 'react-icons/tb'
import ProdiLayout from '../../layouts/prodi_layout'

const ProAddRecognitionPage = () => {

    const navigateTo = useNavigate()
    const [listImageRec, setListImageRec] = useState([])
    const [listTagUser, setListTagUser] = useState([])
    const [titleRec, setTitleRec] = useState('')
    const [contentRec, setContentRec] = useState('')
    const [aspectRatio, setAspectRatio] = useState('aspect-video')
    const [imageView, setImageView] = useState('list')
    const [loading, setLoading] = useState(false)
    const [showSaveBtn, setShowSaveBtn] = useState(false)

    const onSubmitForm = async (event) => {
        event.preventDefault()
        const title = event.target.title.value
        const content = event.target.content.value

        const listImg = []
        if (listImageRec.length > 0) {
            listImageRec.map((img) => {
                listImg.push(img.id)
            })
        }

        const listTags = []
        if (listTagUser.length > 0) {
            listTagUser.map((user) => {
                listTags.push(user.id_user)
            })
        }

        setLoading(true)

        const result = await saveNewRecognition(title, listImg, listTags, content, aspectRatio, imageView)
        if (result.status) {
            setTimeout(() => {
                setLoading(false)
                navigateTo(-1)
            }, 200)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 200)
        }
    }

    const onChangeTitleRec = (event) => {
        event.preventDefault();
        setTitleRec(event.target.value)
    }

    const onChangeContentRec = (value) => {
        setContentRec(value)
    }

    const onChangeImgRec = (img) => {
        setListImageRec(img)
    }

    const onChangeImgRatio = (ratio) => {
        setAspectRatio(ratio)
    }

    const onChangeImgView = (view) => {
        setImageView(view)
    }

    const onChangeTagsUser = (tags) => {
        setListTagUser(tags)
    }

    const showSaveBtnAfterTimeOut = () => {
        if (!showSaveBtn) {
            setTimeout(() => {
                setShowSaveBtn(true)
            }, 100)
        }
    }

    useEffect(() => {
        showSaveBtnAfterTimeOut()
    }, [])

    useEffect(() => { }, [listImageRec, listTagUser])

    return (
        <ProdiLayout idpage='recognition' className='xl:pr-[35%]'>
            <TransitionPage>
                <Column className='gap-8'>
                    <Headline2>
                        Buat Rekognisi
                    </Headline2>
                    <Card className='w-full'>
                        <Form onSubmit={onSubmitForm}>
                            <Column className='gap-8'>
                                <WInputImageRecognition onChangeImg={onChangeImgRec} onChangeImgRatio={onChangeImgRatio} onChangeImgView={onChangeImgView} />
                                <WInputTagsRecognition onChangeTags={onChangeTagsUser} />
                                <OutlineInput id='title' inputWidth='w-full' label='Judul Berita' placeholder='Masukkan judul berita' required={true} onChange={onChangeTitleRec} bgColor='bg-slate-50' />
                                <TextEditor id='content' label='Isi Berita' required={true} onChange={onChangeContentRec} />
                                <ButtonHover className='self-end'>
                                    <PrimaryButton disabled={loading} leadingIcon={<TbUpload className='h-5 w-5 my-auto' />} type='submit' className='pr-6' color='indigo'>
                                        Simpan Berita
                                    </PrimaryButton>
                                </ButtonHover>
                            </Column>
                        </Form>
                    </Card>
                </Column>
            </TransitionPage>
            <WPreviewRecognition images={listImageRec} tags={listTagUser} title={titleRec} content={contentRec} ratio={aspectRatio} view={imageView} />
            <LoadingDialog show={loading} message='Menyimpan rekognisi...' />
        </ProdiLayout>
    )
}

export default ProAddRecognitionPage
