import { Listbox, Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import TextareaAutosize from "react-autosize-textarea"
import { HiChevronUpDown } from "react-icons/hi2"
import { TbSearch } from "react-icons/tb"
import { Column, Row } from "./block"
import { BodyText, HelperText, LabelText } from "./text"

export const FormInput = ({ id, refId, name, children, className, action, method, onSubmit }) => {
    return (
        <form
            id={id}
            ref={refId}
            name={name}
            action={action}
            onSubmit={onSubmit}
            method={method}
            className={className}>
            {children}
        </form>
    )
}

export const OutlineInput = ({ id, refId, name, label = 'Label Input', errorMessage, labelClassName, className, defaultValue, type = 'text', placeholder = 'Placeholder', maxLength = 100, minDate, maxDate, helperText, prfix, suffix, required = false, error = false, disabled = false, autoFocus = false, hideLabel = false, onChange, onKeyDown, onFocus, onBlur }) => {
    return (
        <Column className={'gap-y-1'}>
            {!hideLabel &&
                <LabelText
                    htmlForId={id}
                    className={`px-2 text-gray-800 ${labelClassName}`}>
                    {label} {required && <span className='text-red-500'>*</span>}
                </LabelText>
            }
            <div className={'relative'}>
                {prfix}
                <input
                    id={id}
                    ref={refId}
                    name={name}
                    type={type}
                    spellCheck={false}
                    className={`${error ? 'input-error-new' : 'input-new'} ${className}`}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    defaultValue={defaultValue}
                    min={type === 'date' && minDate}
                    max={type === 'date' && maxDate}
                    maxLength={maxLength}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    autoComplete='off'
                    autoCapitalize='off' />
                <div className={'absolute inset-y-0 right-2 flex items-center'}>
                    {suffix}
                </div>
            </div>
            {((helperText !== null) || (errorMessage !== null)) && <HelperText className={`mt-1 px-4 ${error && 'font-medium text-red-500'}`}>
                {errorMessage ?? helperText}
            </HelperText>}
        </Column>
    )
}

export const OutlineTextArea = ({ id, refId, name, label = 'Textarea Input', errorMessage, labelClassName, className, defaultValue, type = 'text', placeholder = 'Placeholder', maxLength = 100, helperText, initialRow = 1, maxRows = 10, required = false, error = false, disabled = false, autoFocus = false, onChange, onKeyDown, onFocus, onBlur }) => {
    return (
        <Column className={'gap-y-1'}>
            <LabelText
                htmlForId={id}
                className={`px-2 text-gray-800 ${labelClassName}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </LabelText>
            <TextareaAutosize
                id={id}
                ref={refId}
                name={name}
                type={type}
                spellCheck={false}
                className={`${error ? 'input-error-new' : 'input-new'} ${className}`}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                autoFocus={autoFocus}
                rows={initialRow}
                maxRows={maxRows}
                defaultValue={defaultValue}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur} />
            {((helperText !== null) || (errorMessage !== null)) && <HelperText className={`mt-1 px-4 ${error && 'font-medium text-red-500'}`}>
                {errorMessage ?? helperText}
            </HelperText>}
        </Column>
    )
}

export const SearchInput = ({ id, refId, name, className, defaultValue, type = 'text', placeholder = 'Cari Data', maxLength = 100, disabled = false, autoFocus = false, onChange, onKeyDown, onFocus, onBlur }) => {
    return (
        <div className={'relative group'}>
            <div className={'absolute inset-y-0 left-3 flex items-center'}>
                <TbSearch className='h-5 w-5 text-gray-500' />
            </div>
            <input
                id={id}
                ref={refId}
                name={name}
                type={type}
                spellCheck={false}
                className={`input-new py-2 pl-10 group-hover:border-indigo-500 group-hover:ring-2 group-hover:ring-indigo-50 ${className}`}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete='off'
                autoCapitalize='off' />
        </div>
    )
}

export const SelectInput = ({ name, label = null, initial, options = [], width = 'w-fit', maxHeight, labelClassName, btnClassName, optClassName, onChange, zindex = 'z-10', required = false }) => {

    const [optionSelected, setOptionSelected] = useState(initial ?? {
        label: 'Pilih salah satu',
        value: -100,
    })

    const onChangeOption = (value) => {
        setOptionSelected(value)
        onChange(value)
    }

    useEffect(() => {
        setOptionSelected(initial)
    }, [initial])

    return (
        <Column className={'gap-y-1'}>
            {label !== null && <LabelText
                htmlForId={name}
                className={`px-2 text-gray-800 ${labelClassName}`}>
                {label} {required && <span className='text-red-500'>*</span>}
            </LabelText>}
            <Listbox
                name={name}
                value={optionSelected}
                onChange={onChangeOption}>
                <div className={`relative ${width} ${zindex}`}>
                    <Listbox.Button className={`listbox-button-new ${btnClassName} ${width}`}>
                        <Row className={'gap-x-1.5'}>
                            <BodyText className={'w-fit flex-1'}>
                                {optionSelected.label}
                            </BodyText>
                            <HiChevronUpDown className={'h-5 w-5 text-gray-500 my-auto'} />
                        </Row>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        enter={'transition ease-in-out duration-300'}
                        enterFrom={'-translate-y-8 opacity-0'}
                        enterTo={'translate-y-0 opacity-100'}
                        leave={'transition ease-out duration-200'}
                        leaveFrom={'translate-y-0 opacity-100'}
                        leaveTo={'translate-y-3 opacity-0'}>
                        <Listbox.Options className={`listbox-options-new mt-1 ${maxHeight}`}>
                            {options.map((option, idx) => (
                                <Listbox.Option
                                    key={idx}
                                    className={`relative cursor-pointer select-none px-4 py-2 ${option.value === optionSelected.value ? 'bg-indigo-50/50' : 'hover:bg-slate-50'} ${optClassName}`}
                                    value={option}>
                                    <BodyText className={`w-fit flex-1 ${option.value === optionSelected.value && 'font-medium text-indigo-700'}`}>
                                        {option.label}
                                    </BodyText>
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </Column>
    )
}