import React, { useEffect, useState } from 'react'
import { TbBellRinging, TbBriefcase, TbCategory, TbChevronLeft, TbChevronRight, TbLogout, TbMenu2, TbMoodX } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentUserData, readNotifications, resetRecoilState, unreadNotifications } from '../atoms'
import WListNotification from '../components/wlist_notification'
import { getAllNotifications, updateReadNotifications } from '../controllers/apicontrollers'
import { logoutAccountController } from '../controllers/global_controllers'
import avatar1 from '../images/logo.png'
import { AlertDialog, Appbar, Avatar, Caption, Card, Column, FullScreen, GridCol, Headline2, Headline3, HorizontalDivider, IconButton, LoadingDialog, Row, SideNotifPanel, Sidebar, SidebarHeader, SidebarItem, SidebarMenu, Subtitle, Text, TextButton } from '../shared/ui'
import { loginRoute, mitCareerRoute, mitRootRoute } from '../shared/variable'

const MitraLayout = ({ children, idpage, idsubmenu, className, showProgress = false }) => {

    const location = useLocation()
    const navigateTo = useNavigate()
    const user = useRecoilValue(currentUserData)
    const [showSideMobile, setShowSideMobile] = useState(false)
    const [showNotifPanel, setShowNotifPanel] = useState(false)
    const [showDialogLogout, setShowDialogLogout] = useState(false)
    const [loadingLogout, setLoadingLogout] = useState(false)
    const [resetRecoil, setResetRecoil] = useRecoilState(resetRecoilState)
    const [listReadNotification, setListReadNotification] = useRecoilState(readNotifications)
    const [listUnreadNotification, setListUnreadNotification] = useRecoilState(unreadNotifications)

    const getNotifications = async () => {
        const result = await getAllNotifications()
        if (result.status) {
            const listNotification = [...result.data]
            const unread = listNotification.filter((notif) => notif.is_read === false)
            const read = listNotification.filter((notif) => notif.is_read === true)
            setListUnreadNotification(unread)
            setListReadNotification(read)
        }
    }

    const onReadNotification = async (action, notif) => {
        const result = await updateReadNotifications(notif.id_notifikasi)
        if (result.status) {
            if (action === 10) {
                const readTemp = [...listReadNotification, notif]
                const newUnread = listUnreadNotification.filter((notiff) => notiff.id_notifikasi !== notif.id_notifikasi)
                const sortRead = readTemp.sort((a, b) => b.id_notifikasi - a.id_notifikasi)
                setListUnreadNotification(newUnread)
                setListReadNotification(sortRead)
            }
        }
    }

    const onClickMenu = (destination) => {
        setShowSideMobile(false)
        setTimeout(() => [
            navigateTo(destination)
        ], showSideMobile ? 300 : 0)
    }

    const changeShowSideMobile = (value) => {
        setShowSideMobile(value)
    }

    const changeShowNotifPanel = (value) => {
        setShowNotifPanel(value)
    }

    const openDialogLogout = () => {
        setShowSideMobile(false)
        setTimeout(() => [
            setShowDialogLogout(true)
        ], showSideMobile ? 300 : 0)
    }

    const logoutAccount = async () => {
        setShowDialogLogout(false)
        setLoadingLogout(true)
        const result = await logoutAccountController()
        if (result) {
            setTimeout(() => {
                localStorage.clear()
                setResetRecoil(true)
                setLoadingLogout(false)
                navigateTo(loginRoute, { replace: true, state: { action: 20 } })
            }, 300)
        }
    }

    useEffect(() => {
        getNotifications()
    }, [])

    return (
        <>
            {showProgress && <TopBarProgress />}
            <Appbar className='z-30 px-2 border-b border-b-gray-200 sm:px-4 lg:justify-start'>
                <IconButton onClick={() => changeShowSideMobile(true)}>
                    <TbMenu2 className='h-6 w-6' />
                </IconButton>
                <Row className='w-fit justify-between lg:w-full lg:ml-[15.5rem]'>
                    <Row className='w-fit hidden lg:flex overflow-hidden my-auto gap-1'>
                        <TextButton className={`p-2 h-fit rounded-full hover:bg-slate-50 hover:text-indigo-700`}
                            disabled={location.key !== 'default' ? false : true}
                            onClick={() => navigateTo(-1)}>
                            <TbChevronLeft className='h-6 w-6' />
                        </TextButton>
                        <TextButton className={`p-2 h-fit rounded-full hover:bg-slate-50 hover:text-indigo-700`}
                            onClick={() => navigateTo(1)}>
                            <TbChevronRight className='h-6 w-6' />
                        </TextButton>
                    </Row>
                    <div className='relative'>
                        {listUnreadNotification.length > 0 && <span className="absolute top-2 right-2 flex h-2.5 w-2.5">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                            <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500 border border-white"></span>
                        </span>}
                        <IconButton onClick={() => changeShowNotifPanel(true)}>
                            <TbBellRinging className='h-6 w-6' />
                        </IconButton>
                    </div>
                </Row>
            </Appbar>
            <Sidebar className='overflow-y-auto scrollbar-hide scroll-smooth' showSideMobile={showSideMobile} hideSideMobile={changeShowSideMobile}>
                <SidebarHeader>
                    <Column className='gap-3 py-3 text-center'>
                        <Avatar className='h-24 w-24 mx-auto' src={avatar1} />
                        <Column>
                            <Subtitle className='text-gray-800 font-semibold jakarta'>
                                {user.name}
                            </Subtitle>
                            <Caption>
                                {user.username}
                            </Caption>
                        </Column>
                    </Column>
                </SidebarHeader>
                <SidebarMenu className='h-fit gap-1 px-3 py-1'>
                    <SidebarItem
                        id='dashboard'
                        icon={<TbCategory className='h-6 w-6' />}
                        onClick={() => onClickMenu(mitRootRoute)}
                        title='Dashboard'
                        active={idpage}
                        activeSubmenu={idsubmenu ?? idpage}
                        className='rounded-lg' />
                    <SidebarItem
                        id='career'
                        icon={<TbBriefcase className='h-6 w-6' />}
                        onClick={() => onClickMenu(mitCareerRoute)}
                        title='Lowongan'
                        active={idpage}
                        activeSubmenu={idsubmenu ?? idpage}
                        className='rounded-lg' />
                </SidebarMenu>
                <HorizontalDivider />
                <SidebarMenu className='h-fit gap-1 px-3 mb-16'>
                    {/* <SidebarItem
                        id='account'
                        icon={<TbUserCircle className='h-6 w-6' />}
                        onClick={() => onClickMenu(mitAccountRoute)}
                        title='Akun Saya'
                        active={idpage}
                        activeSubmenu={idsubmenu ?? idpage}
                        className='rounded-lg' /> */}
                    <SidebarItem
                        icon={<TbLogout className='h-6 w-6' />}
                        onClick={openDialogLogout}
                        title='Keluar Akun'
                        active={idpage}
                        activeSubmenu={idsubmenu ?? idpage}
                        className='rounded-lg' />
                </SidebarMenu>
            </Sidebar>
            <SideNotifPanel className='sm:max-w-md' showNotifPanel={showNotifPanel} hideNotifPanel={changeShowNotifPanel}>
                <Column className='px-5 gap-8 pt-10'>
                    <Headline2 className='my-auto pt-8'>
                        Notifikasi
                    </Headline2>
                    <WListNotification action={10} listNotification={listUnreadNotification} onReadNotification={onReadNotification} />
                    <WListNotification action={20} listNotification={listReadNotification} onReadNotification={onReadNotification} />
                </Column>
            </SideNotifPanel>
            <FullScreen className={`py-14 lg:pl-72 ${className}`}>
                <div className={'w-full px-4 py-6 sm:p-6 xl:p-8'}>
                    {children}
                </div>
            </FullScreen>
            <AlertDialog className='max-w-xs' show={showDialogLogout} onClose={() => { setShowDialogLogout(false) }}>
                <Column className='divide-y divide-gray-200'>
                    <Column className='px-5 py-10 items-center gap-8'>
                        <Card className='p-1.5 rounded-2xl bg-red-50/50 border-red-200/50'>
                            <TbMoodX className='h-14 w-14 stroke-1.5 text-red-600' />
                        </Card>
                        <Column className='gap-2'>
                            <Headline3 className='text-center'>
                                Keluar Akun
                            </Headline3>
                            <Text className='text-center'>
                                Apakah anda yakin ingin keluar dari Pusat Ikatan Alumni Terpadu UTY?
                            </Text>
                        </Column>
                    </Column>
                    <GridCol className='gap-0 divide-x divide-gray-200'>
                        <TextButton onClick={() => { setShowDialogLogout(false) }} className='w-full py-4 text-base hover:bg-gray-50/50'>
                            Batal
                        </TextButton>
                        <TextButton onClick={logoutAccount} color='red' className='w-full py-4 text-base hover:bg-red-50/50'>
                            Ya, Keluar
                        </TextButton>
                    </GridCol>
                </Column>
            </AlertDialog>
            <LoadingDialog show={loadingLogout} message='Mengeluarkan anda...' />
        </>
    )
}

export default MitraLayout