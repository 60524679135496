//! VARIABLE APP ******************************************************************
//? GLOBAL
export const toastDuration = 3000;

//! ROUTE VARIABLE ****************************************************************
//? ALUMNI ROUTE
export const almConsultationRoute = "/konsultasi";
export const almChatConsultationRoute = "/konsultasi/:id";
export const almWaitingConsultationRoute = "/konsultasi/menunggu";
export const almRejectedConsultationRoute = "/konsultasi/ditolak";
export const almCreateConsultationRoute = "/konsultasi/baru";
export const almTracerRoute = "/tracer";
export const almInputTracerRoute = "/tracer/:id";
export const almBusinessRoute = "/business";
export const almProfileRoute = "/profil";
export const almDocProfileRoute = "/profil/dokumen";
export const almApplyProfileRoute = "/profil/apply-lowongan";
export const almTaggedProfileRoute = "/profil/berita-menandai";
export const almSavedProfileRoute = "/profil/berita-tersimpan";
export const almNotificationProfileRoute = "/profil/notifikasi";
export const almDetailSavedRecognitionRoute = "/profil/tersimpan/:id";
export const almDetailTagRecognitionRoute = "/profil/ditandai/:id";
//? PRODI ROUTE
export const proRootRoute = "/prodi";
export const proRecognitionRoute = "/prodi/rekognisi";
export const proAddRecognitionRoute = "/prodi/rekognisi/tambah";
export const proTracerRoute = "/prodi/tracer";
//? ADMIN ROUTE
export const admRootRoute = "/admin";
export const admOverviewRecognitionRoute = "/admin/berita/ringkasan";
export const admAcceptedRecognitionRoute = "/admin/berita/terverifikasi";
export const admProcessedRecognitionRoute = "/admin/berita/menunggu-verifikasi";
export const admRejectedRecognitionRoute = "/admin/berita/ditolak";
export const admProcessedCommentsRoute = "/admin/komentar/menunggu-verifikasi";
export const admRejectedCommentsRoute = "/admin/komentar/ditolak";
export const admRecognitionRoute = "/admin/rekognisi";
export const admCommentsRoute = "/admin/rekognisi/komentar";
export const admAddRecognitionRoute = "/admin/berita/buat-berita";
export const admEventRoute = "/admin/event";
export const admAddEventRoute = "/admin/event/buat-event";
export const admCareerRoute = "/admin/karir";
export const admOverviewCareerRoute = "/admin/karir/ringkasan";
export const admLokerCareerRoute = "/admin/karir/lowongan";
export const admLowonganRoute = "/admin/karir/:id/lowongan";
export const admApplyCareerRoute = "/admin/karir/:id/lowongan/:id";
export const admMitraRoute = "/admin/mitra";
export const admAcceptedMitraRoute = "/admin/mitra/terverifikasi";
export const admProcessedMitraRoute = "/admin/mitra/menunggu-verifikasi";
export const admRejectedMitraRoute = "/admin/mitra/ditolak";
export const admConsultationRoute = "/admin/konsultasi";
export const admAcceptedConsultationRoute = "/admin/konsultasi/terverifikasi";
export const admProcessedConsultationRoute =
  "/admin/konsultasi/menunggu-verifikasi";
export const admRejectedConsultationRoute = "/admin/konsultasi/ditolak";
export const admCounselorConsultationRoute =
  "/admin/konsultasi/ploting-konselor";
export const admTopicConsultationRoute = "/admin/konsultasi/ploting-topik";
export const admKonselorRoute = "/admin/konsultasi/konselor";
export const admTracerRoute = "/admin/tracer";
export const admOverviewTracerRoute = "/admin/tracer/ringkasan";
export const admKuesionerTracerRoute = "/admin/tracer/data-kuesioner";
export const admWisudaTracerRoute = "/admin/tracer/data-wisuda";
export const admSaranTracerRoute = "/admin/tracer/saran-alumni";
export const admOverviewPenggunaLulusanRoute =
  "/admin/pengguna-lulusan/ringkasan";
export const admSurveiPenggunaLulusanRoute = "/admin/pengguna-lulusan/survei";
export const admPenggunaLulusanRoute = "/admin/tracer/pengguna-lulusan";
export const admBusinessRoute = "/admin/business";
export const admLegalitasBusinessRoute = "/admin/legalitas";
export const admUnivCertificateRoute = "/admin/sertifikat/institusi";
export const admProdiCertificateRoute = "/admin/sertifikat/program-studi";
export const admNotificationRoute = "/admin/notifikasi";
export const admTracerIPTRoute = "/admin/tracer/ipt";
//? KONSELOR ROUTE
export const konRootRoute = "/konselor";
export const konPlotingRoute = "/konselor/ploting";
export const konConsultationRoute = "/konselor/konsultasi";
export const konChatConsultationRoute = "/konselor/konsultasi/:id";
export const konPendingConsultationRoute = "/konselor/konsultasi/pengajuan";
export const konCompleteConsultationRoute = "/konselor/konsultasi/selesai";
export const konNotificationRoute = "/konselor/notifikasi";
//? MITRA ROUTE
export const mitRootRoute = "/mitra";
export const mitCareerRoute = "/mitra/lowongan";
export const mitAddCareerRoute = "/mitra/lowongan/tambah";
export const mitApplyCareerRoute = "/mitra/lowongan/:id";
export const mitAccountRoute = "/mitra/akun";
//? PUBLIC ROUTE
export const landingRoute = "/";
export const notFoundRoute = "*";
export const loginRoute = "/masuk";
export const registerMitraRoute = "/daftar/mitra";
export const successRegMitraRoute = "/daftar/mitra/sukses";
export const recognitionRoute = "/berita";
export const detailRecognitionRoute = "/berita/:id";
export const eventRoute = "/event";
export const detailEventRoute = "/event/:id";
export const careerRoute = "/lowongan";
export const surveiLulusanRoute = "/kuesioner/pengguna-lulusan";
export const testRoute = "/test";

//! API URL VARIABLE **************************************************************
//? PUBLIC
export const gRecognitionApiUrl = "/rekognisi";
export const gLowonganApiUrl = "/loker";
export const gLikeRecognitionApiUrl = "/rekognisi/do/like";
export const gDislikeRecognitionApiUrl = "/rekognisi/do/dislike";
export const gConsultationCategoryApiUrl = "/konsultasi/kategori/all";
export const gB2BApiUrl = "/b2b";
export const gLegalitasB2BApiUrl = "/b2b/siu";
export const gFilterConsultationApiUrl = "/konsultasi-new/filter/all";
export const gListConsultationApiUrl = "/konsultasi-new/list";
export const gChatConsultationApiUrl = "/konsultasi-new/chat";
export const gSurveiApiUrl = "/kuesioner/form";
//? ALUMNI
export const almCheckLikedRecognitionApiUrl = "/rekognisi/do/check-liked";
export const almCheckSavedRecognitionApiUrl = "/rekognisi/do/check-saved";
export const almSaveRecognitionApiUrl = "/rekognisi/do/save";
export const almUnsaveRecognitionApiUrl = "/rekognisi/do/delete-save";
export const almReqConsultationApiUrl = "/alumni/konsultasi/pengajuan";
export const almApplyCareerApiUrl = "/alumni/lowongan/pengajuan";
export const almDocumentApiUrl = "/alumni/berkas";
export const almB2BApiUrl = "/alumni/b2b";
export const almB2BApplyApiUrl = "/alumni/b2b/respon";

//? PUBLIC
export const loginApiUrl = "/login";
export const logoutApiUrl = "/logout";
export const refreshTokenApiUrl = "/token?username";
export const fullNameApiUrl = "/get-name";
export const recognitionApiUrl = "/rekognisi";
export const eventApiUrl = "/event";
export const masterFilterOvTracerApiUrl = "/dashboard/master-filter";
export const grafikStatusOvTracerApiUrl = "/dashboard/bar-status-alumni";
export const rataPendapatanOvTracerApiUrl = "/dashboard/rata-pendapatan";
export const grafikDapatKerjaOvTracerApiUrl = "/dashboard/chart-dapat-kerja";
export const rataDapatKerjaOvTracerApiUrl = "/dashboard/rata-dapat-kerja";
export const grafikHubStudiKerjaOvTracerApiUrl =
  "/dashboard/chart-hubungan-studi-kerja";
export const grafikJenisPerusahaanOvTracerApiUrl =
  "/dashboard/chart-jenis-perusahaan";
export const grafikPosisiWiraOvTracerApiUrl =
  "/dashboard/chart-posisi-wirausaha";
export const grafikTingkatWiraOvTracerApiUrl =
  "/dashboard/chart-tingkat-wirausaha";
export const grafikTingkatPendOvTracerApiUrl =
  "/dashboard/chart-tingkat-pendidikan";
export const grafikSumberBiayaOvTracerApiUrl =
  "/dashboard/chart-sumber-biaya-kuliah";
export const tableTingkatKomOvTracerApiUrl =
  "/dashboard/tabel-tingkat-kompetensi";
export const tableTingkatKomKerjaOvTracerApiUrl =
  "/dashboard/tabel-kompetensi-pekerjaan";
export const tableMetodeBelajarOvTracerApiUrl =
  "/dashboard/tabel-metode-pembelajaran";
export const grafikCariKerjaOvTracerApiUrl = "/dashboard/chart-cari-kerja";
export const grafikAktifCariKerjaOvTracerApiUrl =
  "/dashboard/chart-aktif-cari-kerja";
export const grafikCaraCariKerjaOvTracerApiUrl =
  "/dashboard/chart-cara-cari-kerja";
export const grafikCaraCariKerjaOvTracerApiUrl2 =
  "/dashboard/tabel-cara-cari-kerja";
export const grafikProCariKerjaOvTracerApiUrl =
  "/dashboard/tabel-proses-cari-kerja";
export const grafikBedaKerjaOvTracerApiUrl = "/dashboard/tabel-beda-pekerjaan";
export const tableSaranTracerStudiApiUrl = "/dashboard/tabel-rekap-saran";
export const chatKonsultasiApiUrl = "/konsultasi/chat";
export const notificationsApiUrl = "/notification";
export const masterProdiApiUrl = "/sertif/prodi";
export const sertifAkreditasiApiUrl = "/sertif";
//? ADMIN
export const admRecognitionApiUrl = "/admin/rekognisi";
export const admOvGrafikRecognitionApiUrl = "/admin/rekognisi/overview-grafik";
export const admOvRekapRecognitionApiUrl = "/admin/rekognisi/overview-rekap";
export const tagUserRecognitionApiUrl = "/tag";
export const upImgRecognitionApiUrl = "/img/rekognisi";
export const admCommentsRecognitionApiUrl = "/admin/rekognisi/pending-comments";
export const admKonselorApiUrl = "/admin/konsultasi/konselor";
export const admPlotingKonselorApiUrl = "/admin/konsultasi/plotting";
export const admConsultationApiUrl = "/admin/konsultasi/pengajuan";
export const admConsultationNewApiUrl = "/admin/konsultasi-new/pengajuan";
export const admCounselorConsultationNewApiUrl =
  "/admin/konsultasi-new/konselor";
export const admTopicConsultationNewApiUrl = "/admin/konsultasi-new/plot-topik";
export const admOvGrafikTracerApiUrl = "/admin/tracer/overview-grafik";
export const admRekapKuesTracerApiUrl = "/admin/tracer/overview-rekap";
export const admRekapWisudaTracerApiUrl = "/admin/wisuda/overview-rekap";
export const admSurveiPenggunaApiUrl = "/survei";
export const admMasterKonselorApiUrl = "/admin/konselor";
export const admSearchKonselorApiUrl = "/admin/konselor/search";
export const admEventApiUrl = "/admin/event";
//? PRODI
export const proRecognitionApiUrl = "/prodi/rekognisi";
export const proOvGrafikRecognitionApiUrl = "/prodi/rekognisi/overview-grafik";
export const proOvGrafikTracerApiUrl = "/prodi/tracer/overview-grafik";
export const proRekapKuesTracerApiUrl = "/prodi/tracer/overview-rekap";
export const proRekapWisudaTracerApiUrl = "/prodi/wisuda/overview-rekap";
//? ALUMNI
export const dataAlumniApiUrl = "/alumni";
export const checkLikeRecognitionApiUrl = "/rekognisi/do/check-liked";
export const likedRecognitionApiUrl = "/rekognisi/do/like";
export const dislikedRecognitionApiUrl = "/rekognisi/do/dislike";
export const checkSaveRecognitionApiUrl = "/rekognisi/do/check-saved";
export const saveRecognitionApiUrl = "/rekognisi/do/save";
export const unsaveRecognitionApiUrl = "/rekognisi/do/delete-save";
export const checkFilledTracerUrl = "/tracer/check/";
export const masterProvinsiTracer = "/tracer/master/provinsi";
export const masterTingKejaTracer = "/tracer/master/tingkatkerja";
export const masterPosisiTracer = "/tracer/master/posisi";
export const masterSumBiayaTracer = "/tracer/master/sumberbiaya";
export const dataTracerUrl = "/tracer/get/";
export const saveTracerUrl = "/tracer/isi";
export const savedRecognitionApiUrl = "/rekognisi/saved";
export const taggedRecognitionApiUrl = "/rekognisi/tagged";
export const historyConsultationApiUrl = "/konsultasi/list";
export const reqConsultationApiUrl = "/alumni/konsultasi/pengajuan";
export const jobsCareerApiUrl = "/loker";
export const filterCareerApiUrl = "/loker/filter";
export const documentApiUrl = "/alumni/berkas";
export const applyCareerApiUrl = "/alumni/lowongan/pengajuan";
//? KONSELOR
export const plottingConsultationApiUrl = "/konselor/plotting";
//? MITRA
export const registerMitraApiUrl = "/mitra/register";
export const admMitraApiUrl = "/admin/mitra";
export const mitLowonganApiUrl = "/mitra/lowongan";
export const mitApplyLowonganApiUrl = "/mitra/pelamar";
export const mitApproveLowonganApiUrl = "/mitra/approve/";

//! GLOBAL VARIABLE ***************************************************************
export const tokenApiKey = "tokenApi";
export const identifyUserKey = "userType";
export const refreshTokenKey = "icikiwir";

//! ATOMS VARIABLE ****************************************************************
//? GLOBAL
export const gRecognitionKey = "gRecognition";
export const gLandingRecognitionKey = "gLandingRecognition";
export const gSuggestionRecognitionKey = "gSuggestionRecognition";
export const gLandingLowonganKey = "gLandingLowongan";
export const gEventKey = "gEvent";
export const gLandingEventKey = "gLandingEvent";
export const gSuggestionEventKey = "gSuggestionEvent";
export const gCareerKey = "gCareer";
export const gMasterFilterCareerKey = "gMasterFilterCareer";
export const gInitTypeCareerKey = "gInitTypeCareer";
export const gInitTimeCareerKey = "gInitTimeCareer";
export const gInitProvCareerKey = "gInitProvCareer";
export const gInitCityCareerKey = "gInitCityCareer";
export const gOpsiTypeCareerKey = "gOpsiTypeCareer";
export const gOpsiTimeCareerKey = "gOpsiTimeCareer";
export const gOpsiProvCareerKey = "gOpsiProvCareer";
export const gOpsiCityCareerKey = "gOpsiCityCareer";
export const gFilterConsultationKey = "gFilterConsultation";
export const gConsultationCategoryKey = "gConsultationCategory";
export const gChatConsultationKey = "gChatConsultation";

//? ALUMNI
export const aApproveConsultationKey = "aApproveConsultation";
export const aProcessedConsultationKey = "aProcessedConsultation";
export const aRejectedConsultationKey = "aRejectedConsultation";
export const aLegalitasB2BKey = "aLegalitasB2B";
export const aOpsiLegalitasB2BKey = "aOpsiLegalitasB2B";
export const aB2BKey = "aB2B";
export const aMyB2BKey = "aMyB2B";
export const aApplyB2BKey = "aApplyB2B";

//? GLOBAL
export const resetRecoilKey = "resetRecoil";
export const fullNameDataRecoilKey = "fullNameData";
export const userDataKey = "userData";
export const masterFilterOvTracerKey = "masterFilterOvTracer";
export const unreadNotificationsKey = "unreadNotifications";
export const readNotificationsKey = "readNotifications";
//? ADMIN
export const currentIdxTabRecognitionKey = "idxTabRecognition";
export const ovRecognitionKey = "ovRecognition";
export const grafikOvRecognitionKey = "grafikOvRecognition";
export const rekapOvRecognitionKey = "rekapOvRecognition";
export const acceptedRecognitionKey = "acceptedRecognition";
export const processedRecognitionKey = "processedRecognition";
export const rejectedRecognitionKey = "rejectRecognition";
export const currentIdxTabConsultationKey = "idxTabConsultation";
export const acceptedConsultationKey = "acceptedConsultation";
export const processedConsultationKey = "processedConsultation";
export const rejectedConsultationKey = "rejectedConsultation";
export const currentIdxTabTracerKey = "idxTabTracer";
export const grafikOvTracerKey = "grafikOvTracer";
export const grafikStatusOvTracerKey = "grafikStatusOvTracer";
export const rekapKuesionerTracerKey = "rekapKuesionerTracer";
export const dateRangeKuesionerTracerKey = "dateRangeKuesionerTracer";
export const rekapWisudaTracerKey = "rekapWisudaTracer";
export const thnWisudaTracerKey = "thnWisudaTracer";
export const blnWisudaTracerKey = "blnWisudaTracer";
export const rekapInfoWisudaTracerKey = "rekapInfoWisudaTracer";
export const dateRangeSurveiPenggunaLulusanKey =
  "dateRangeSurveiPenggunaLulusan";
export const currentIdxTabCommentsKey = "idxTabComments";
export const processedCommentsKey = "processedComments";
export const rejectedCommentsKey = "rejectedComments";
export const currentIdxTabCareerKey = "idxTabCareer";
export const currentIdxTabMitraKey = "idxTabMitra";
export const acceptedMitraKey = "acceptedMitra";
export const processedMitraKey = "processedMitra";
export const rejectedMitraKey = "rejectedMitra";
export const currentIdxTabSertifKey = "idxTabSertifikat";
export const currentIdxTabPenggunaAlumniKey = "idxTabPenggunaAlumni";
export const currentIdxTabB2BKey = "idxTabB2B";
export const acceptedB2BKey = "acceptedB2B";
export const processedB2BKey = "processedB2B";
export const rejectedB2BKey = "rejectedB2B";
export const currentIdxTabLegalitasKey = "idxTabLegalitas";
export const acceptedLegalitasKey = "acceptedLegalitas";
export const processedLegalitasKey = "processedLegalitas";
export const rejectedLegalitasKey = "rejectedLegalitas";
export const admFilterPenggunaLulusan = "/dashboard/master-filter";
export const admGrafikSebaranResponden = "/pengguna/diagram-pengguna-lulusan";
export const admRekapPenilaianKepuasan = "/pengguna/diagram-hasil-penilaian-kepuasan-responden"
//? ALUMNI
export const dataAlumniKey = "dataAlumni";
export const currentRecognitionFromKey = "fromRecognition";
export const inputTracerKey = "inputTracer";
export const sertifAkreditasiTracerKey = "sertifAkreditasiTracer";
export const sertifAkreditasiInstitusiTracerKey =
  "sertifAkreditasiInstitusiTracer";
export const indexInputTracerKey = "indexInputTracer";
export const masterProvinsiTracerKey = "provinsiTracer";
export const initProvinsiTracerKey = "initProvinsiTracer";
export const masterTingKerjaTracerKey = "tingKerjaTracer";
export const masterPosisiTracerKey = "posisiTracer";
export const masterSumBiayaTracerKey = "biayaTracer";
export const inputTracerActionKey = "inputTracerAction";
export const dataTracerKey = "dataTracer";
export const tracerIdentitasQuestionKey = "tracerIdentitasQuestion";
export const tracerKueWajibQuestionKey = "tracerKueWajibQuestion";
export const tracerKueOpsionalQuestionKey = "tracerKueOpsionalQuestion";
export const currentIdxTabB2BAlumniKey = "idxTabB2BAlm";
export const currentIdxTabProfileAlumniKey = "idxTabProfileAlumni";
export const applyCareerKey = "applyCareer";
export const savedRecognitionKey = "savedRecognition";
export const taggedRecognitionKey = "taggedRecognition";
//? KONSELOR
export const currentIdxTabPlotingKey = "currentIdxTabPloting";
//? MITRA
export const currentIdxTabLowonganKey = "idxTabLowongan";
export const acceptedLowonganKey = "acceptedLowongan";
export const currentAacceptedLowonganKey = "currentAcceptedLowongan";
export const processedLowonganKey = "processedLowongan";
export const rejectedLowonganKey = "rejectedLowongan";

//! ROLE USER *********************************************************************
export const admin = "1";
export const alumni = "2";
export const prodi = "3";
export const konselor = "4";
export const mitra = "5";
