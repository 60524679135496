import { axios_delete, axios_get, axios_post } from "../services/apiservice";
import { getCurrentUser } from "../shared/user";
import { almApplyCareerApiUrl, almB2BApiUrl, almB2BApplyApiUrl, almCheckLikedRecognitionApiUrl, almCheckSavedRecognitionApiUrl, almDocumentApiUrl, almReqConsultationApiUrl, almSaveRecognitionApiUrl, almUnsaveRecognitionApiUrl, applyCareerApiUrl, dataAlumniApiUrl, filterCareerApiUrl, gB2BApiUrl, gDislikeRecognitionApiUrl, gFilterConsultationApiUrl, gLegalitasB2BApiUrl, gLikeRecognitionApiUrl, gListConsultationApiUrl, gRecognitionApiUrl, jobsCareerApiUrl, savedRecognitionApiUrl, taggedRecognitionApiUrl } from "../shared/variable";

//? GET -- PROFIL ALUMNI
export const getProfileAlumniController = async () => {
    const { username } = await getCurrentUser()
    const result = await axios_get(`${dataAlumniApiUrl}/${username}`)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? CHECK -- ALUMNI MENYUKAI ATAU TIDAK MENYUKAI REKOGNISI
export const checkLikedRecognitionController = async (id) => {
    const body = {
        id_rekognisi: id,
    }
    const result = await axios_post(almCheckLikedRecognitionApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? CHECK -- ALUMNI MENYIMPAN ATAU TIDAK MENYIMPAN REKOGNISI
export const checkSavedRecognitionController = async (id) => {
    const body = {
        id_rekognisi: id,
    }
    const result = await axios_post(almCheckSavedRecognitionApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? SAVE -- MEMBERIKAN KOMENTAR PADA REKOGNISI
export const saveCommentRecognitionController = async ({ id, comment }) => {
    const body = {
        comment: comment,
    }
    const result = await axios_post(`${gRecognitionApiUrl}/${id}/comment`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? UPDATE -- MENYUKAI ATAU BATAL MENYUKAI REKOGNISI
export const likeRecognitionController = async ({ action, id }) => {
    const body = {
        id_rekognisi: id,
    }
    const result = await axios_post(action === 10 ? gLikeRecognitionApiUrl : gDislikeRecognitionApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? UPDATE -- SIMPAN ATAU BATAL SIMPAN REKOGNISI
export const saveRecognitionController = async ({ action, id }) => {
    const body = {
        id_rekognisi: id,
    }
    const result = await axios_post(action === 10 ? almSaveRecognitionApiUrl : almUnsaveRecognitionApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DAFTAR REKOGNISI YANG MENANDAI ALUMNI
export const getTaggedRecognitionController = async () => {
    const body = {}
    const result = await axios_post(taggedRecognitionApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DAFTAR REKOGNISI TERSIMPAN
export const getSavedRecognitionController = async () => {
    const body = {}
    const result = await axios_post(savedRecognitionApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- FILTER KONSULTASI BARU
export const getFilterConsultationController = async () => {
    const result = await axios_get(gFilterConsultationApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? CHECK -- ALUMNI MEMILIKI KONSULTASI YANG MASIH BERLANGSUNG ATAU TIDAK
export const checkRequestConsultationController = async () => {
    const result = await axios_get(almReqConsultationApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- LIST KONSULTASI
export const getConsultationController = async (action) => {
    const header = {}
    const params = {
        approve: action === 10 ? 'ACCEPTED' : action === 20 ? 'PROCESSED' : 'REJECTED',
    }
    const result = await axios_get(gListConsultationApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? SAVE -- REQUEST KONSULTASI BARU
export const requestConsultationController = async ({ category, subject, topic }) => {
    const body = {
        id_kategori_konsultasi: category,
        subject: subject,
        topik: topic
    }
    const result = await axios_post(almReqConsultationApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? SAVE -- REQUEST KONSULTASI BARU
export const requestNewConsultationController = async ({ topic, subject, problems }) => {
    const body = {
        id_topik: topic,
        subjek: subject,
        permasalahan: problems,
    }
    const result = await axios_post(almReqConsultationApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- FILTER LOWONGAN
export const getFilterCareerController = async () => {
    const result = await axios_get(filterCareerApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DAFTAR LOWONGAN DITAWARKAN
export const getCareerController = async ({ jenispekerjaan = null, waktu = null, prov = 1, kota = 10, posisi = null }) => {
    const header = {}
    const params = {
        jenispekerjaan,
        waktu,
        prov,
        kota,
        posisi,
    }
    const result = await axios_get(jobsCareerApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- APPLY LOWONGAN
export const applyCareerController = async (id) => {
    const body = {
        id_lowongan: id,
    }
    const result = await axios_post(applyCareerApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DAFTAR APPLY LOWONGAN
export const getApplyLowonganController = async () => {
    const result = await axios_get(almApplyCareerApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? SAVE -- DOKUMEN IJAZAH, TRANSKRIP NILAI ATAU CV
export const uploadDocumentController = async (action, document) => {
    const bodyFromData = new FormData()
    if (action === 10) {
        bodyFromData.append('ijazah', document)
    } else if (action === 20) {
        bodyFromData.append('transkrip', document)
    } else if (action === 30) {
        bodyFromData.append('cv', document)
    } else {
        bodyFromData.append('portofolio', document)
    }

    const result = await axios_post(almDocumentApiUrl, bodyFromData, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? SAVE -- EMAIL, NO HP ATAU WA
export const updateContactController = async (email, nowa) => {
    const body = {
        email: email,
        no_wa: nowa,
    }
    const result = await axios_post(almDocumentApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- ALL POSTINGAN B2B
export const getB2BController = async ({ page, limit, type, category }) => {
    const header = {}
    const params = {
        page: page,
        limit: limit,
        jenis: type,
        kategori: category,
    }
    const result = await axios_get(gB2BApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- MY POSTINGAN B2B
export const getMyB2BController = async ({ page, limit, type, category }) => {
    const header = {}
    const params = {
        page: page,
        limit: limit,
        jenis: type,
        kategori: category,
    }
    const result = await axios_get(almB2BApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- LIST MY APPLY POSTINGAN B2B
export const getApplyB2BController = async ({ page, limit }) => {
    const header = {}
    const params = {
        page: page,
        limit: limit,
    }
    const result = await axios_get(almB2BApplyApiUrl, header, params)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? SAVE -- POSTINGAN B2B ALUMNI
export const saveB2BController = async ({ title, type, category, desc, legalitas }) => {
    const body = {
        title: title,
        jenis: type,
        kategori: category,
        body: desc,
        legalitas: legalitas
    }
    const result = await axios_post(gB2BApiUrl, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- APPLY OR CANCEL POSTINGAN B2B ALUMNI
export const applyB2BController = async ({ id, type, note }) => {
    const body = {
        type: type,
        catatan: note,
    }
    const result = await axios_post(`${gB2BApiUrl}/${id}`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? POST -- APPROVE OR RECJECT APPLIED POSTINGAN B2B ALUMNI
export const approveAppliedB2BController = async ({ id, iduser, approve }) => {
    const body = {
        id_user: iduser,
        approve: approve,
    }
    const result = await axios_post(`${gB2BApiUrl}/${id}/pilih`, body, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? DELETE -- DELETE POSTINGAN B2B ALUMNI
export const deleteB2BController = async (id) => {
    const result = await axios_delete({
        url: `${gB2BApiUrl}/${id}`
    })
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DAFTAR DOKUMEN LEGALITAS B2B
export const getLegalitasB2BController = async () => {
    const result = await axios_get(gLegalitasB2BApiUrl)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}

//? GET -- DAFTAR DOKUMEN LEGALITAS B2B
export const uploadLegalitasB2BController = async ({ name, type, doc }) => {
    const bodyFromData = new FormData()
    bodyFromData.append('nama_usaha', name)
    bodyFromData.append('jenis_usaha', type)
    bodyFromData.append('siu', doc)

    const result = await axios_post(gLegalitasB2BApiUrl, bodyFromData, true)
    if (result.status) {
        return result
    }
    return {
        status: false,
        message: result.message
    };
}