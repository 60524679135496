import React, { Suspense, lazy, useState } from 'react'
import { TbChecks, TbRefresh, TbX } from 'react-icons/tb'
import { useRecoilState } from 'recoil'
import { currentIndexTabLowongan } from '../../atoms'
import STable from '../../components/shimmer/stable'
import { Column, Headline1, TabPanel, Tabs } from '../../shared/ui'

const TableAcceptedLowongan = lazy(() => import('../../components/tabels/accepted_lowongan'))
const TableProcessedLowongan = lazy(() => import('../../components/tabels/processed_lowongan'))
const TableRejectedLowongan = lazy(() => import('../../components/tabels/rejected_lowongan'))

const AdmLowonganPage = () => {

    const [currentTabIndex, setCurrentTabIndex] = useRecoilState(currentIndexTabLowongan)
    const [showProgress, setShowProgress] = useState(true)

    const onChangeTabIndex = (index) => {
        setShowProgress(true)
        setCurrentTabIndex(index)
    }

    const onDoneLoadData = (value) => {
        setShowProgress(false)
    }

    return (
        <Column className='gap-y-5'>
            <Headline1>
                Lowongan
            </Headline1>
            <Tabs selectedTabIdx={currentTabIndex} onChangeTabIndex={onChangeTabIndex}>
                <TabPanel icon={<TbChecks className='h-5 w-5' />} title='Disetujui' textActiveColor='text-green-700'>
                    <Suspense fallback={<STable column={8} listWidth={['w-12', 'w-32', 'w-72', 'w-20', 'w-20', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-end', 'justify-end', 'justify-end', 'justify-end']} />}>
                        <TableAcceptedLowongan onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbRefresh className='h-5 w-5' />} title='Menunggu' textActiveColor='text-blue-700'>
                    <Suspense fallback={<STable column={8} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableProcessedLowongan onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
                <TabPanel icon={<TbX className='h-5 w-5' />} title='Ditolak' textActiveColor='text-red-700'>
                    <Suspense fallback={<STable column={8} listWidth={['w-12', 'w-32', 'w-72', 'w-32', 'w-20', 'w-16']} listAlign={['justify-start', 'justify-start', 'justify-start', 'justify-start', 'justify-center', 'justify-end']} />}>
                        <TableRejectedLowongan onDoneLoadData={onDoneLoadData} />
                    </Suspense>
                </TabPanel>
            </Tabs>
        </Column>
    )
}

export default AdmLowonganPage