import { useEffect, useState } from "react"
import { Column, Row } from "../../../../components/interfaces/block"
import { Card } from "../../../../components/interfaces/card"
import { CaptionText, Headline1Text, TitleText } from "../../../../components/interfaces/text"
import { getOvRataPendapatanTracerController } from "../../../../controllers/admin_controllers"
import { DotSeparated } from "../../../../shared/ui"

function CardRataPenghasilan({ prodi, year, takehome }) {

  const [locTakeHome, setLocTakeHome] = useState(0)

  const getOvRataPendapatanTracer = async () => {
    const props = {
      prodi: prodi.value,
      tahun: year.value,
    }
    const result = await getOvRataPendapatanTracerController(props)
    if (result.status) {
      setLocTakeHome(result.data)
    }
  }

  useEffect(() => {
    if (prodi.value != null || year.value != null) {
      getOvRataPendapatanTracer()
    }
  }, [prodi, year])

  return (
    <Card className='w-full h-full p-6'>
      <Column className={'gap-y-6 h-full'}>
        <Column className={'gap-y-1'}>
          <TitleText>
            Rata-Rata Penghasilan Lulusan
          </TitleText>
          <Row>
            <CaptionText>
              {prodi.label}
            </CaptionText>
            <DotSeparated />
            <CaptionText>
              {year.value ? `Tahun ${year.label}` : year.label}
            </CaptionText>
          </Row>
        </Column>
        <Headline1Text className={'font-semibold my-auto'}>
          {((prodi.value != null || year.value != null) ? locTakeHome : takehome) ?? 'Rp 0'}
        </Headline1Text>
      </Column>
    </Card>
  )
}

export default CardRataPenghasilan