import React from 'react'
import MitraLayout from '../../layouts/mitra_layout'
import { Card, Column, Headline2, Headline3, HorizontalDivider, Image, Row, Text, TransitionPage } from '../../shared/ui'

const MitAccountPage = () => {
    return (
        <>
            <MitraLayout idpage='account'>
                <TransitionPage>
                    <Row>
                        <Card className='w-full p-0 overflow-hidden'>
                            <Column>
                                <Image className='aspect-[16/5]' imgClasName='aspect-[16/5] object-cover' hoverScale={false} />
                                <Column className='px-8 py-8 relative gap-y-5'>
                                    <Image className='absolute -top-[4rem] w-full bg-white aspect-square max-w-[8rem] max-h-[8rem] rounded-full border border-gray-200 ring-4 ring-white' imgClasName='w-full aspect-square max-w-[8rem] max-h-[8rem]' hoverScale={false} />
                                    <Column className='gap-3 mt-[3rem]'>
                                        <Headline3>
                                            GMEDIA
                                        </Headline3>
                                        <Text>
                                            Deskripsi
                                        </Text>
                                    </Column>
                                    <HorizontalDivider />
                                </Column>
                            </Column>
                        </Card>
                        <Column className='max-w-md'>
                        </Column>
                    </Row>
                </TransitionPage>
            </MitraLayout>
        </>
    )
}

export default MitAccountPage