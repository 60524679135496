import moment from 'moment'
import React from 'react'
import { TableCell, TableRow } from '../../../../../components/interfaces/table'
import { BodyText } from '../../../../../components/interfaces/text'

function RowKuesionerTracer({ idx, kuesioner, page = 1, limit = 10 }) {
  return (
    <TableRow striped>
      <TableCell>
        {limit * (page - 1) + (idx + 1)}
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {kuesioner.nim}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2 capitalize'}>
          {kuesioner.nama.toLowerCase()}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {kuesioner.nama_jenjang}
        </BodyText>
      </TableCell>
      <TableCell>
        <BodyText className={'line-clamp-2'}>
          {kuesioner.nama_jurusan}
        </BodyText>
      </TableCell>
      <TableCell>
        {moment(kuesioner.tgllulus).format('DD MMM YYYY')}
      </TableCell>
      <TableCell>
        {moment(kuesioner.created).format('DD MMM YYYY')}
      </TableCell>
    </TableRow>
  )
}

export default RowKuesionerTracer