import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../../../../components/interfaces/table'
import RowTableAcceptedRecognition from './rows/row_accepted_recognition'

function TableAcceptedRecognition({ listRecognition, page, limit, onShort }) {

  const [currentSort, setCurrentSort] = useState(null)

  const onHandleClick = (recognition) => {
    console.log(recognition)
  }

  const onHandleSort = (value) => {
    setCurrentSort(value)
    onShort(value)
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            No
          </TableHeadCell>
          <TableHeadCell>
            Penulis
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='title'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Judul Berita
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='viewer'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Kunjungan
          </TableHeadCell>
          <TableHeadCell
            sortable
            sortkey='likes'
            currentSortKey={currentSort !== null && currentSort.key}
            onShortable={onHandleSort}>
            Menyukai
          </TableHeadCell>
          <TableHeadCell>
            Komentar
          </TableHeadCell>
          <TableHeadCell className={'text-center'}>
            Detail
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listRecognition.length > 0 ? listRecognition.map((recognition, idx) => {
          return (
            <RowTableAcceptedRecognition
              key={idx}
              idx={idx}
              page={page}
              limit={limit}
              recognition={recognition}
              handleClick={onHandleClick} />
          )
        }) : <TableRow>
          <TableCell colSpan={7} className='text-center text-gray-500'>
            No data to display
          </TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  )
}

export default TableAcceptedRecognition