import React, { useEffect, useState } from 'react'
import { TbBellFilled, TbMenu2 } from 'react-icons/tb'
import { useLocation, useNavigate } from 'react-router-dom'
import { getNotificationsController } from '../../controllers/global_controllers'
import logosimfora from '../../images/simfora_black.png'
import { Appbar, AppbarItem, AppbarMenu, ButtonHover, IconButton, PrimaryButton, Row, Subtitle } from '../../shared/ui'
import { getCurrentUser } from '../../shared/user'
import { almNotificationProfileRoute, almProfileRoute, almTracerRoute, eventRoute, landingRoute, loginRoute, recognitionRoute } from '../../shared/variable'

function AlmAppBar({ onShowSidebar }) {

  const location = useLocation()
  const navigateTo = useNavigate()

  const { name, username } = getCurrentUser()

  const [sumNewNotif, setSumNewNotif] = useState(0)

  const getNotifications = async () => {
    if (username) {
      const result = await getNotificationsController()
      if (result.status) {
        const listNotification = [...result.data]
        const unread = listNotification.filter((notif) => notif.is_read === false)
        setSumNewNotif(unread.length)
      }
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <Appbar className={`px-4 z-30 bg-white/90 backdrop-blur-xl border-b border-b-gray-200 lg:px-8 lg:py-5`}>
      <Row className={'justify-between'}>
        <IconButton
          className={'xl:hidden'}
          onClick={onShowSidebar}>
          <TbMenu2 className={'h-6 w-6'} />
        </IconButton>
        <img
          src={logosimfora}
          className={'cursor-pointer hidden xl:flex h-7 my-auto'}
          onClick={() => navigateTo(landingRoute)} />
        <AppbarMenu className={'hidden xl:flex gap-x-3'}>
          <AppbarItem
            id='home'
            title='Beranda'
            className={'text-lg'}
            active={location.pathname === landingRoute}
            onClick={() => navigateTo(landingRoute)} />
          <AppbarItem
            id='recognition'
            title='Berita'
            className={'text-lg'}
            active={location.pathname.includes(recognitionRoute)}
            onClick={() => navigateTo(recognitionRoute)} />
          <AppbarItem
            id='event'
            title='Event'
            className={'text-lg'}
            active={location.pathname.includes(eventRoute)}
            onClick={() => navigateTo(eventRoute)} />
          {/* <AppbarItem
            id='career'
            title='Lowongan'
            className={'text-lg'}
            active={location.pathname.includes(careerRoute)}
            onClick={() => navigateTo(careerRoute)} /> */}
          {/* <AppbarItem
            id='consultation'
            title='Konsultasi'
            className={'text-lg'}
            active={location.pathname.includes(almConsultationRoute)}
            onClick={() => navigateTo(almConsultationRoute)} /> */}
          <AppbarItem
            id='tracer'
            title='Tracer Study'
            className={'text-lg'}
            active={location.pathname.includes(almTracerRoute)}
            onClick={() => navigateTo(almTracerRoute)} />
          {/* <AppbarItem
            id='business'
            title='B2B'
            className={'text-lg'}
            active={location.pathname.includes(almBusinessRoute)}
            onClick={() => navigateTo(almBusinessRoute)} /> */}
        </AppbarMenu>
        {username ? <Row className={'w-fit gap-x-3 sm:gap-x-5 my-auto'}>
          <ButtonHover className={'my-auto'}>
            <div className={'relative'}>
              {sumNewNotif > 0 &&
                <span className={'absolute top-2 right-2 flex h-2.5 w-2.5'}>
                  <span className={'animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75'} />
                  <span className={'relative inline-flex rounded-full h-2.5 w-2.5 bg-red-500 border-[1.5px] border-white'} />
                </span>
              }
              <IconButton
                color='custom'
                className={'text-gray-800 hover:text-indigo-600 hover:bg-gray-50'}
                onClick={() => navigateTo(almNotificationProfileRoute)}>
                <TbBellFilled className={'h-6 w-6'} />
              </IconButton>
            </div>
          </ButtonHover>
          <ButtonHover className='my-auto'>
            <div
              className={'flex flex-col w-8 h-8 bg-indigo-600 rounded-full items-center'}
              onClick={() => navigateTo(almProfileRoute)}>
              <Subtitle className={'text-white font-semibold my-auto'}>
                {name.substring(0, 1)}
              </Subtitle>
            </div>
          </ButtonHover>
        </Row> : <ButtonHover>
          <PrimaryButton
            pills
            color='indigo'
            className={'px-6'}
            onClick={() => navigateTo(loginRoute)}>
            Masuk
          </PrimaryButton>
        </ButtonHover>}
      </Row>
    </Appbar>
  )
}

export default AlmAppBar