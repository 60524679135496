import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { TbUserCircle } from 'react-icons/tb';
import Cookies from 'universal-cookie';
import { Caption, Card, Column, HorizontalDivider, OutlineInput, Row, Title } from '../shared/ui';
import { refreshTokenKey } from '../shared/variable';

const WAlmKueSatu = ({ reqCheck, onCheck, answer = null }) => {

    let decodeCookies;

    const cookies = new Cookies()
    const tokenCookies = cookies.get(refreshTokenKey) ?? null

    if (tokenCookies != null) {
        decodeCookies = jwtDecode(tokenCookies)
    }

    const kdpt = '051018'

    const [npm, setNpm] = useState('')
    const [blnlulus, setBlnLulus] = useState('')
    const [thnlulus, setThnLulus] = useState('')
    const [kdpd, setKdPd] = useState('')
    const [nama, setNama] = useState('')
    const [notlp, setNoTlp] = useState('')
    const [email, setEmail] = useState('')
    const [nik, setNik] = useState('')
    const [npwp, setNpwp] = useState('')
    const [dpta, setDpta] = useState('')

    const GetAnswerQuestion = (kode) => {
        const value = answer[kode]
        return value
    }

    const onChangeInput = (result) => {
        switch (result['action']) {
            case 10:
                setNoTlp(result['value'])
                break;
            case 20:
                setEmail(result['value'])
                break;
            case 30:
                setNik(result['value'])
                break;
            case 40:
                setNpwp(result['value'])
                break;
        }
    }

    useEffect(() => {
        setNpm(GetAnswerQuestion('nimhsmsmh'))
        setBlnLulus(GetAnswerQuestion('bulan_lulus'))
        setThnLulus(GetAnswerQuestion('tahun_lulus'))
        setKdPd(GetAnswerQuestion('kdpstmsmh'))
        setNama(GetAnswerQuestion('nmmhsmsmh'))
        setNoTlp(GetAnswerQuestion('telpomsmh'))
        setEmail(GetAnswerQuestion('emailmsmh'))
        setNik(GetAnswerQuestion('nik'))
        setNpwp(GetAnswerQuestion('npwp'))
        setDpta(GetAnswerQuestion('dosbing'))
    }, [])

    useEffect(() => {
        if (reqCheck) {
            const result = {
                'status': true,
                'action': 10,
                'value': {
                    'nimhsmsmh': npm,
                    'bulan_lulus': blnlulus,
                    'tahun_lulus': thnlulus,
                    'kdpstmsmh': kdpd,
                    'nmmhsmsmh': nama,
                    'telpomsmh': notlp,
                    'emailmsmh': email,
                    'nik': nik,
                    'npwp': npwp,
                    'dosbing': dpta,
                }
            }
            onCheck(result)
        }
    }, [reqCheck])

    return (
        <Card className='w-full p-0'>
            <Column>
                <Row className='px-4 py-3 sm:px-8 sm:py-5'>
                    <Column className='gap-0.5 my-auto'>
                        <Title>
                            Identitas Diri Alumni
                        </Title>
                        <Caption>
                            Lengkapi data diri anda dengan benar
                        </Caption>
                    </Column>
                    <TbUserCircle className='hidden sm:flex h-14 w-14 my-auto stroke-1.5 text-indigo-300' />
                </Row>
                <HorizontalDivider className='mt-0 mb-0' />
                <Column className='px-4 py-3 sm:px-8 sm:py-6 gap-6 mb-4'>
                    <OutlineInput
                        id='nimhsmsmh'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Nomor Pokok Mahasiswa'
                        labelClassName='text-gray-800'
                        placeholder='Jawaban anda'
                        defaultValue={npm}
                        disabled
                        required />
                    <OutlineInput
                        id='kdpt'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Kode Perguruan Tinggi'
                        labelClassName='text-gray-800'
                        placeholder='Jawaban anda'
                        defaultValue={kdpt}
                        disabled
                        required />
                    <Row className='flex-col sm:flex-row gap-x-3 gap-y-6'>
                        <OutlineInput
                            id='bulan_lulus'
                            type='text'
                            inputWidth='w-full'
                            className='rounded-md'
                            label='Bulan'
                            labelClassName='text-gray-800'
                            placeholder='Jawaban anda'
                            defaultValue={blnlulus}
                            disabled
                            required />
                        <OutlineInput
                            id='tahun_lulus'
                            type='text'
                            inputWidth='w-full'
                            className='rounded-md'
                            label='Tahun'
                            labelClassName='text-gray-800'
                            placeholder='Jawaban anda'
                            defaultValue={thnlulus}
                            disabled
                            required />
                    </Row>
                    <Row className='flex-col sm:flex-row gap-x-3 gap-y-6'>
                        <OutlineInput
                            id='kdpstmsmh'
                            type='text'
                            inputWidth='w-full'
                            className='rounded-md'
                            label='Jenjang'
                            labelClassName='text-gray-800'
                            placeholder='Jawaban anda'
                            defaultValue={decodeCookies.jenjang}
                            disabled
                            required />
                        <OutlineInput
                            id='kdpstmsmh2'
                            type='text'
                            inputWidth='w-full'
                            className='rounded-md'
                            label='Jurusan'
                            labelClassName='text-gray-800'
                            placeholder='Jawaban anda'
                            defaultValue={decodeCookies.prodi}
                            disabled
                            required />
                    </Row>
                    <OutlineInput
                        id='nmmhsmsmh'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Nama Lengkap'
                        labelClassName='text-gray-800'
                        placeholder='Jawaban anda'
                        defaultValue={nama}
                        maxLength={100}
                        disabled
                        required />
                    {/* <OutlineInput
                        action={10}
                        id='telpomsmh'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Nomor Telepon/HP'
                        labelClassName='text-gray-800'
                        onChange={onChangeInput}
                        onKeyDown={(event) => {
                            if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }
                        }}
                        placeholder='Jawaban anda'
                        defaultValue={notlp}
                        maxLength={13}
                        required /> */}
                    <OutlineInput
                        action={20}
                        id='emailmsmh'
                        type='email'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Alamat Email'
                        labelClassName='text-gray-800'
                        onChange={onChangeInput}
                        placeholder='Jawaban anda'
                        defaultValue={email}
                        maxLength={50}
                        required />
                    {/* <OutlineInput
                        action={30}
                        id='nik'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Nomor Induk Kependudukan (NIK)'
                        labelClassName='text-gray-800'
                        onChange={onChangeInput}
                        onKeyDown={(event) => {
                            if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }
                        }}
                        placeholder='Jawaban anda'
                        defaultValue={nik}
                        maxLength={16}
                        required /> */}
                    <OutlineInput
                        action={40}
                        id='npwp'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Nomor Pokok Wajib Pajak (NPWP)'
                        labelClassName='text-gray-800'
                        onKeyDown={(event) => {
                            if (event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                                if (!/^[0-9\b]+$/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }
                        }}
                        onChange={onChangeInput}
                        placeholder='Jawaban anda'
                        defaultValue={npwp}
                        maxLength={16} />
                    <OutlineInput
                        id='dosbing'
                        type='text'
                        inputWidth='w-full'
                        className='rounded-md'
                        label='Dosen Pembimbing'
                        labelClassName='text-gray-800'
                        placeholder='Jawaban anda'
                        defaultValue={dpta}
                        disabled
                        required />
                </Column>
            </Column>
        </Card>
    )
}

export default WAlmKueSatu
